import { UniqueIdentifier } from "hooks/uniqueIdentifier";

class FormHelper {
  /**
   * Creates a string of ids to be used to identify error message and instructions and other
   * descriptive tests for input and control form elements
   * @param groupIdentifier groupIdentifier or groupName
   * @param hasInstruction If it has instructions present
   * @param hasErrorMessage If it has an error message present
   * @param state Current state of input
   */
  createAriaDescribe(
    groupIdentifier: UniqueIdentifier,
    hasInstruction: boolean | undefined,
    hasErrorMessage: boolean | undefined,
    state: string | undefined
  ): string | undefined {
    if (!groupIdentifier) {
      console.warn(
        "Your FormGroup element is missing either a groupId or groupName."
      );
    }
    let ariaDescribedBy = undefined;

    if (hasInstruction && hasErrorMessage) {
      ariaDescribedBy = [
        groupIdentifier.getChildId("error-message"),
        groupIdentifier.getChildId("instruction"),
      ].join(" ");
    } else if (hasErrorMessage && state === "error") {
      ariaDescribedBy = groupIdentifier.getChildId("error-message");
    } else if (hasInstruction) {
      ariaDescribedBy = groupIdentifier.getChildId("instruction");
    }

    return ariaDescribedBy;
  }
}

export default FormHelper;
