import React, { Component } from "react";
import classNames from "classnames";
import FooterLinkSection from "./FooterLinkSection";
import FooterSocialSection from "./FooterSocialSection";
import FooterCopySection from "./FooterCopySection";
import FooterLinkWrapper from "./FooterLinkWrapper";
import FooterSocials from "./FooterSocials";

export interface FooterProps {
  footerType?: "primary-one";
  [x: string]: any;
}

export default class Footer extends Component<FooterProps> {
  static LinkSection = FooterLinkSection;
  static SocialSection = FooterSocialSection;
  static CopySection = FooterCopySection;
  static LinkWrapper = FooterLinkWrapper;
  static SocialIcons = FooterSocials;

  render() {
    const { className, children, ...other } = this.props;

    let footerClasses = classNames({
      "tds-footer": true,
      "tds-focus-dark-background": true,
    });

    footerClasses = className
      ? footerClasses.concat(" ", className)
      : footerClasses;

    return (
      <footer className={footerClasses} {...other}>
        <div className="tds-footer__section-container">{children}</div>
      </footer>
    );
  }
}
