import * as React from "react";
import { PopupTipIconProps } from "./PopupTip";

const POPUP_INFO_COMPONENT_KEY = "info";

export const PopupTipInfo = (props: PopupTipIconProps) => {
  const { popupTipIdentifier, isHovered, iconColor, iconVariant, ...rest } =
    props;
  const color = iconColor === "primary" ? "#002677" : "#fff";
  const hoverColor = iconColor === "primary" ? "#196ecf" : "#dadee9";

  if (iconVariant === "outlined") {
    return (
      <svg
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        focusable={false}
        aria-hidden={true}
        {...rest}
      >
        <defs>
          <path
            fillRule="evenodd"
            d="M11 17H13V11H11V17V17ZM12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2V2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20V20ZM11 9H13V7H11V9V9Z"
            id={popupTipIdentifier.getChildId(POPUP_INFO_COMPONENT_KEY)}
          />
        </defs>
        <g fill="none" fillRule="evenodd">
          <mask id={popupTipIdentifier.getChildId("mask")} fill="#fff">
            <use
              xlinkHref={`#${popupTipIdentifier.getChildId(
                POPUP_INFO_COMPONENT_KEY
              )}`}
            />
          </mask>
          <path
            fill={!isHovered ? color : hoverColor}
            mask={`url(#${popupTipIdentifier.getChildId("mask")})`}
            d="M11 17H13V11H11V17V17ZM12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2V2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20V20ZM11 9H13V7H11V9V9Z"
          />
        </g>
      </svg>
    );
  }

  return (
    <svg
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      focusable={false}
      aria-hidden={true}
      {...rest}
    >
      <defs>
        <path
          d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2V2ZM13 17H11V11H13V17V17ZM13 9H11V7H13V9V9Z"
          id={popupTipIdentifier.getChildId(POPUP_INFO_COMPONENT_KEY)}
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <mask id={popupTipIdentifier.getChildId("mask")} fill="#fff">
          <use
            xlinkHref={`#${popupTipIdentifier.getChildId(
              POPUP_INFO_COMPONENT_KEY
            )}`}
          />
        </mask>
        <path
          fill={!isHovered ? color : hoverColor}
          mask={`url(#${popupTipIdentifier.getChildId("mask")})`}
          d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2V2ZM13 17H11V11H13V17V17ZM13 9H11V7H13V9V9Z"
        />
      </g>
    </svg>
  );
};

PopupTipInfo.defaultProps = {
  iconColor: "primary",
  iconVariant: "filled",
} as Partial<PopupTipIconProps>;

export default PopupTipInfo;
