import * as React from "react";

export interface AuthNavLinkProps {
  /** Active/non-active link. */
  active?: boolean;
  /** href is required (accessibility) */
  href: Pick<React.HTMLProps<HTMLAnchorElement>, "href"> | string;
}

const AuthNavLink: React.FC<
  AuthNavLinkProps & React.HTMLProps<HTMLAnchorElement>
> = ({ children, active, href = "#", ...rest }) => {
  return (
    <a
      className={`s-nav-link${active ? " s-nav-link-active" : ""}`}
      href={href}
      {...rest}
    >
      {children}
      {active && (
        <React.Fragment>
          <span className="s-nav-sr-only">, current section</span>
        </React.Fragment>
      )}
    </a>
  );
};

AuthNavLink.displayName = "AuthNav.Link";

export default AuthNavLink;
