import * as React from "react";
import classNames from "classnames";
import UtilityIcon from "../../icon/UtilityIcon";
import { ArrowBack, ArrowForward } from "@material-ui/icons";

export interface PaginationNavigationButtonProps {
  /**
   * Alternate title for pagination navigation buttons. The default
   * is Previous for left direction and Next for right Direction.
   */
  altTitle?: string;
  /**
   * Alternate title for "Page". Used here for a11y text.
   */
  altPageTitle?: string;
  /**
   * Determines which direction the arrow should face.
   */
  direction: "left" | "right";
  /**
   * Hides the component.
   */
  hide?: boolean;
}

/**
 * Pagination Navigation Button (PNB)
 * @param {PaginationNavigationButtonProps} props
 */
const PaginationNavigationButton: React.FunctionComponent<
  PaginationNavigationButtonProps & React.HTMLAttributes<HTMLButtonElement>
> = (props) => {
  const {
    className,
    altTitle,
    direction,
    hide,
    altPageTitle,
    ...other
  } = props;

  // Set up local variables
  let linkText = "";

  // Set up classes and class logic
  let PaginationNavigationButtonClasses = classNames({
    "tds-pagination__navigation-button": true,
    "tds-pagination__navigation-button--hidden": hide,
    "tds-link": true,
    "tds-link--medium": true,
  });

  let PaginationButtonIconClasses = classNames({
    "tds-pagination__navigation-button-icon": direction === "right",
    "tds-pagination__navigation-button-icon--left": direction === "left",
  });

  const ArrowIcon = (
    <UtilityIcon
      size={"small"}
      icon={direction === "left" ? ArrowBack : ArrowForward}
      color="primaryCTA"
      className={PaginationButtonIconClasses}
    />
  );

  PaginationNavigationButtonClasses = className
    ? PaginationNavigationButtonClasses.concat(" ", className)
    : PaginationNavigationButtonClasses;

  // If no altTitle passed used default based on direction set.
  if (!altTitle) {
    linkText = direction === "left" ? "Previous" : "Next";
  } else {
    linkText = altTitle;
  }

  return (
    <React.Fragment>
      <button
        className={PaginationNavigationButtonClasses}
        aria-label={`${linkText} ${altPageTitle ? altPageTitle : "Page"}`}
        aria-disabled={hide}
        {...other}
      >
        {direction === "left" && ArrowIcon}
        <span className="link-text">{linkText}</span>
        {direction === "right" && ArrowIcon}
      </button>
    </React.Fragment>
  );
};

PaginationNavigationButton.defaultProps = {
  hide: false,
} as Partial<PaginationNavigationButtonProps>;

export default PaginationNavigationButton;
