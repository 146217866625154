import * as React from "react";

export interface StepTitleProps {
  title?: string;
  subtitle?: string;
  placeholder?: boolean;
}

export default class StepTitle extends React.PureComponent<StepTitleProps> {
  render() {
    const { title, subtitle, placeholder, ...other } = this.props;

    return (
      <div
        className={
          placeholder
            ? "tds-step-tracker__placeholder-titles"
            : "tds-step-tracker__titles"
        }
        {...other}
      >
        {title && (
          <p
            className={
              placeholder
                ? "tds-step-tracker__placeholder-title"
                : "tds-step-tracker__title"
            }
          >
            {title}
          </p>
        )}
        {subtitle && (
          <p
            className={
              placeholder
                ? "tds-step-tracker__placeholder-subtitle"
                : "tds-step-tracker__subtitle"
            }
          >
            {subtitle}
          </p>
        )}
      </div>
    );
  }
}
