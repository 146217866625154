import * as React from "react";
import Instruction from "../Instruction/Instruction";
import Input from "../Input/Input";
import Select from "../Select/Select";
import classNames from "classnames";
import RadioButton from "../RadioButton/RadioButton";
import Checkbox from "../Checkbox/Checkbox";
import ErrorMessage from "../ErrorMessage/ErrorMessage";
import FormHelper from "../FormHelper";
import { useUniqueId } from "../../../hooks";

export interface FormGroupProps {
  /**
   * Applies id and for attribute to inputs within the formgroup
   * This only applies to inputs of type: text,
   */
  groupId?: string;
  /**
   * Applies shared name attribute to radiobutton's and checkboxes within the form group
   * This only applies to radiobuttons's and checkboxes.
   */
  groupName?: string;
  /**
   * Alignment for checkboxes and radiobutton's & checkboxes within the FormGroup
   * This only applies to radiobutton's and checkboxes.
   */
  alignment?: "vertical" | "horizontal";
  /**
   * Different states for form elements
   */
  state?: "default" | "error" | "disabled";
  /**
   * If an input has instruction text if this is true the error will
   * replace the text instead of add on as this will hide the instruction text
   * in an error state.
   */
  hideInstruction?: boolean;
}

interface ConfigProps {
  hasInstruction: boolean;
  instructionIndex: number | undefined;
  hasErrorMessage: boolean;
  errorMessageIndex: number | undefined;
  alignElements: boolean;
  role: string | undefined;
  controlGroup: boolean;
}

export type FormGroupType = React.FunctionComponent<
  FormGroupProps & React.HTMLProps<HTMLFieldSetElement | HTMLDivElement>
>;

/**
 * Form Group
 * @param {FormGroupProps} props
 */
const FormGroup: FormGroupType = (props) => {
  const {
    className,
    groupId,
    groupName,
    alignment,
    hideInstruction,
    state,
    ...other
  } = props;
  const groupIdentifier = useUniqueId("f-group", groupId || groupName);

  const formHelper = new FormHelper();

  let config: ConfigProps = {
    hasInstruction: false,
    instructionIndex: undefined,
    hasErrorMessage: false,
    errorMessageIndex: undefined,
    alignElements: false,
    role: undefined,
    controlGroup: false,
  };

  let elements = React.Children.toArray(props.children);

  // Set up configuration flags for FormGroup
  elements.forEach((element, index) => {
    if (React.isValidElement(element)) {
      switch (element.type) {
        case Instruction:
          config.hasInstruction = hideInstruction ? false : true;
          config.instructionIndex = index;
          break;
        case ErrorMessage:
          config.hasErrorMessage = true;
          config.errorMessageIndex = index;
          break;
        case RadioButton:
        case Checkbox:
          config.alignElements = true;
          config.role = "group";
          config.controlGroup = true;
          break;
        default:
          break;
      }
    }
  });

  elements = elements.map((element) => {
    if (React.isValidElement(element)) {
      switch (element.type) {
        case Input:
          return React.cloneElement(element, {
            groupIdentifier,
            state: state,
            hasInstruction: config.hasInstruction,
            hasErrorMessage: config.hasErrorMessage,
            ariaDescribedBy: formHelper.createAriaDescribe(
              groupIdentifier,
              config.hasInstruction,
              config.hasErrorMessage,
              state
            ),
            ...element.props,
          });
        case Select:
          return React.cloneElement(element, {
            groupIdentifier,
            state: state,
            hasInstruction: config.hasInstruction,
            hasErrorMessage: config.hasErrorMessage,
            ariaDescribedBy: formHelper.createAriaDescribe(
              groupIdentifier,
              config.hasInstruction,
              config.hasErrorMessage,
              state
            ),
            ...element.props,
          });
        case RadioButton:
        case Checkbox:
          return React.cloneElement(element, {
            groupName: props.groupName,
            state: state,
            ariaDescribedBy: formHelper.createAriaDescribe(
              groupIdentifier,
              config.hasInstruction,
              config.hasErrorMessage,
              state
            ),
            ...element.props,
          });
        case ErrorMessage:
          return React.cloneElement(element, {
            groupIdentifier,
            state: state,
          });
        case Instruction:
          return React.cloneElement(element, {
            groupIdentifier,
            state: state,
            hideInstruction: props.hideInstruction,
            ...element.props,
          });
        default:
          return React.cloneElement(element, {
            groupIdentifier,
            state: state,
            ...element.props,
          });
      }
    } else {
      return false;
    }
  });

  if (config.controlGroup) {
    let ControlGroupClasses = classNames({
      "tds-form__control-group": true,
      "tds-form__control-group--vertical":
        config.alignElements && alignment === "vertical",
      "tds-form__control-group--horizontal":
        config.alignElements && alignment === "horizontal",
      "tds-form__control-group--error":
        config.controlGroup && state === "error",
    });

    // We need to remove any descriptive text from the flex flow
    let errorMessageElement = undefined;
    let instructionElement = undefined;

    if (config.hasErrorMessage && config.errorMessageIndex) {
      errorMessageElement = elements.splice(config.errorMessageIndex);
    }
    if (config.hasInstruction && config.instructionIndex) {
      instructionElement = elements.splice(config.instructionIndex);
    }

    return (
      <div className={className}>
        <fieldset
          className={ControlGroupClasses}
          {...(other as React.HTMLProps<HTMLFieldSetElement>)}
        >
          {elements}
        </fieldset>
        {errorMessageElement}
        {instructionElement}
      </div>
    );
  }

  return (
    <div className={className} {...(other as React.HTMLProps<HTMLDivElement>)}>
      {elements}
    </div>
  );
};

FormGroup.defaultProps = {
  alignment: "vertical",
  hideInstruction: false,
  state: "default",
} as Partial<FormGroupProps>;

export default FormGroup;
