import * as React from "react";

const AuthNavDesktop: React.FC<React.HTMLProps<HTMLDivElement>> = ({
  children,
  ...rest
}) => (
  <div className="s-nav-desktop" {...rest}>
    {children}
  </div>
);

AuthNavDesktop.displayName = "AuthNav.Desktop";

export default AuthNavDesktop;
