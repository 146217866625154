import * as React from "react";
import classNames from "classnames";

export interface RadioButtonProps {
  /**
   * Adjusts the color of the RadioButton based on state
   */
  state?: "default" | "error" | "disabled";
  /**
   * Applies the name attribute on the RadioButton (used by FormGroup)
   */
  groupName?: string;
  /**
   * Adds label text to RadioButton
   */
  label: string;
  /**
   * Id reference for error and instruction messages
   */
  ariaDescribedBy?: string;
}

export type RadioButtonType = React.FunctionComponent<
  RadioButtonProps & React.HTMLProps<HTMLInputElement>
>;

/**
 * This is an RadioButton
 * @param {RadioButtonProps} props
 */
const RadioButton: RadioButtonType = (props) => {
  const {
    className,
    groupName,
    state,
    id,
    label,
    ariaDescribedBy,
    ...other
  } = props;

  let RadioButtonGroupClasses = classNames(
    {
      "tds-form__radio-button": true,
    },
    className
  );

  let radioSVGCircleClasses = classNames({
    "tds-form__radio-button-radio-bg": true,
    "tds-form__radio-button-radio-bg--error": state === "error",
  });

  return (
    <label className={RadioButtonGroupClasses} htmlFor={id}>
      <input
        type="radio"
        name={groupName}
        id={id}
        aria-describedby={ariaDescribedBy}
        {...other}
      />

      <svg
        className="tds-form__radio-button-svg"
        width="24"
        height="24"
        version="1.1"
        viewBox="0 0 24 24"
        aria-hidden="true"
        focusable="false"
      >
        <rect
          stroke="#196ECF"
          strokeWidth="2"
          x="1"
          y="1"
          width="22"
          height="22"
          fill="none"
          className="tds-form__radio-button-focus"
        />
        <ellipse
          className={radioSVGCircleClasses}
          cx="8"
          cy="8"
          rx="7"
          ry="7"
          fill="#FFFFFF"
          stroke="currentColor"
          strokeWidth="2"
          transform="translate(4, 4)"
        />
        <circle
          cx="8"
          cy="8"
          r="4"
          fill="none"
          transform="translate(4, 4)"
          className="tds-form__radio-button-radio-fill"
        />
      </svg>

      <span className="tds-form__radio-button-label-text">{label}</span>
    </label>
  );
};

RadioButton.defaultProps = {} as Partial<RadioButtonProps>;

export default RadioButton;
