import { UniqueIdentifier } from "hooks/uniqueIdentifier";
import * as React from "react";
import {
  ACCORDION_HEADER_KEY,
  ACCORDION_CONTENT_KEY,
  ACCORDION_HEADER_TITLE_KEY,
  ACCORDION_SVG_KEY,
} from "./accordion.const";

export interface AccordionHeaderProps {
  isOpen: boolean;
  children: string;
  accordionIdentifier: UniqueIdentifier;
  /**
   * Determines the color variant of the accordion
   * @type {("primary" | "secondary")}
   * @memberof AccordionGroupProps
   */
  backgroundColor?: "primary" | "secondary";
  onToggleAccordion: (
    event: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>
  ) => void;
  /**
   * Headers have been removed from the Accordion component
   * @deprecated
   * @type {1|2|3|4|5|6}
   * @memberof AccordionGroupProps
   */
  headingLevel?: 1 | 2 | 3 | 4 | 5 | 6;
}

interface ExpanderProps {
  expanded: string;
  id: string;
}

/**
 * A simple component to load the correct svg to indicate expanded state
 *
 * @param {ExpanderProps} { expanded }
 * @returns
 */
const Expander = ({ expanded, id }: ExpanderProps) => {
  return (
    <svg
      viewBox="0 0 32 32"
      className={`tds-accordion__header-icon tds-accordion__header-icon--${expanded}`}
      aria-hidden="true"
      focusable="false"
      id={id}
    >
      <path
        fill="#002677"
        d="M16,32A16,16,0,1,1,32,16,16,16,0,0,1,16,32ZM16,1A15,15,0,1,0,31,16,15,15,0,0,0,16,1Zm0,10.5L9.65,17.85a.5.5,0,0,0,0,.71.48.48,0,0,0,.7,0L16,12.91l5.65,5.65a.48.48,0,0,0,.7,0,.5.5,0,0,0,0-.71Z"
      />
    </svg>
  );
};

/**
 * An Accordion for displaying accordion content.
 *
 * @param {AccordionHeaderProps} props
 */
const AccordionHeader: React.FunctionComponent<AccordionHeaderProps> = (
  props
) => {
  const { children, isOpen, accordionIdentifier, backgroundColor } = props;
  const openState = isOpen ? "open" : "closed";
  return (
    <h3
      className={`tds-accordion__header--${backgroundColor} tds-accordion__header-heading-wrapper`}
    >
      <button
        id={accordionIdentifier.getChildId(ACCORDION_HEADER_KEY)}
        aria-controls={accordionIdentifier.getChildId(ACCORDION_CONTENT_KEY)}
        aria-labelledby={accordionIdentifier.getChildId(
          ACCORDION_HEADER_TITLE_KEY
        )}
        aria-expanded={isOpen ? "true" : "false"}
        className={`tds-accordion__header tds-accordion__header--${openState}`}
        onClick={props.onToggleAccordion}
      >
        <div
          id={accordionIdentifier.getChildId(ACCORDION_HEADER_TITLE_KEY)}
          className="tds-accordion__header-title"
        >
          {children}
        </div>
        <div className="tds-accordion__header-icon-container">
          <Expander
            expanded={openState}
            id={accordionIdentifier.getChildId(ACCORDION_SVG_KEY)}
          />
        </div>
      </button>
    </h3>
  );
};

export default AccordionHeader;
