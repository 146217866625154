import * as React from "react";

export interface SelectOptionProps {}

export type SelectOptionType = React.FunctionComponent<
  SelectOptionProps & React.HTMLProps<HTMLOptionElement>
>;

/**
 * This is a SelectOption
 * @param {SelectOptionProps} props
 */
const SelectOption: SelectOptionType = ({ children, ...rest }) => (
  <option {...rest}>{children}</option>
);

export default SelectOption;
