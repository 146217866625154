/// <reference path="./datepicker.d.ts" />
/// <reference path="../../testing-library.d.ts" />

/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import classNames from "classnames";
import UtilityIcon from "../icon/UtilityIcon";
import Event from "@material-ui/icons/Event";
import ArrowForward from "@material-ui/icons/ArrowForward";
import ArrowBack from "@material-ui/icons/ArrowBack";
import FocusTrap from "focus-trap-react";
import { useUniqueId } from "../../hooks";
import Select from "../form/Select/Select";

import {
  isEqualDate,
  splitWeeksInMonth,
  getPrevYearAndMonth,
  getNextYearAndMonth,
  getYears,
  isDateWithinRange,
  isValidDate,
  padDayOrMonth,
  formatFullDate,
  validateFullDate,
} from "./datepicker.func";
import {
  monthNames,
  dayHeight,
  transitionTime,
  dayNames,
  dayNamesLong,
} from "./datepicker.const";
import { Form } from "index";

const { useState, useEffect, useRef } = React;

export interface DatePickerProps {
  /**
   * Override the encapsulated error state of the DatePicker.
   */
  state?: "default" | "error" | "disabled";
  /**
   * A function that will return the date when a new date is selected.
   *
   * @type {(d: string) => void}
   * @memberof DatePickerProps
   */
  onDateChange?: (d: string) => void;
  /**
   * The default date in the format of "YYYY-MM-DD"
   *
   * @type {string}
   * @memberof DatePickerProps
   */
  defaultValue?: string;
  /**
   * The minimum date in the format of "YYYY-MM-DD"
   *
   * @type {string}
   * @memberof DatePickerProps
   */
  min?: string;
  /**
   * The maximum date in the format of "YYYY-MM-DD"
   *
   * @type {string}
   * @memberof DatePickerProps
   */
  max?: string;
  /**
   * The DatePicker's label text
   */
  label?: string;
  /**
   * The DatePicker's error text that shows up for an invalid date
   */
  invalidDateErrorText?: string;
  /**
   * The DatePicker's error text that shows up for a date that is out of range
   */
  outOfRangeErrorText?: string;
  /**
   * The DatePicker's instruction text that shows up below the DatePicker's input
   */
  instructionText?: string;

  buttonProps?: React.HTMLAttributes<HTMLButtonElement> & DataTestID;

  popupProps?: React.HTMLAttributes<HTMLDivElement> & DataTestID;

  inputProps?: React.HTMLAttributes<HTMLInputElement> & DataTestID;

  monthButtonProps?: React.HTMLAttributes<HTMLButtonElement> & DataTestID;

  monthListContainerProps?: React.HTMLAttributes<HTMLDivElement> & DataTestID;

  yearListContainerProps?: React.HTMLAttributes<HTMLDivElement> & DataTestID;

  previousMonthButtonProps?: React.HTMLAttributes<HTMLButtonElement> &
    DataTestID;

  nextMonthButtonProps?: React.HTMLAttributes<HTMLButtonElement> & DataTestID;

  selectedDayButtonProps?: React.HTMLAttributes<HTMLButtonElement> & DataTestID;

  errorMessageProps?: React.HTMLAttributes<HTMLSpanElement> & DataTestID;
}

export const usePrevious = (text: string, value: any) => {
  const ref = useRef();
  useEffect(() => {
    // @ts-ignore
    ref.current = value;
  });
  return ref.current;
};

const DatePicker: React.FunctionComponent<
  DatePickerProps & React.HTMLProps<HTMLDivElement>
> = (props) => {
  const {
    onDateChange,
    defaultValue,
    min,
    max,
    className,
    label,
    instructionText,
    invalidDateErrorText,
    outOfRangeErrorText,
    buttonProps,
    popupProps,
    inputProps,
    monthButtonProps,
    monthListContainerProps,
    yearListContainerProps,
    previousMonthButtonProps,
    nextMonthButtonProps,
    selectedDayButtonProps,
    errorMessageProps,
    state,
    ...rest
  } = props;

  const [open, setOpen] = useState(false);
  const [error, setError] = useState<string | undefined>(undefined);

  let datePickerClassNames = classNames(className, { "tds-date-picker": true });

  let labelClassNames = classNames({
    "tds-form__label": true,
    "tds-form__label--error": error !== undefined || state === "error",
    "tds-form__label--disabled": state === "disabled",
  });

  let inputClassNames = classNames({
    "tds-date-picker__input": true,
    "tds-form__input--error": error || state === "error",
  });

  const uniqueIdentifier = useUniqueId("datepicker");
  const id = uniqueIdentifier.getId();

  // min! / max! is defined in defaultProps
  const minDate = min!.split("-").map((x) => parseInt(x));
  const maxDate = max!.split("-").map((x) => parseInt(x));

  // What time is is now?
  const currentDate = new Date();
  const currentMonth: ReadableMonth = (currentDate.getMonth() +
    1) as ReadableMonth;
  const currentYear = currentDate.getFullYear();
  const currentDay: DayOfMonth = currentDate.getDate() as DayOfMonth;

  const yearsListRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const popupRef = useRef<HTMLDivElement>(null);
  const monthSwitcherRef = useRef<HTMLButtonElement>(null);
  const prevButtonRef = useRef<HTMLButtonElement>(null);
  const nextButtonRef = useRef<HTMLButtonElement>(null);
  const dayRefs = useRef({});

  const defaultInputValue =
    defaultValue !== undefined
      ? `${defaultValue.substr(5, 2)}/${defaultValue.substr(
          8,
          2
        )}/${defaultValue.substr(0, 4)}`
      : "";

  const [inputValue, setInputValue] = useState<string>(defaultInputValue);

  let previousInputValue = usePrevious(
    "inputValue",
    inputValue || defaultInputValue
  );

  // The month displayed to the user
  const [calendarDisplayDate, setCalendarDisplayDate] =
    useState<ReadableYearMonth>(
      defaultValue !== undefined
        ? [
            parseInt(defaultValue.substr(0, 4)) as number,
            parseInt(defaultValue.substr(5, 2)) as ReadableMonth,
          ]
        : [currentYear, currentMonth]
    );

  // The month that will display after a click
  const [calendarQueuedDate, setCalendarQueuedDate] =
    useState<ReadableYearMonth>([currentYear, currentMonth]);
  const previousQueuedDate = usePrevious(
    "calendarQueuedDate",
    calendarQueuedDate
  );

  // Selected date
  const value =
    defaultValue !== undefined
      ? (defaultValue
          .split("-")
          .map((x) => parseInt(x)) as ReadableYearMonthDay)
      : ([] as any);

  const [selectedDate, setSelectedDate] = useState<
    ReadableYearMonthDay | any[]
  >(value);

  // Positioning / Display Calendar vs. Years
  const [position, setPosition] = useState<Transition>({ left: "-100%" });

  const weeksInActiveMonth = splitWeeksInMonth(calendarDisplayDate);

  const [prevYear, prevMonth] = getPrevYearAndMonth(calendarDisplayDate);
  const weeksInPrevMonth = splitWeeksInMonth([prevYear, prevMonth]);

  const [nextYear, nextMonth] = getNextYearAndMonth(calendarDisplayDate);
  const weeksInNextMonth = splitWeeksInMonth([nextYear, nextMonth]);

  // Min and max Date obj for comparisons
  const minDateObj = new Date(
    minDate[0],
    minDate[1] - 1,
    minDate[2],
    0,
    0,
    0,
    0
  );
  const maxDateObj = new Date(
    maxDate[0],
    maxDate[1] - 1,
    maxDate[2],
    23,
    59,
    59,
    999
  );

  // Manage the horizontal date scroller
  let effectTimer = useRef();
  useEffect(() => {
    if (
      previousQueuedDate !== undefined &&
      !isEqualDate(calendarQueuedDate, previousQueuedDate)
    ) {
      if (effectTimer.current) {
        clearTimeout(effectTimer.current);
      }

      const qDate = new Date(
        calendarQueuedDate[0],
        calendarQueuedDate[1] - 1,
        1,
        0,
        0,
        0,
        0
      );
      const dDate = new Date(
        calendarDisplayDate[0],
        calendarDisplayDate[1] - 1,
        1,
        0,
        0,
        0,
        0
      );

      if (qDate > dDate) {
        setPosition({ transition: `left ${transitionTime}ms`, left: "-200%" });
        // @ts-ignore
        effectTimer.current = setTimeout(() => {
          setCalendarDisplayDate(calendarQueuedDate);
          setPosition({ left: "-100%" });
          if (nextButtonRef && nextButtonRef.current) {
            nextButtonRef.current.focus();
          }
        }, transitionTime + 1);
      } else if (qDate < dDate) {
        // @ts-ignore
        effectTimer.current = setTimeout(() => {
          setCalendarDisplayDate(calendarQueuedDate);
          setPosition({ left: "-100%" });
          if (prevButtonRef && prevButtonRef.current) {
            prevButtonRef.current.focus();
          }
        }, transitionTime + 1);
        setPosition({ transition: `left ${transitionTime}ms`, left: "0%" });
      }
    }
    return () => {
      if (effectTimer.current) {
        try {
          clearTimeout(effectTimer.current);
        } catch (e) {}
      }
    };
  }, [
    effectTimer,
    JSON.stringify(calendarDisplayDate),
    JSON.stringify(calendarQueuedDate),
  ]);

  // If the selected calendar date changes
  useEffect(() => {
    if (previousInputValue !== undefined && inputValue !== previousInputValue) {
      const s = formatFullDate(inputValue).split(/[^0-9]/);
      const year = parseInt(s[2]);
      const month = parseInt(s[0]) as ReadableMonth;
      const day = parseInt(s[1]) as DayOfMonth;
      const sDate = new Date(year, month - 1, day, 12, 0, 0, 0);

      if (
        isValidDate([year, month, day]) &&
        isDateWithinRange(sDate, minDateObj, maxDateObj)
      ) {
        // Value was OK, update the calendar to match
        setCalendarDisplayDate([year, month]);
        setSelectedDate([year, month, day]);
        setError(undefined);

        if (onDateChange) {
          onDateChange(`${padDayOrMonth(month)}-${padDayOrMonth(day)}-${year}`);
        }
      }
    }
  }, [inputValue, previousInputValue]);

  const getFocusedDay = () => {
    const focusedElement = document.activeElement;
    let focusedDayNode;
    let focusedDayKey;
    Object.entries(dayRefs.current).forEach(([key, value]) => {
      if (value === focusedElement) {
        focusedDayNode = value;
        focusedDayKey = key;
      }
    });
    return focusedDayNode !== undefined && focusedDayKey !== undefined
      ? // @ts-ignore
        parseInt(focusedDayKey.split("-")[3])
      : undefined;
  };

  // Handle the arrows. Arrows move the selectedDate up and down / left and right
  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (open) {
        const focusedDayValue = getFocusedDay();

        if (focusedDayValue && e.key === "Enter") {
          e.preventDefault();
          // Change the date when the user hits Enter to choose another date. This is different than the normal mouse interaction. When a date is changed by click, it is kept open. (accessibility)
          if (
            dayRefs.current[
              `${id}-${calendarDisplayDate[0]}-${calendarDisplayDate[1]}-${focusedDayValue}`
            ]
          ) {
            setSelectedDate([
              calendarDisplayDate[0],
              calendarDisplayDate[1],
              focusedDayValue,
            ]);
            setInputValue(
              `${padDayOrMonth(calendarDisplayDate[1])}/${padDayOrMonth(
                focusedDayValue as DayOfMonth
              )}/${calendarDisplayDate[0]}`
            );
            setOpen(false);
          }
        }

        if (
          focusedDayValue &&
          (e.key === "ArrowUp" ||
            e.key === "ArrowDown" ||
            e.key === "ArrowLeft" ||
            e.key === "ArrowRight")
        ) {
          e.preventDefault();
          if (e.key === "ArrowUp") {
            if (
              dayRefs.current[
                `${id}-${calendarDisplayDate[0]}-${calendarDisplayDate[1]}-${
                  focusedDayValue - 7
                }`
              ]
            ) {
              dayRefs.current[
                `${id}-${calendarDisplayDate[0]}-${calendarDisplayDate[1]}-${
                  focusedDayValue - 7
                }`
              ].focus();
            } else {
              dayRefs.current[
                `${id}-${calendarDisplayDate[0]}-${calendarDisplayDate[1]}-${
                  weeksInActiveMonth
                    .flat()
                    .filter(
                      (d: Week) => d !== undefined && d.date >= focusedDayValue
                    )
                    .filter((d: Week, i: number) => !(i % 7))
                    .pop().date
                }`
              ].focus();
            }
          } else if (e.key === "ArrowDown") {
            if (
              dayRefs.current[
                `${id}-${calendarDisplayDate[0]}-${calendarDisplayDate[1]}-${
                  focusedDayValue + 7
                }`
              ]
            ) {
              dayRefs.current[
                `${id}-${calendarDisplayDate[0]}-${calendarDisplayDate[1]}-${
                  focusedDayValue + 7
                }`
              ].focus();
            } else {
              dayRefs.current[
                `${id}-${calendarDisplayDate[0]}-${calendarDisplayDate[1]}-${
                  focusedDayValue % 7 !== 0 ? focusedDayValue % 7 : 7
                }`
              ].focus();
            }
          } else if (e.key === "ArrowLeft") {
            if (
              dayRefs.current[
                `${id}-${calendarDisplayDate[0]}-${calendarDisplayDate[1]}-${
                  focusedDayValue - 1
                }`
              ]
            ) {
              dayRefs.current[
                `${id}-${calendarDisplayDate[0]}-${calendarDisplayDate[1]}-${
                  focusedDayValue - 1
                }`
              ].focus();
            } else {
              dayRefs.current[
                `${id}-${calendarDisplayDate[0]}-${calendarDisplayDate[1]}-${
                  weeksInActiveMonth.flat().pop().date
                }`
              ].focus();
            }
          } else if (e.key === "ArrowRight") {
            if (
              dayRefs.current[
                `${id}-${calendarDisplayDate[0]}-${calendarDisplayDate[1]}-${
                  focusedDayValue + 1
                }`
              ]
            ) {
              dayRefs.current[
                `${id}-${calendarDisplayDate[0]}-${calendarDisplayDate[1]}-${
                  focusedDayValue + 1
                }`
              ].focus();
            } else {
              // Focus on the first day of month (could possibly make it focus on first available day if day range is limited)
              dayRefs.current[
                `${id}-${calendarDisplayDate[0]}-${calendarDisplayDate[1]}-1`
              ].focus();
            }
          }
        }
      }
    };
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [open, calendarDisplayDate]);

  // Handles clicks outside the Input Button and the DatePicker Popup, also handles ESC
  useEffect(() => {
    const handleDocumentClick = (e: any) => {
      // If anything was clicked inside the DatePicker Popup, just return
      if (popupRef.current !== null && popupRef.current?.contains(e.target)) {
        return;
      }
      // If open and the button was clicked, close the popup
      if (
        buttonRef.current !== null &&
        !buttonRef.current?.contains(e.target)
      ) {
        setOpen(false);
        return;
      }
    };
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === "Escape") {
        setOpen(false);
      }
    };

    // Focus on the Month Switcher when open
    const monthSwitcher = monthSwitcherRef.current;
    if (open && monthSwitcher) {
      monthSwitcher.focus();
    }

    document.addEventListener("click", handleDocumentClick);
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("click", handleDocumentClick);
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [open]);

  if (defaultValue) {
    const defaultDate = defaultValue.split("-").map((x) => parseInt(x));
    const defaultDateObj = new Date(
      defaultDate[0],
      defaultDate[1] - 1,
      defaultDate[2],
      12,
      0,
      0,
      0
    );

    if (
      !isValidDate([
        defaultDate[0],
        defaultDate[1] as ReadableMonth,
        defaultDate[2] as DayOfMonth,
      ])
    ) {
      console.error("DatePicker defaultValue must be a valid date.");
    }

    if (!isDateWithinRange(defaultDateObj, minDateObj, maxDateObj)) {
      console.error(
        "DatePicker defaultValue must be between the mix and max values."
      );
    }
  }

  const isSelectedDateVisible = weeksInActiveMonth
    .flat()
    .filter((d: Week) => d !== undefined)
    .find((d: Week) => {
      return isEqualDate(
        [calendarDisplayDate[0], calendarDisplayDate[1], d.date],
        [selectedDate[0], selectedDate[1], selectedDate[2]]
      );
    });

  // This function determines what value is displayed in the month and year selects
  // value is 1 for months and 0 for years and is passed within the component below
  const displayValue = (value: number): number => {
    if (selectedDate[value] !== defaultValue) {
      return calendarDisplayDate[value];
    } else if (selectedDate[value] !== undefined) {
      return selectedDate[value];
    } else {
      return calendarDisplayDate[value];
    }
  };

  // Converts the date array to a string to be read by the screenReaderSpeak function
  const announceableDate = (date: number[]): string => {
    const month: string = monthNames[date[1] - 1];
    const year: number = date[0];
    return `${month} ${year}`;
  };

  // This function will announce whatever is passed as liveText via screen reader
  const screenReaderSpeak = (liveText: string, priority?: string): void => {
    const el: HTMLElement = document.createElement("div");
    const id: string = "speak-" + Date.now();

    el.setAttribute("id", id);
    el.setAttribute("aria-live", priority || "polite");
    el.classList.add("tds-sr-only");

    document.body.appendChild(el);

    window.setTimeout(() => {
      document.getElementById(id)!.innerHTML = liveText;
    }, 100);

    window.setTimeout(() => {
      document.body.removeChild(document.getElementById(id)!);
    }, 1000);
  };

  const changeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setError(undefined);
    let value = e.target.value;
    if (value === "") {
      setError(undefined);
    }
    setInputValue(value);
  };

  // This function handles the date validation when the focus leaves the input and will throw either an invalid date error
  // or an out of range errors if unable to validate the date entered.
  const blurInput = () => {
    // Check inputValue for valid date
    if (validateFullDate(inputValue)) {
      const s = formatFullDate(inputValue).split(/[^0-9]/);
      const year = parseInt(s[2]);
      if (s[2].length === 4 && s[2].split("")[0] === "0") {
        setInputValue(formatFullDate);
        setError(outOfRangeErrorText);
        return;
      }
      const month = parseInt(s[0]) as ReadableMonth;
      const day = parseInt(s[1]) as DayOfMonth;
      const sDate = new Date(year, month - 1, day, 12, 0, 0, 0);

      // If a valid date is entered, check that it is within the set date range
      setInputValue(formatFullDate(inputValue));
      if (!isDateWithinRange(sDate, minDateObj, maxDateObj)) {
        setError(outOfRangeErrorText);
      } else {
        setError(undefined);
      }
    } else {
      // If a value is entered but it is an invalid date
      if (inputValue === "") {
        setError(undefined);
      } else {
        setError(invalidDateErrorText);
      }
    }
  };

  // This function is being used in in the .map of the datepicker popup
  // Every date in the currently visible month is checked and is given a tabIndex of either 0 or -1
  // Only 1 date at the most will have a tabIndex of 0, the rest will have a tabIndex of -1
  const setTabIndex = (
    isSelectedDay: boolean,
    row: number,
    date: number
  ): number => {
    // First check if the date is the currently selected date
    if (isSelectedDay) {
      return 0;
    }
    // Then check if the date is in the first row of dates AND it is the first day of the month AND the currently selected date is not visible (if applicable)
    else if (row === 0 && date === 1 && !isSelectedDateVisible) {
      return 0;
    }
    // Then check to see if the date is the same as the minimum date AND the currently selected date is not visible (if applicable)
    else if (date === minDate[2] && !isSelectedDateVisible) {
      return 0;
    }
    // If the date doesn't meet any of the above critera its tabIndex is set to -1
    else {
      return -1;
    }
  };

  useEffect(() => {
    setCalendarQueuedDate(calendarDisplayDate);
  }, [calendarDisplayDate]);

  return (
    <div className={datePickerClassNames} {...rest}>
      <div className="tds-form__form-group">
        <label htmlFor={id} className={labelClassNames}>
          {label}
        </label>
        <div className="tds-date-picker__input-combo">
          <div className="tds-form__input-combo">
            <Form.Input
              type="text"
              className={inputClassNames}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                changeInput(e)
              }
              value={inputValue}
              placeholder={inputValue !== "" ? undefined : "MM/DD/YYYY"}
              onFocus={() => setOpen(false)}
              onBlur={blurInput}
              id={id}
              aria-invalid={error !== undefined || state === "error"}
              aria-describedby={`${id}_error-message ${id}_instruction`}
              autoComplete="off"
              disabled={state === "disabled"}
              state={!error ? undefined : "error"}
              {...inputProps}
            />
            <button
              tabIndex={0}
              className="tds-form__input-button tds-date-picker__input-button"
              ref={buttonRef}
              onClick={() => setOpen(!open)}
              aria-label="Toggle calendar"
              disabled={state === "disabled"}
              {...buttonProps}
            >
              <UtilityIcon
                className="tds-date-picker__input-button-icon"
                color="primaryLight"
                icon={Event}
                size="medium"
              />
            </button>
          </div>
          {open && (
            <FocusTrap
              focusTrapOptions={{
                clickOutsideDeactivates: true,
                // allowOutsideClick: true, // This isn't needed. Adding this doesn't let us loop through the dates.
              }}
            >
              <div
                className={`tds-date-picker__popup`}
                ref={popupRef}
                {...popupProps}
              >
                <div className="tds-date-picker__header">
                  <div className="tds-date-picker__month-year-wrapper">
                    <Select
                      aria-label="Select a month"
                      className="tds-date-picker__month-select"
                      containerClassName="tds-date-picker__month-select-container"
                      value={displayValue(1)}
                      onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                        const m = parseInt(e.target.value, 10);
                        if (
                          selectedDate[1] === maxDate[1] ||
                          selectedDate[1] === minDate[1]
                        ) {
                          setCalendarDisplayDate([
                            calendarDisplayDate[0],
                            m as ReadableMonth,
                          ]);
                        }
                        if (
                          isValidDate([
                            calendarDisplayDate[0],
                            m as ReadableMonth,
                            selectedDate[2],
                          ])
                        ) {
                          // Check to see if the date still falls within the range when switching back to the month display
                          // For example, selected date is Dec. 17th, user moves to March, switches the year, DatePicker should switch to March 17th. (That's how other DatePickers seem to work)
                          if (
                            isDateWithinRange(
                              new Date(
                                calendarDisplayDate[0],
                                m - 1,
                                selectedDate[2],
                                12,
                                0,
                                0,
                                0
                              ),
                              minDateObj,
                              maxDateObj
                            ) ||
                            isDateWithinRange(
                              new Date(
                                selectedDate[0],
                                selectedDate[1],
                                selectedDate[2],
                                12,
                                0,
                                0,
                                0
                              ),
                              minDateObj,
                              maxDateObj
                            )
                          ) {
                            setSelectedDate([
                              calendarDisplayDate[0],
                              m,
                              selectedDate[2],
                            ]);
                            setInputValue(
                              `${padDayOrMonth(
                                m as DayOfMonth
                              )}/${padDayOrMonth(selectedDate[2])}/${
                                calendarDisplayDate[0]
                              }`
                            );
                          } else {
                            setCalendarDisplayDate([
                              calendarDisplayDate[0],
                              m as ReadableMonth,
                            ]);
                            setCalendarQueuedDate([
                              calendarDisplayDate[0],
                              m as ReadableMonth,
                            ]);
                          }
                        } else {
                          if (
                            selectedDate.length === 0 &&
                            e.target.value !== ""
                          ) {
                            // No selectedDate, just set the calendarDisplayDate to the current month, and the year the user clicked.
                            setCalendarDisplayDate([
                              calendarDisplayDate[0],
                              m as ReadableMonth,
                            ]);
                          } else {
                            // A selectedDate was found, set the new selectedDate to the selected year, and current calendarDisplayDate.
                            setSelectedDate([
                              calendarDisplayDate[0],
                              m,
                              new Date(calendarDisplayDate[0], m, 0).getDate(),
                            ]);
                            setInputValue(
                              `${padDayOrMonth(
                                m as DayOfMonth
                              )}/${padDayOrMonth(
                                new Date(
                                  calendarDisplayDate[0],
                                  m,
                                  0
                                ).getDate() as DayOfMonth
                              )}/${calendarDisplayDate[0]}`
                            );
                          }
                        }
                      }}
                    >
                      {monthNames.map((m, i) => (
                        <Select.Option key={i} value={i + 1}>
                          {m}
                        </Select.Option>
                      ))}
                    </Select>
                    <Select
                      aria-label="Select a year"
                      name="year"
                      className="tds-date-picker__year-select"
                      containerClassName="tds-date-picker__year-select-container"
                      value={displayValue(0)}
                      onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                        const y = parseInt(e.target.value, 10);
                        // The new selectedDate will be the current selectedDate with the newly selected year, and the month the scroller is at...
                        // Only select the date if the date is valid (think February 29th) and the date falls within the min / max range
                        if (
                          isValidDate([
                            y,
                            calendarDisplayDate[1],
                            selectedDate[2],
                          ])
                        ) {
                          // Check to see if the date still falls within the range when switching back to the month display
                          // For example, selected date is Dec. 17th, user moves to March, switches the year, DatePicker should switch to March 17th. (That's how other DatePickers seem to work)
                          if (
                            isDateWithinRange(
                              new Date(
                                y,
                                calendarDisplayDate[1] - 1,
                                selectedDate[2],
                                12,
                                0,
                                0,
                                0
                              ),
                              minDateObj,
                              maxDateObj
                            )
                          ) {
                            setSelectedDate([
                              y,
                              calendarDisplayDate[1],
                              selectedDate[2],
                            ]);

                            setInputValue(
                              `${padDayOrMonth(
                                calendarDisplayDate[1]
                              )}/${padDayOrMonth(selectedDate[2])}/${y}`
                            );
                          }
                        } else {
                          if (
                            selectedDate.length === 0 &&
                            e.target.value !== ""
                          ) {
                            // No selectedDate, just set the calendarDisplayDate to the current month, and the year the user clicked.
                            setCalendarDisplayDate([y, calendarDisplayDate[1]]);
                          } else {
                            // A selectedDate was found, set the new selectedDate to the selected year, and current calendarDisplayDate.
                            setSelectedDate([
                              y,
                              calendarDisplayDate[1],
                              new Date(y, calendarDisplayDate[1], 0).getDate(),
                            ]);
                            setInputValue(
                              `${padDayOrMonth(
                                calendarDisplayDate[1]
                              )}/${padDayOrMonth(
                                new Date(
                                  y,
                                  calendarDisplayDate[1],
                                  0
                                ).getDate() as DayOfMonth
                              )}/${y}`
                            );
                          }
                        }
                      }}
                    >
                      {getYears(minDate[0], maxDate[0]).map((y, i) => {
                        return (
                          <Select.Option key={i} value={y}>
                            {y}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </div>
                  <div className={`tds-date-picker__arrows`}>
                    <button
                      className="tds-date-picker__arrow-prev"
                      disabled={position.transition !== undefined}
                      onClick={() => {
                        setCalendarQueuedDate([prevYear, prevMonth]);
                        screenReaderSpeak(
                          `Showing ${announceableDate([prevYear, prevMonth])}`
                        );
                      }}
                      tabIndex={0}
                      aria-label="Previous month"
                      ref={prevButtonRef}
                      {...previousMonthButtonProps}
                    >
                      <UtilityIcon
                        color="currentColor"
                        icon={ArrowBack}
                        size="medium"
                      />
                    </button>
                    <button
                      className="tds-date-picker__arrow-next"
                      disabled={position.transition !== undefined}
                      onClick={() => {
                        setCalendarQueuedDate([nextYear, nextMonth]);
                        screenReaderSpeak(
                          `Showing ${announceableDate([nextYear, nextMonth])}`
                        );
                      }}
                      tabIndex={0}
                      aria-label="Next month"
                      ref={nextButtonRef}
                      {...nextMonthButtonProps}
                    >
                      <UtilityIcon
                        color="currentColor"
                        icon={ArrowForward}
                        size="medium"
                      />
                    </button>
                  </div>
                </div>
                <div className={`tds-date-picker__years-months-container`}>
                  <div
                    className={`tds-date-picker__years`}
                    ref={yearsListRef}
                    {...yearListContainerProps}
                  ></div>
                  <div
                    className={`tds-date-picker__months`}
                    {...monthListContainerProps}
                  >
                    <div
                      className="tds-date-picker__month-slider-outer"
                      style={{
                        height: `${
                          weeksInActiveMonth.length * dayHeight + 15
                        }px`, // 15 takes care of the thead
                      }}
                    >
                      <div
                        className="tds-date-picker__month-slider-inner"
                        style={position}
                      >
                        <div
                          aria-hidden={true}
                          className="tds-date-picker__month"
                        >
                          <table className="tds-date-picker__month-table">
                            <thead className="tds-date-picker__month-table-headings">
                              <tr>
                                {dayNames.map((day, i) => (
                                  <th key={day}>
                                    <span aria-hidden="true">
                                      {dayNames[i]}
                                    </span>
                                    <span className="tds-sr-only">
                                      {dayNamesLong[i]}
                                    </span>
                                  </th>
                                ))}
                              </tr>
                            </thead>
                            <tbody>
                              {weeksInPrevMonth.map(
                                (week: Week[], f: number) => {
                                  const previousMonth = getPrevYearAndMonth([
                                    calendarDisplayDate[0],
                                    calendarDisplayDate[1],
                                  ]);
                                  return (
                                    <tr key={f}>
                                      {week.map((d: Week, i: number) => {
                                        if (d) {
                                          const previousMonthDateObj = new Date(
                                            previousMonth[0],
                                            previousMonth[1] - 1,
                                            d.date,
                                            12,
                                            0,
                                            0,
                                            0
                                          );
                                          const isSelectedDay = isEqualDate(
                                            [
                                              previousMonth[0],
                                              previousMonth[1],
                                              d.date,
                                            ],
                                            [
                                              selectedDate[0],
                                              selectedDate[1],
                                              selectedDate[2],
                                            ]
                                          );
                                          const isCurrentDay = isEqualDate(
                                            [
                                              previousMonth[0],
                                              previousMonth[1],
                                              d.date,
                                            ],
                                            [
                                              currentYear,
                                              currentMonth,
                                              currentDay,
                                            ]
                                          )
                                            ? true
                                            : undefined;
                                          return (
                                            <td
                                              key={i}
                                              className="tds-date-picker__day"
                                            >
                                              <button
                                                tabIndex={-1}
                                                disabled={
                                                  !isDateWithinRange(
                                                    previousMonthDateObj,
                                                    minDateObj,
                                                    maxDateObj
                                                  )
                                                }
                                                className={`tds-date-picker__day-button${
                                                  isSelectedDay
                                                    ? " tds-date-picker__day-button-selected"
                                                    : ""
                                                }${
                                                  isEqualDate(
                                                    [
                                                      previousMonth[0],
                                                      previousMonth[1],
                                                      d.date,
                                                    ],
                                                    [
                                                      currentYear,
                                                      currentMonth,
                                                      currentDay,
                                                    ]
                                                  )
                                                    ? " tds-date-picker__day-button-current"
                                                    : ""
                                                }`}
                                                {...(isSelectedDay && {
                                                  ...selectedDayButtonProps,
                                                })}
                                              >
                                                {d.date}
                                                {isCurrentDay && (
                                                  <span className="tds-sr-only">
                                                    , today
                                                  </span>
                                                )}
                                                {isSelectedDay && (
                                                  <span className="tds-sr-only">
                                                    , selected
                                                  </span>
                                                )}
                                              </button>
                                            </td>
                                          );
                                        }
                                        return (
                                          <td
                                            key={i}
                                            className="tds-date-picker__day"
                                          ></td>
                                        );
                                      })}
                                    </tr>
                                  );
                                }
                              )}
                            </tbody>
                          </table>
                        </div>
                        <div className="tds-date-picker__month">
                          <table className="tds-date-picker__month-table">
                            <thead className="tds-date-picker__month-table-headings">
                              <tr>
                                {dayNames.map((day, i) => (
                                  <th key={day}>
                                    <span aria-hidden="true">
                                      {dayNames[i]}
                                    </span>
                                    <span className="tds-sr-only">
                                      {dayNamesLong[i]}
                                    </span>
                                  </th>
                                ))}
                              </tr>
                            </thead>
                            <tbody>
                              {weeksInActiveMonth.map(
                                (week: Week[], f: number) => {
                                  return (
                                    <tr key={f}>
                                      {week.map((d: Week, i: number) => {
                                        if (d) {
                                          const calendarDisplayDateObj =
                                            new Date(
                                              calendarDisplayDate[0],
                                              calendarDisplayDate[1] - 1,
                                              d.date,
                                              12,
                                              0,
                                              0,
                                              0
                                            );
                                          const isSelectedDay = isEqualDate(
                                            [
                                              calendarDisplayDate[0],
                                              calendarDisplayDate[1],
                                              d.date,
                                            ],
                                            [
                                              selectedDate[0],
                                              selectedDate[1],
                                              selectedDate[2],
                                            ]
                                          );
                                          const isCurrentDay = isEqualDate(
                                            [
                                              calendarDisplayDate[0],
                                              calendarDisplayDate[1],
                                              d.date,
                                            ],
                                            [
                                              currentYear,
                                              currentMonth,
                                              currentDay,
                                            ]
                                          )
                                            ? true
                                            : undefined;
                                          return (
                                            <td
                                              key={i}
                                              className="tds-date-picker__day"
                                            >
                                              <button
                                                ref={(element) =>
                                                  (dayRefs.current[
                                                    `${id}-${calendarDisplayDate[0]}-${calendarDisplayDate[1]}-${d.date}`
                                                  ] = element)
                                                }
                                                data-week={f}
                                                data-date={d.date}
                                                tabIndex={setTabIndex(
                                                  isSelectedDay,
                                                  f,
                                                  d.date
                                                )}
                                                disabled={
                                                  !isDateWithinRange(
                                                    calendarDisplayDateObj,
                                                    minDateObj,
                                                    maxDateObj
                                                  )
                                                }
                                                className={`tds-date-picker__day-button${
                                                  isSelectedDay
                                                    ? ` tds-date-picker__day-button-selected`
                                                    : ""
                                                }${
                                                  isEqualDate(
                                                    [
                                                      calendarDisplayDate[0],
                                                      calendarDisplayDate[1],
                                                      d.date,
                                                    ],
                                                    [
                                                      currentYear,
                                                      currentMonth,
                                                      currentDay,
                                                    ]
                                                  )
                                                    ? " tds-date-picker__day-button-current"
                                                    : ""
                                                }`}
                                                onClick={(e) => {
                                                  setSelectedDate([
                                                    calendarDisplayDate[0],
                                                    calendarDisplayDate[1],
                                                    d.date,
                                                  ]);
                                                  setInputValue(
                                                    `${padDayOrMonth(
                                                      calendarDisplayDate[1]
                                                    )}/${padDayOrMonth(
                                                      d.date
                                                    )}/${
                                                      calendarDisplayDate[0]
                                                    }`
                                                  );
                                                  setOpen(false);
                                                }}
                                                {...(isSelectedDay && {
                                                  ...selectedDayButtonProps,
                                                })}
                                              >
                                                {d.date}
                                                {isCurrentDay && (
                                                  <span className="tds-sr-only">
                                                    , today
                                                  </span>
                                                )}
                                                {isSelectedDay && (
                                                  <span className="tds-sr-only">
                                                    , selected
                                                  </span>
                                                )}
                                              </button>
                                            </td>
                                          );
                                        }
                                        return (
                                          <td
                                            key={i}
                                            className="tds-date-picker__day"
                                          ></td>
                                        );
                                      })}
                                    </tr>
                                  );
                                }
                              )}
                            </tbody>
                          </table>
                        </div>
                        <div
                          className="tds-date-picker__month"
                          aria-hidden={true}
                        >
                          <table className="tds-date-picker__month-table">
                            <thead className="tds-date-picker__month-table-headings">
                              <tr>
                                {dayNames.map((day, i) => (
                                  <th key={day}>
                                    <span aria-hidden="true">
                                      {dayNames[i]}
                                    </span>
                                    <span className="tds-sr-only">
                                      {dayNamesLong[i]}
                                    </span>
                                  </th>
                                ))}
                              </tr>
                            </thead>
                            <tbody>
                              {weeksInNextMonth.map(
                                (week: Week[], f: number) => {
                                  const nextMonth = getNextYearAndMonth([
                                    calendarDisplayDate[0],
                                    calendarDisplayDate[1],
                                  ]);
                                  return (
                                    <tr key={f}>
                                      {week.map((d: Week, i: number) => {
                                        if (d) {
                                          const nextMonthDateObj = new Date(
                                            nextMonth[0],
                                            nextMonth[1] - 1,
                                            d.date,
                                            12,
                                            0,
                                            0,
                                            0
                                          );
                                          const isSelectedDay = isEqualDate(
                                            [
                                              nextMonth[0],
                                              nextMonth[1],
                                              d.date,
                                            ],
                                            [
                                              selectedDate[0],
                                              selectedDate[1],
                                              selectedDate[2],
                                            ]
                                          );
                                          const isCurrentDay = isEqualDate(
                                            [
                                              nextMonth[0],
                                              nextMonth[1],
                                              d.date,
                                            ],
                                            [
                                              currentYear,
                                              currentMonth,
                                              currentDay,
                                            ]
                                          )
                                            ? true
                                            : undefined;
                                          return (
                                            <td
                                              key={i}
                                              className="tds-date-picker__day"
                                            >
                                              <button
                                                tabIndex={-1}
                                                disabled={
                                                  !isDateWithinRange(
                                                    nextMonthDateObj,
                                                    minDateObj,
                                                    maxDateObj
                                                  )
                                                }
                                                className={`tds-date-picker__day-button${
                                                  isSelectedDay
                                                    ? " tds-date-picker__day-button-selected"
                                                    : ""
                                                }${
                                                  isEqualDate(
                                                    [
                                                      nextMonth[0],
                                                      nextMonth[1],
                                                      d.date,
                                                    ],
                                                    [
                                                      currentYear,
                                                      currentMonth,
                                                      currentDay,
                                                    ]
                                                  )
                                                    ? " tds-date-picker__day-button-current"
                                                    : ""
                                                }`}
                                                {...(isSelectedDay && {
                                                  ...selectedDayButtonProps,
                                                })}
                                              >
                                                {d.date}
                                                {isCurrentDay && (
                                                  <span className="tds-sr-only">
                                                    , today
                                                  </span>
                                                )}
                                                {isSelectedDay && (
                                                  <span className="tds-sr-only">
                                                    , selected
                                                  </span>
                                                )}
                                              </button>
                                            </td>
                                          );
                                        }
                                        return (
                                          <td
                                            key={i}
                                            className="tds-date-picker__day"
                                          ></td>
                                        );
                                      })}
                                    </tr>
                                  );
                                }
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </FocusTrap>
          )}
        </div>
        {(error !== undefined || state === "error") && (
          <span
            id={`${id}_error-message`}
            className="tds-form__error-message"
            {...errorMessageProps}
          >
            {error !== undefined ? error : invalidDateErrorText}
          </span>
        )}
        <span id={`${id}_instruction`} className="tds-form__instruction">
          {instructionText}
        </span>
      </div>
    </div>
  );
};

DatePicker.defaultProps = {
  min: "1900-01-01",
  max: `${new Date().getFullYear() + 5}-12-31`,
  label: "Choose a date:",
  instructionText: "Please enter MM/DD/YYYY",
  invalidDateErrorText: "The date is invalid",
  outOfRangeErrorText: "The date is out of range",
} as Partial<DatePickerProps>;

export default DatePicker;
