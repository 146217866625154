import * as React from "react";

export interface LevelItemProps {
  // HTML Props
  /** Props passed to the Level.Item. */
  contentContainerProps?: React.HTMLProps<HTMLDivElement>;
}

const AuthNavLevelItem: React.FC<
  LevelItemProps & React.HTMLProps<HTMLDivElement>
> = ({ children, contentContainerProps, ...rest }) => (
  <div className="s-level-item" {...rest}>
    <div className="s-level-item-container" {...contentContainerProps}>
      {children}
    </div>
  </div>
);

AuthNavLevelItem.displayName = "AuthNav.LevelItem";

export default AuthNavLevelItem;
