import * as React from "react";
import classNames from "classnames";

export interface FooterSocialSectionProps {
  [x: string]: any;
}

/**
 * This is an FooterSocialSection
 * @param {FooterSocialSectionProps} props
 */
const FooterSocialSection: React.FunctionComponent<FooterSocialSectionProps> = (
  props
) => {
  const { className, children, ...other } = props;

  let footerClasses = classNames({
    "tds-footer__social-section": true,
  });

  footerClasses = className
    ? footerClasses.concat(" ", className)
    : footerClasses;

  return (
    <div className={footerClasses} {...other}>
      {children}
    </div>
  );
};

FooterSocialSection.defaultProps = {} as Partial<FooterSocialSectionProps>;

export default FooterSocialSection;
