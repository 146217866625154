import React, { Component } from "react";
import OneSubStep from "./steps/OneSubStep";
import TwoSubStep from "./steps/TwoSubStep";
import classNames from "classnames";
import ThreeSubStep from "./steps/ThreeSubStep";
import FourSubStep from "./steps/FourSubStep";
import StepTitle from "./steps/StepTitle";

export interface StepTrackerProps {
  [x: string]: any;
}

export default class StepTracker extends Component<StepTrackerProps> {
  static OneSubStep = OneSubStep;
  static TwoSubStep = TwoSubStep;
  static ThreeSubStep = ThreeSubStep;
  static FourSubStep = FourSubStep;

  render() {
    const { className, children, ...other } = this.props;
    const elements = React.Children.toArray(children);
    const stepTitles: JSX.Element[] = [];

    if (elements.length < 1) {
      console.warn("StepTracker must have at least one child");
    }

    elements.forEach((element, index) => {
      if (React.isValidElement(element)) {
        switch (element.type) {
          case OneSubStep:
          case TwoSubStep:
          case ThreeSubStep:
          case FourSubStep:
            stepTitles.push(
              <StepTitle
                title={element.props.title}
                subtitle={element.props.subtitle}
                placeholder={true}
              />
            );
            if (elements.length > 1)
              stepTitles.push(
                <span className="tds-step-tracker__placeholder-title-divider"></span>
              );
            break;
          default:
            console.warn(
              "Warning StepTracker has children that aren't SubStep components"
            );
            break;
        }
      }
    });

    let StepTrackerClasses = classNames({
      "tds-step-tracker": true,
    });

    StepTrackerClasses = className
      ? StepTrackerClasses.concat(" ", className)
      : StepTrackerClasses;

    return (
      <div>
        <ol className={StepTrackerClasses} {...other}>
          {elements.length > 1
            ? React.Children.map(children, (child, i) => {
                if (i < React.Children.count(children) - 1) {
                  return (
                    <React.Fragment key={i}>
                      {child}
                      <span className="tds-step-tracker__divider"></span>
                    </React.Fragment>
                  );
                } else {
                  return child;
                }
              })
            : children}
        </ol>
        {stepTitles.length > 0 && (
          <div className="tds-step-tracker__placeholder-title-container">
            {stepTitles.map((component, index) => (
              <React.Fragment key={index}>{component}</React.Fragment>
            ))}
          </div>
        )}
      </div>
    );
  }
}
