import * as React from "react";

export interface AuthSubNavLinkProps {
  /** Active/non-active link. */
  active?: boolean;
  /** href is required (accessibility) */
  href: Pick<React.HTMLProps<HTMLAnchorElement>, "href"> | string;
  listItemProps?: React.HTMLProps<HTMLLIElement>;
  setExpanded?: (expanded: boolean) => void;
  expanded?: boolean;
  pageContext?: string;
}

const AuthSubNavLink: React.FC<
  AuthSubNavLinkProps & React.HTMLProps<HTMLLIElement>
> = ({
  children,
  active,
  href = "#",
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setExpanded = () => {},
  expanded,
  listItemProps,
  pageContext,
  ...rest
}) => {
  // const trackedOnClick = useClickTracking(onClickTrackingData, onClick);

  return (
    <li
      className={`s-nav-item${expanded ? " s-nav-item-expanded" : ""}${
        active ? " s-nav-item-active" : ""
      }`}
      onClick={() => setExpanded(!expanded)}
      {...listItemProps}
    >
      <a
        className={`s-nav-link${active ? " s-nav-link-active" : ""}`}
        href={href}
      >
        {pageContext && (
          <React.Fragment>
            <span className="s-nav-sr-only">{pageContext}:</span>{" "}
          </React.Fragment>
        )}
        {children}
        {active && (
          <React.Fragment>
            <span className="s-nav-sr-only">, current page</span>
          </React.Fragment>
        )}
      </a>
    </li>
  );
};

AuthSubNavLink.displayName = "AuthNav.SubNavLink";

export default AuthSubNavLink;
