import * as React from "react";
import classNames from "classnames";

export interface CheckboxProps {
  /**
   * Adjusts the color of the checkbox based on state
   */
  state?: "default" | "error" | "disabled";
  /**
   * Applies the name attribute on the checkbox (used by FormGroup)
   */
  groupName?: string;
  /**
   * Adds label text to checkbox
   */
  label: string;
  /**
   * Id reference for error and instruction messages
   */
  ariaDescribedBy?: string;
}

export type CheckboxType = React.FunctionComponent<
  CheckboxProps & React.HTMLProps<HTMLInputElement>
>;

/**
 * This is a checkbox
 * @param {CheckboxProps} props
 */
const Checkbox: CheckboxType = (props) => {
  const {
    className,
    groupName,
    state,
    id,
    label,
    ariaDescribedBy,
    ...other
  } = props;

  let CheckboxGroupClasses = classNames({
    "tds-form__checkbox": true,
    "tds-form__checkbox--error": state === "error",
  });

  let CheckBoxSVGRectClasses = classNames({
    "tds-form__checkbox-bg": true,
    "tds-form__checkbox-bg--error": state === "error",
  });

  CheckboxGroupClasses = className
    ? CheckboxGroupClasses.concat(" ", className)
    : CheckboxGroupClasses;

  return (
    <label className={CheckboxGroupClasses} htmlFor={id}>
      <input
        type="checkbox"
        name={groupName}
        id={id}
        aria-describedby={ariaDescribedBy}
        {...other}
      />

      <svg
        width="24"
        height="24"
        version="1.1"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        className="tds-form__checkbox-svg"
        aria-hidden="true"
        role="presentation"
        focusable="false"
      >
        <rect
          stroke="#196ECF"
          strokeWidth="2"
          x="1"
          y="1"
          width="22"
          height="22"
          fill="none"
          className="tds-form__checkbox-focus"
        />
        <rect
          x=".95031"
          y=".95031"
          width="14"
          height="14"
          rx="2"
          fill="#FFFFFF"
          stroke="#6f6f6f"
          strokeWidth="2"
          className={CheckBoxSVGRectClasses}
          transform="translate(4, 4)"
        />
        <path
          d="m3.4444 10.326 4.3762 3.0172 6.4373-9.1298"
          fill="none"
          stroke="none"
          strokeWidth="2"
          className="tds-form__checkbox-check"
          transform="translate(4, 3.5), scale(0.9)"
        />
      </svg>

      <span className="tds-form__checkbox-label-text">{label}</span>
    </label>
  );
};

Checkbox.defaultProps = {} as Partial<CheckboxProps>;

export default Checkbox;
