import * as React from "react";
import { getUniqueId, UniqueIdentifier } from "./uniqueIdentifier";

const PREFIX = ""; //if we want a namespaced id, change this to "tds";

const buildUniqueIdentifier = (
  uniqueKey: string,
  componentName: string,
  userTitle?: string | number
) => {
  const userValue = typeof userTitle === "string" ? userTitle : undefined;
  const prefix = PREFIX.length ? `${PREFIX}_` : "";
  const combinedKey = `${userValue ? userValue + "-" : ""}${uniqueKey}`;
  const id = `${prefix}${componentName}_${combinedKey}`;
  return {
    getId: () => id,
    getChildId: (childKey: string) => `${id}_${childKey}`,
    getDerivedIdentifier: (componentName: string, index: number) => {
      // zero indexes are not semantic when describing actual elements, so adding 1 to keep id descriptive.
      return buildUniqueIdentifier(
        combinedKey,
        `${componentName}-${index + 1}`
      );
    },
  };
};

const useUniqueId = (
  componentName: string,
  userTitle?: string | number
): UniqueIdentifier => {
  const ref = React.useRef<number>();

  const [uniqueKey, setUniqueKey] = React.useState("000");
  // NOTE: In the future, we should look at using react 17 useOpaqueId, but we will still need fall backs
  // -- Because of the way we have structured this functionality, we should be able to change the uniqueKey above to the opaqueId without causing issues throughout the system.

  // @ts-ignore
  React.useEffect(() => {
    // in testing this doesn't trigger
    setUniqueKey(getUniqueId(userTitle));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref.current]);

  return buildUniqueIdentifier(uniqueKey, componentName, userTitle);
};

export default useUniqueId;
