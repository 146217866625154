const tokens = {
    "font": {
        "family": {
            "heading": {
                "one": {
                    "value": "'UHC Serif Headline SemiBold', Georgia Bold, serif",
                    "filePath": "properties/globals/font/base.json",
                    "isSource": true,
                    "original": {
                        "value": "{font.brand.family.heading.one.value}"
                    },
                    "name": "tds-font-family-heading-one",
                    "attributes": {
                        "category": "font",
                        "type": "family",
                        "item": "heading",
                        "subitem": "one"
                    },
                    "path": [
                        "font",
                        "family",
                        "heading",
                        "one"
                    ]
                },
                "two": {
                    "value": "'UHC Serif Headline SemiBold', Georgia Bold, serif",
                    "filePath": "properties/globals/font/base.json",
                    "isSource": true,
                    "original": {
                        "value": "{font.brand.family.heading.two.value}"
                    },
                    "name": "tds-font-family-heading-two",
                    "attributes": {
                        "category": "font",
                        "type": "family",
                        "item": "heading",
                        "subitem": "two"
                    },
                    "path": [
                        "font",
                        "family",
                        "heading",
                        "two"
                    ]
                },
                "three": {
                    "value": "'UHC Serif Headline SemiBold', Georgia Bold, serif",
                    "filePath": "properties/globals/font/base.json",
                    "isSource": true,
                    "original": {
                        "value": "{font.brand.family.heading.three.value}"
                    },
                    "name": "tds-font-family-heading-three",
                    "attributes": {
                        "category": "font",
                        "type": "family",
                        "item": "heading",
                        "subitem": "three"
                    },
                    "path": [
                        "font",
                        "family",
                        "heading",
                        "three"
                    ]
                },
                "four": {
                    "value": "'UHC Sans', Arial, sans-serif",
                    "filePath": "properties/globals/font/base.json",
                    "isSource": true,
                    "original": {
                        "value": "{font.brand.family.heading.four.value}"
                    },
                    "name": "tds-font-family-heading-four",
                    "attributes": {
                        "category": "font",
                        "type": "family",
                        "item": "heading",
                        "subitem": "four"
                    },
                    "path": [
                        "font",
                        "family",
                        "heading",
                        "four"
                    ]
                },
                "five": {
                    "value": "'UHC Sans', Arial, sans-serif",
                    "filePath": "properties/globals/font/base.json",
                    "isSource": true,
                    "original": {
                        "value": "{font.brand.family.heading.five.value}"
                    },
                    "name": "tds-font-family-heading-five",
                    "attributes": {
                        "category": "font",
                        "type": "family",
                        "item": "heading",
                        "subitem": "five"
                    },
                    "path": [
                        "font",
                        "family",
                        "heading",
                        "five"
                    ]
                },
                "six": {
                    "value": "'UHC Sans', Arial, sans-serif",
                    "filePath": "properties/globals/font/base.json",
                    "isSource": true,
                    "original": {
                        "value": "{font.brand.family.heading.six.value}"
                    },
                    "name": "tds-font-family-heading-six",
                    "attributes": {
                        "category": "font",
                        "type": "family",
                        "item": "heading",
                        "subitem": "six"
                    },
                    "path": [
                        "font",
                        "family",
                        "heading",
                        "six"
                    ]
                },
                "mobile": {
                    "one": {
                        "value": "'UHC Serif Headline SemiBold', Georgia Bold, serif",
                        "filePath": "properties/globals/font/base.json",
                        "isSource": true,
                        "original": {
                            "value": "{font.brand.family.heading.mobile.one.value}"
                        },
                        "name": "tds-font-family-heading-mobile-one",
                        "attributes": {
                            "category": "font",
                            "type": "family",
                            "item": "heading",
                            "subitem": "mobile",
                            "state": "one"
                        },
                        "path": [
                            "font",
                            "family",
                            "heading",
                            "mobile",
                            "one"
                        ]
                    },
                    "two": {
                        "value": "'UHC Serif Headline SemiBold', Georgia Bold, serif",
                        "filePath": "properties/globals/font/base.json",
                        "isSource": true,
                        "original": {
                            "value": "{font.brand.family.heading.mobile.two.value}"
                        },
                        "name": "tds-font-family-heading-mobile-two",
                        "attributes": {
                            "category": "font",
                            "type": "family",
                            "item": "heading",
                            "subitem": "mobile",
                            "state": "two"
                        },
                        "path": [
                            "font",
                            "family",
                            "heading",
                            "mobile",
                            "two"
                        ]
                    },
                    "three": {
                        "value": "'UHC Sans', Arial, sans-serif",
                        "filePath": "properties/globals/font/base.json",
                        "isSource": true,
                        "original": {
                            "value": "{font.brand.family.heading.mobile.three.value}"
                        },
                        "name": "tds-font-family-heading-mobile-three",
                        "attributes": {
                            "category": "font",
                            "type": "family",
                            "item": "heading",
                            "subitem": "mobile",
                            "state": "three"
                        },
                        "path": [
                            "font",
                            "family",
                            "heading",
                            "mobile",
                            "three"
                        ]
                    },
                    "four": {
                        "value": "'UHC Sans', Arial, sans-serif",
                        "filePath": "properties/globals/font/base.json",
                        "isSource": true,
                        "original": {
                            "value": "{font.brand.family.heading.mobile.four.value}"
                        },
                        "name": "tds-font-family-heading-mobile-four",
                        "attributes": {
                            "category": "font",
                            "type": "family",
                            "item": "heading",
                            "subitem": "mobile",
                            "state": "four"
                        },
                        "path": [
                            "font",
                            "family",
                            "heading",
                            "mobile",
                            "four"
                        ]
                    },
                    "five": {
                        "value": "'UHC Sans', Arial, sans-serif",
                        "filePath": "properties/globals/font/base.json",
                        "isSource": true,
                        "original": {
                            "value": "{font.brand.family.heading.mobile.five.value}"
                        },
                        "name": "tds-font-family-heading-mobile-five",
                        "attributes": {
                            "category": "font",
                            "type": "family",
                            "item": "heading",
                            "subitem": "mobile",
                            "state": "five"
                        },
                        "path": [
                            "font",
                            "family",
                            "heading",
                            "mobile",
                            "five"
                        ]
                    },
                    "six": {
                        "value": "'UHC Sans', Arial, sans-serif",
                        "filePath": "properties/globals/font/base.json",
                        "isSource": true,
                        "original": {
                            "value": "{font.brand.family.heading.mobile.six.value}"
                        },
                        "name": "tds-font-family-heading-mobile-six",
                        "attributes": {
                            "category": "font",
                            "type": "family",
                            "item": "heading",
                            "subitem": "mobile",
                            "state": "six"
                        },
                        "path": [
                            "font",
                            "family",
                            "heading",
                            "mobile",
                            "six"
                        ]
                    }
                }
            },
            "paragraph": {
                "value": "'UHC Sans', Arial, sans-serif",
                "filePath": "properties/globals/font/base.json",
                "isSource": true,
                "original": {
                    "value": "{font.brand.family.paragraph.value}"
                },
                "name": "tds-font-family-paragraph",
                "attributes": {
                    "category": "font",
                    "type": "family",
                    "item": "paragraph"
                },
                "path": [
                    "font",
                    "family",
                    "paragraph"
                ]
            },
            "footnote": {
                "value": "'UHC Sans', Arial, sans-serif",
                "filePath": "properties/globals/font/base.json",
                "isSource": true,
                "original": {
                    "value": "{font.brand.family.footnote.value}"
                },
                "name": "tds-font-family-footnote",
                "attributes": {
                    "category": "font",
                    "type": "family",
                    "item": "footnote"
                },
                "path": [
                    "font",
                    "family",
                    "footnote"
                ]
            },
            "link": {
                "value": "'UHC Sans', Arial, sans-serif",
                "filePath": "properties/globals/font/base.json",
                "isSource": true,
                "original": {
                    "value": "{font.brand.family.link.value}"
                },
                "name": "tds-font-family-link",
                "attributes": {
                    "category": "font",
                    "type": "family",
                    "item": "link"
                },
                "path": [
                    "font",
                    "family",
                    "link"
                ]
            },
            "label": {
                "value": "'UHC2020 Sans Semibold', Arial, sans-serif",
                "filePath": "properties/globals/font/base.json",
                "isSource": true,
                "original": {
                    "value": "{font.brand.family.sans.semibold.value}"
                },
                "name": "tds-font-family-label",
                "attributes": {
                    "category": "font",
                    "type": "family",
                    "item": "label"
                },
                "path": [
                    "font",
                    "family",
                    "label"
                ]
            },
            "serif": {
                "semibold": {
                    "value": "'UHC Serif Headline SemiBold', Georgia Bold, serif",
                    "filePath": "properties/globals/font/families.json",
                    "isSource": true,
                    "original": {
                        "value": "{font.brand.family.serif.semibold.value}"
                    },
                    "name": "tds-font-family-serif-semibold",
                    "attributes": {
                        "category": "font",
                        "type": "family",
                        "item": "serif",
                        "subitem": "semibold"
                    },
                    "path": [
                        "font",
                        "family",
                        "serif",
                        "semibold"
                    ]
                }
            },
            "sans": {
                "base": {
                    "value": "'UHC Sans', Arial, sans-serif",
                    "filePath": "properties/globals/font/families.json",
                    "isSource": true,
                    "original": {
                        "value": "{font.brand.family.sans.base.value}"
                    },
                    "name": "tds-font-family-sans-base",
                    "attributes": {
                        "category": "font",
                        "type": "family",
                        "item": "sans",
                        "subitem": "base"
                    },
                    "path": [
                        "font",
                        "family",
                        "sans",
                        "base"
                    ]
                },
                "bold": {
                    "value": "'UHC2020 Sans Bold', Arial, sans-serif",
                    "comment": "For backwards compatibility only. Will be removed soon.",
                    "filePath": "properties/globals/font/families.json",
                    "isSource": true,
                    "original": {
                        "value": "{font.brand.family.sans.bold.value}",
                        "comment": "For backwards compatibility only. Will be removed soon."
                    },
                    "name": "tds-font-family-sans-bold",
                    "attributes": {
                        "category": "font",
                        "type": "family",
                        "item": "sans",
                        "subitem": "bold"
                    },
                    "path": [
                        "font",
                        "family",
                        "sans",
                        "bold"
                    ]
                },
                "medium": {
                    "value": "'UHC2020 Sans Medium', Arial, sans-serif",
                    "comment": "For backwards compatibility only. Will be removed soon.",
                    "filePath": "properties/globals/font/families.json",
                    "isSource": true,
                    "original": {
                        "value": "{font.brand.family.sans.medium.value}",
                        "comment": "For backwards compatibility only. Will be removed soon."
                    },
                    "name": "tds-font-family-sans-medium",
                    "attributes": {
                        "category": "font",
                        "type": "family",
                        "item": "sans",
                        "subitem": "medium"
                    },
                    "path": [
                        "font",
                        "family",
                        "sans",
                        "medium"
                    ]
                },
                "semibold": {
                    "value": "'UHC2020 Sans Semibold', Arial, sans-serif",
                    "comment": "For backwards compatibility only. Will be removed soon.",
                    "filePath": "properties/globals/font/families.json",
                    "isSource": true,
                    "original": {
                        "value": "{font.brand.family.sans.semibold.value}",
                        "comment": "For backwards compatibility only. Will be removed soon."
                    },
                    "name": "tds-font-family-sans-semibold",
                    "attributes": {
                        "category": "font",
                        "type": "family",
                        "item": "sans",
                        "subitem": "semibold"
                    },
                    "path": [
                        "font",
                        "family",
                        "sans",
                        "semibold"
                    ]
                }
            }
        },
        "size": {
            "base": {
                "font": {
                    "value": "1rem",
                    "filePath": "properties/globals/font/base.json",
                    "isSource": true,
                    "original": {
                        "value": "{font.brand.size.base.font.value}"
                    },
                    "name": "tds-font-size-base-font",
                    "attributes": {
                        "category": "font",
                        "type": "size",
                        "item": "base",
                        "subitem": "font"
                    },
                    "path": [
                        "font",
                        "size",
                        "base",
                        "font"
                    ]
                }
            },
            "heading": {
                "one": {
                    "value": "2.281rem",
                    "filePath": "properties/globals/font/base.json",
                    "isSource": true,
                    "original": {
                        "value": "{font.brand.size.heading.one.value}"
                    },
                    "name": "tds-font-size-heading-one",
                    "attributes": {
                        "category": "font",
                        "type": "size",
                        "item": "heading",
                        "subitem": "one"
                    },
                    "path": [
                        "font",
                        "size",
                        "heading",
                        "one"
                    ]
                },
                "two": {
                    "value": "2.017rem",
                    "filePath": "properties/globals/font/base.json",
                    "isSource": true,
                    "original": {
                        "value": "{font.brand.size.heading.two.value}"
                    },
                    "name": "tds-font-size-heading-two",
                    "attributes": {
                        "category": "font",
                        "type": "size",
                        "item": "heading",
                        "subitem": "two"
                    },
                    "path": [
                        "font",
                        "size",
                        "heading",
                        "two"
                    ]
                },
                "three": {
                    "value": "1.802rem",
                    "filePath": "properties/globals/font/base.json",
                    "isSource": true,
                    "original": {
                        "value": "{font.brand.size.heading.three.value}"
                    },
                    "name": "tds-font-size-heading-three",
                    "attributes": {
                        "category": "font",
                        "type": "size",
                        "item": "heading",
                        "subitem": "three"
                    },
                    "path": [
                        "font",
                        "size",
                        "heading",
                        "three"
                    ]
                },
                "four": {
                    "value": "1.602rem",
                    "filePath": "properties/globals/font/base.json",
                    "isSource": true,
                    "original": {
                        "value": "{font.brand.size.heading.four.value}"
                    },
                    "name": "tds-font-size-heading-four",
                    "attributes": {
                        "category": "font",
                        "type": "size",
                        "item": "heading",
                        "subitem": "four"
                    },
                    "path": [
                        "font",
                        "size",
                        "heading",
                        "four"
                    ]
                },
                "five": {
                    "value": "1.424rem",
                    "filePath": "properties/globals/font/base.json",
                    "isSource": true,
                    "original": {
                        "value": "{font.brand.size.heading.five.value}"
                    },
                    "name": "tds-font-size-heading-five",
                    "attributes": {
                        "category": "font",
                        "type": "size",
                        "item": "heading",
                        "subitem": "five"
                    },
                    "path": [
                        "font",
                        "size",
                        "heading",
                        "five"
                    ]
                },
                "six": {
                    "value": "1.266rem",
                    "filePath": "properties/globals/font/base.json",
                    "isSource": true,
                    "original": {
                        "value": "{font.brand.size.heading.six.value}"
                    },
                    "name": "tds-font-size-heading-six",
                    "attributes": {
                        "category": "font",
                        "type": "size",
                        "item": "heading",
                        "subitem": "six"
                    },
                    "path": [
                        "font",
                        "size",
                        "heading",
                        "six"
                    ]
                },
                "mobile": {
                    "one": {
                        "value": "1.802rem",
                        "filePath": "properties/globals/font/base.json",
                        "isSource": true,
                        "original": {
                            "value": "{font.brand.size.heading.mobile.one.value}"
                        },
                        "name": "tds-font-size-heading-mobile-one",
                        "attributes": {
                            "category": "font",
                            "type": "size",
                            "item": "heading",
                            "subitem": "mobile",
                            "state": "one"
                        },
                        "path": [
                            "font",
                            "size",
                            "heading",
                            "mobile",
                            "one"
                        ]
                    },
                    "two": {
                        "value": "1.609rem",
                        "filePath": "properties/globals/font/base.json",
                        "isSource": true,
                        "original": {
                            "value": "{font.brand.size.heading.mobile.two.value}"
                        },
                        "name": "tds-font-size-heading-mobile-two",
                        "attributes": {
                            "category": "font",
                            "type": "size",
                            "item": "heading",
                            "subitem": "mobile",
                            "state": "two"
                        },
                        "path": [
                            "font",
                            "size",
                            "heading",
                            "mobile",
                            "two"
                        ]
                    },
                    "three": {
                        "value": "1.424rem",
                        "filePath": "properties/globals/font/base.json",
                        "isSource": true,
                        "original": {
                            "value": "{font.brand.size.heading.mobile.three.value}"
                        },
                        "name": "tds-font-size-heading-mobile-three",
                        "attributes": {
                            "category": "font",
                            "type": "size",
                            "item": "heading",
                            "subitem": "mobile",
                            "state": "three"
                        },
                        "path": [
                            "font",
                            "size",
                            "heading",
                            "mobile",
                            "three"
                        ]
                    },
                    "four": {
                        "value": "1.266rem",
                        "filePath": "properties/globals/font/base.json",
                        "isSource": true,
                        "original": {
                            "value": "{font.brand.size.heading.mobile.four.value}"
                        },
                        "name": "tds-font-size-heading-mobile-four",
                        "attributes": {
                            "category": "font",
                            "type": "size",
                            "item": "heading",
                            "subitem": "mobile",
                            "state": "four"
                        },
                        "path": [
                            "font",
                            "size",
                            "heading",
                            "mobile",
                            "four"
                        ]
                    },
                    "five": {
                        "value": "1.125rem",
                        "filePath": "properties/globals/font/base.json",
                        "isSource": true,
                        "original": {
                            "value": "{font.brand.size.heading.mobile.five.value}"
                        },
                        "name": "tds-font-size-heading-mobile-five",
                        "attributes": {
                            "category": "font",
                            "type": "size",
                            "item": "heading",
                            "subitem": "mobile",
                            "state": "five"
                        },
                        "path": [
                            "font",
                            "size",
                            "heading",
                            "mobile",
                            "five"
                        ]
                    },
                    "six": {
                        "value": "1rem",
                        "filePath": "properties/globals/font/base.json",
                        "isSource": true,
                        "original": {
                            "value": "{font.brand.size.heading.mobile.six.value}"
                        },
                        "name": "tds-font-size-heading-mobile-six",
                        "attributes": {
                            "category": "font",
                            "type": "size",
                            "item": "heading",
                            "subitem": "mobile",
                            "state": "six"
                        },
                        "path": [
                            "font",
                            "size",
                            "heading",
                            "mobile",
                            "six"
                        ]
                    }
                }
            },
            "paragraph": {
                "default": {
                    "value": "1rem",
                    "filePath": "properties/globals/font/base.json",
                    "isSource": true,
                    "original": {
                        "value": "{font.brand.size.paragraph.value}"
                    },
                    "name": "tds-font-size-paragraph-default",
                    "attributes": {
                        "category": "font",
                        "type": "size",
                        "item": "paragraph",
                        "subitem": "default"
                    },
                    "path": [
                        "font",
                        "size",
                        "paragraph",
                        "default"
                    ]
                },
                "small": {
                    "value": ".889rem",
                    "filePath": "properties/globals/font/base.json",
                    "isSource": true,
                    "original": {
                        "value": "{font.brand.size.paragraph.small.value}"
                    },
                    "name": "tds-font-size-paragraph-small",
                    "attributes": {
                        "category": "font",
                        "type": "size",
                        "item": "paragraph",
                        "subitem": "small"
                    },
                    "path": [
                        "font",
                        "size",
                        "paragraph",
                        "small"
                    ]
                },
                "large": {
                    "value": "1.125rem",
                    "filePath": "properties/globals/font/base.json",
                    "isSource": true,
                    "original": {
                        "value": "{font.brand.size.paragraph.large.value}"
                    },
                    "name": "tds-font-size-paragraph-large",
                    "attributes": {
                        "category": "font",
                        "type": "size",
                        "item": "paragraph",
                        "subitem": "large"
                    },
                    "path": [
                        "font",
                        "size",
                        "paragraph",
                        "large"
                    ]
                },
                "mobile": {
                    "value": ".889rem",
                    "large": {
                        "value": "1rem",
                        "filePath": "properties/globals/font/base.json",
                        "isSource": true
                    },
                    "filePath": "properties/globals/font/base.json",
                    "isSource": true,
                    "original": {
                        "value": "{font.brand.size.paragraph.mobile.value}",
                        "large": {
                            "value": "{font.brand.size.paragraph.value}",
                            "filePath": "properties/globals/font/base.json",
                            "isSource": true
                        }
                    },
                    "name": "tds-font-size-paragraph-mobile",
                    "attributes": {
                        "category": "font",
                        "type": "size",
                        "item": "paragraph",
                        "subitem": "mobile"
                    },
                    "path": [
                        "font",
                        "size",
                        "paragraph",
                        "mobile"
                    ]
                }
            },
            "footnote": {
                "value": "0.79rem",
                "filePath": "properties/globals/font/base.json",
                "isSource": true,
                "original": {
                    "value": "{font.brand.size.footnote.value}"
                },
                "name": "tds-font-size-footnote",
                "attributes": {
                    "category": "font",
                    "type": "size",
                    "item": "footnote"
                },
                "path": [
                    "font",
                    "size",
                    "footnote"
                ]
            },
            "label": {
                "value": "0.875rem",
                "filePath": "properties/globals/font/base.json",
                "isSource": true,
                "original": {
                    "value": "{font.brand.size.label.value}"
                },
                "name": "tds-font-size-label",
                "attributes": {
                    "category": "font",
                    "type": "size",
                    "item": "label"
                },
                "path": [
                    "font",
                    "size",
                    "label"
                ]
            },
            "link": {
                "default": {
                    "value": "1rem",
                    "filePath": "properties/globals/font/base.json",
                    "isSource": true,
                    "original": {
                        "value": "{font.brand.size.link.value}"
                    },
                    "name": "tds-font-size-link-default",
                    "attributes": {
                        "category": "font",
                        "type": "size",
                        "item": "link",
                        "subitem": "default"
                    },
                    "path": [
                        "font",
                        "size",
                        "link",
                        "default"
                    ]
                },
                "small": {
                    "value": "0.79rem",
                    "filePath": "properties/globals/font/base.json",
                    "isSource": true,
                    "original": {
                        "value": "{font.brand.size.link.small.value}"
                    },
                    "name": "tds-font-size-link-small",
                    "attributes": {
                        "category": "font",
                        "type": "size",
                        "item": "link",
                        "subitem": "small"
                    },
                    "path": [
                        "font",
                        "size",
                        "link",
                        "small"
                    ]
                },
                "large": {
                    "value": "1.125rem",
                    "filePath": "properties/globals/font/base.json",
                    "isSource": true,
                    "original": {
                        "value": "{font.brand.size.link.large.value}"
                    },
                    "name": "tds-font-size-link-large",
                    "attributes": {
                        "category": "font",
                        "type": "size",
                        "item": "link",
                        "subitem": "large"
                    },
                    "path": [
                        "font",
                        "size",
                        "link",
                        "large"
                    ]
                }
            }
        },
        "line-height": {
            "heading": {
                "one": {
                    "value": "1.2058",
                    "filePath": "properties/globals/font/base.json",
                    "isSource": true,
                    "original": {
                        "value": "{font.brand.line-height.heading.one.value}"
                    },
                    "name": "tds-font-line-height-heading-one",
                    "attributes": {
                        "category": "font",
                        "type": "line-height",
                        "item": "heading",
                        "subitem": "one"
                    },
                    "path": [
                        "font",
                        "line-height",
                        "heading",
                        "one"
                    ]
                },
                "two": {
                    "value": "1.2330",
                    "filePath": "properties/globals/font/base.json",
                    "isSource": true,
                    "original": {
                        "value": "{font.brand.line-height.heading.two.value}"
                    },
                    "name": "tds-font-line-height-heading-two",
                    "attributes": {
                        "category": "font",
                        "type": "line-height",
                        "item": "heading",
                        "subitem": "two"
                    },
                    "path": [
                        "font",
                        "line-height",
                        "heading",
                        "two"
                    ]
                },
                "three": {
                    "value": "1.2487",
                    "filePath": "properties/globals/font/base.json",
                    "isSource": true,
                    "original": {
                        "value": "{font.brand.line-height.heading.three.value}"
                    },
                    "name": "tds-font-line-height-heading-three",
                    "attributes": {
                        "category": "font",
                        "type": "line-height",
                        "item": "heading",
                        "subitem": "three"
                    },
                    "path": [
                        "font",
                        "line-height",
                        "heading",
                        "three"
                    ]
                },
                "four": {
                    "value": "1.2485",
                    "filePath": "properties/globals/font/base.json",
                    "isSource": true,
                    "original": {
                        "value": "{font.brand.line-height.heading.four.value}"
                    },
                    "name": "tds-font-line-height-heading-four",
                    "attributes": {
                        "category": "font",
                        "type": "line-height",
                        "item": "heading",
                        "subitem": "four"
                    },
                    "path": [
                        "font",
                        "line-height",
                        "heading",
                        "four"
                    ]
                },
                "five": {
                    "value": "1.4047",
                    "filePath": "properties/globals/font/base.json",
                    "isSource": true,
                    "original": {
                        "value": "{font.brand.line-height.heading.five.value}"
                    },
                    "name": "tds-font-line-height-heading-five",
                    "attributes": {
                        "category": "font",
                        "type": "line-height",
                        "item": "heading",
                        "subitem": "five"
                    },
                    "path": [
                        "font",
                        "line-height",
                        "heading",
                        "five"
                    ]
                },
                "six": {
                    "value": "1.1851",
                    "filePath": "properties/globals/font/base.json",
                    "isSource": true,
                    "original": {
                        "value": "{font.brand.line-height.heading.six.value}"
                    },
                    "name": "tds-font-line-height-heading-six",
                    "attributes": {
                        "category": "font",
                        "type": "line-height",
                        "item": "heading",
                        "subitem": "six"
                    },
                    "path": [
                        "font",
                        "line-height",
                        "heading",
                        "six"
                    ]
                },
                "mobile": {
                    "one": {
                        "value": "1.2487",
                        "filePath": "properties/globals/font/base.json",
                        "isSource": true,
                        "original": {
                            "value": "{font.brand.line-height.heading.mobile.one.value}"
                        },
                        "name": "tds-font-line-height-heading-mobile-one",
                        "attributes": {
                            "category": "font",
                            "type": "line-height",
                            "item": "heading",
                            "subitem": "mobile",
                            "state": "one"
                        },
                        "path": [
                            "font",
                            "line-height",
                            "heading",
                            "mobile",
                            "one"
                        ]
                    },
                    "two": {
                        "value": "1.2485",
                        "filePath": "properties/globals/font/base.json",
                        "isSource": true,
                        "original": {
                            "value": "{font.brand.line-height.heading.mobile.two.value}"
                        },
                        "name": "tds-font-line-height-heading-mobile-two",
                        "attributes": {
                            "category": "font",
                            "type": "line-height",
                            "item": "heading",
                            "subitem": "mobile",
                            "state": "two"
                        },
                        "path": [
                            "font",
                            "line-height",
                            "heading",
                            "mobile",
                            "two"
                        ]
                    },
                    "three": {
                        "value": "1.2291",
                        "filePath": "properties/globals/font/base.json",
                        "isSource": true,
                        "original": {
                            "value": "{font.brand.line-height.heading.mobile.three.value}"
                        },
                        "name": "tds-font-line-height-heading-mobile-three",
                        "attributes": {
                            "category": "font",
                            "type": "line-height",
                            "item": "heading",
                            "subitem": "mobile",
                            "state": "three"
                        },
                        "path": [
                            "font",
                            "line-height",
                            "heading",
                            "mobile",
                            "three"
                        ]
                    },
                    "four": {
                        "value": "1.1852",
                        "filePath": "properties/globals/font/base.json",
                        "isSource": true,
                        "original": {
                            "value": "{font.brand.line-height.heading.mobile.four.value}"
                        },
                        "name": "tds-font-line-height-heading-mobile-four",
                        "attributes": {
                            "category": "font",
                            "type": "line-height",
                            "item": "heading",
                            "subitem": "mobile",
                            "state": "four"
                        },
                        "path": [
                            "font",
                            "line-height",
                            "heading",
                            "mobile",
                            "four"
                        ]
                    },
                    "five": {
                        "value": "1.3333",
                        "filePath": "properties/globals/font/base.json",
                        "isSource": true,
                        "original": {
                            "value": "{font.brand.line-height.heading.mobile.five.value}"
                        },
                        "name": "tds-font-line-height-heading-mobile-five",
                        "attributes": {
                            "category": "font",
                            "type": "line-height",
                            "item": "heading",
                            "subitem": "mobile",
                            "state": "five"
                        },
                        "path": [
                            "font",
                            "line-height",
                            "heading",
                            "mobile",
                            "five"
                        ]
                    },
                    "six": {
                        "value": "1.25",
                        "filePath": "properties/globals/font/base.json",
                        "isSource": true,
                        "original": {
                            "value": "{font.brand.line-height.heading.mobile.six.value}"
                        },
                        "name": "tds-font-line-height-heading-mobile-six",
                        "attributes": {
                            "category": "font",
                            "type": "line-height",
                            "item": "heading",
                            "subitem": "mobile",
                            "state": "six"
                        },
                        "path": [
                            "font",
                            "line-height",
                            "heading",
                            "mobile",
                            "six"
                        ]
                    }
                }
            },
            "paragraph": {
                "default": {
                    "value": "1.25",
                    "filePath": "properties/globals/font/base.json",
                    "isSource": true,
                    "original": {
                        "value": "{font.brand.line-height.paragraph.value}"
                    },
                    "name": "tds-font-line-height-paragraph-default",
                    "attributes": {
                        "category": "font",
                        "type": "line-height",
                        "item": "paragraph",
                        "subitem": "default"
                    },
                    "path": [
                        "font",
                        "line-height",
                        "paragraph",
                        "default"
                    ]
                },
                "large": {
                    "value": "1.3333",
                    "filePath": "properties/globals/font/base.json",
                    "isSource": true,
                    "original": {
                        "value": "{font.brand.line-height.paragraph.large.value}"
                    },
                    "name": "tds-font-line-height-paragraph-large",
                    "attributes": {
                        "category": "font",
                        "type": "line-height",
                        "item": "paragraph",
                        "subitem": "large"
                    },
                    "path": [
                        "font",
                        "line-height",
                        "paragraph",
                        "large"
                    ]
                },
                "small": {
                    "value": "1.1252",
                    "filePath": "properties/globals/font/base.json",
                    "isSource": true,
                    "original": {
                        "value": "{font.brand.line-height.paragraph.small.value}"
                    },
                    "name": "tds-font-line-height-paragraph-small",
                    "attributes": {
                        "category": "font",
                        "type": "line-height",
                        "item": "paragraph",
                        "subitem": "small"
                    },
                    "path": [
                        "font",
                        "line-height",
                        "paragraph",
                        "small"
                    ]
                },
                "mobile": {
                    "value": "1.4065",
                    "filePath": "properties/globals/font/base.json",
                    "isSource": true,
                    "original": {
                        "value": "{font.brand.line-height.paragraph.mobile.value}"
                    },
                    "name": "tds-font-line-height-paragraph-mobile",
                    "attributes": {
                        "category": "font",
                        "type": "line-height",
                        "item": "paragraph",
                        "subitem": "mobile"
                    },
                    "path": [
                        "font",
                        "line-height",
                        "paragraph",
                        "mobile"
                    ]
                }
            },
            "footnote": {
                "value": "1.2658",
                "filePath": "properties/globals/font/base.json",
                "isSource": true,
                "original": {
                    "value": "{font.brand.line-height.footnote.value}"
                },
                "name": "tds-font-line-height-footnote",
                "attributes": {
                    "category": "font",
                    "type": "line-height",
                    "item": "footnote"
                },
                "path": [
                    "font",
                    "line-height",
                    "footnote"
                ]
            },
            "link": {
                "value": "1.4",
                "filePath": "properties/globals/font/base.json",
                "isSource": true,
                "original": {
                    "value": "{font.brand.line-height.link.value}"
                },
                "name": "tds-font-line-height-link",
                "attributes": {
                    "category": "font",
                    "type": "line-height",
                    "item": "link"
                },
                "path": [
                    "font",
                    "line-height",
                    "link"
                ]
            }
        },
        "bottom-margin": {
            "heading": {
                "one": {
                    "value": "1.5rem",
                    "filePath": "properties/globals/font/base.json",
                    "isSource": true,
                    "original": {
                        "value": "{font.brand.bottom-margin.heading.one.value}"
                    },
                    "name": "tds-font-bottom-margin-heading-one",
                    "attributes": {
                        "category": "font",
                        "type": "bottom-margin",
                        "item": "heading",
                        "subitem": "one"
                    },
                    "path": [
                        "font",
                        "bottom-margin",
                        "heading",
                        "one"
                    ]
                },
                "two": {
                    "value": "1.25rem",
                    "filePath": "properties/globals/font/base.json",
                    "isSource": true,
                    "original": {
                        "value": "{font.brand.bottom-margin.heading.two.value}"
                    },
                    "name": "tds-font-bottom-margin-heading-two",
                    "attributes": {
                        "category": "font",
                        "type": "bottom-margin",
                        "item": "heading",
                        "subitem": "two"
                    },
                    "path": [
                        "font",
                        "bottom-margin",
                        "heading",
                        "two"
                    ]
                },
                "three": {
                    "value": "1rem",
                    "filePath": "properties/globals/font/base.json",
                    "isSource": true,
                    "original": {
                        "value": "{font.brand.bottom-margin.heading.three.value}"
                    },
                    "name": "tds-font-bottom-margin-heading-three",
                    "attributes": {
                        "category": "font",
                        "type": "bottom-margin",
                        "item": "heading",
                        "subitem": "three"
                    },
                    "path": [
                        "font",
                        "bottom-margin",
                        "heading",
                        "three"
                    ]
                },
                "four": {
                    "value": "1rem",
                    "filePath": "properties/globals/font/base.json",
                    "isSource": true,
                    "original": {
                        "value": "{font.brand.bottom-margin.heading.four.value}"
                    },
                    "name": "tds-font-bottom-margin-heading-four",
                    "attributes": {
                        "category": "font",
                        "type": "bottom-margin",
                        "item": "heading",
                        "subitem": "four"
                    },
                    "path": [
                        "font",
                        "bottom-margin",
                        "heading",
                        "four"
                    ]
                },
                "five": {
                    "value": "1rem",
                    "filePath": "properties/globals/font/base.json",
                    "isSource": true,
                    "original": {
                        "value": "{font.brand.bottom-margin.heading.five.value}"
                    },
                    "name": "tds-font-bottom-margin-heading-five",
                    "attributes": {
                        "category": "font",
                        "type": "bottom-margin",
                        "item": "heading",
                        "subitem": "five"
                    },
                    "path": [
                        "font",
                        "bottom-margin",
                        "heading",
                        "five"
                    ]
                },
                "six": {
                    "value": "1rem",
                    "filePath": "properties/globals/font/base.json",
                    "isSource": true,
                    "original": {
                        "value": "{font.brand.bottom-margin.heading.six.value}"
                    },
                    "name": "tds-font-bottom-margin-heading-six",
                    "attributes": {
                        "category": "font",
                        "type": "bottom-margin",
                        "item": "heading",
                        "subitem": "six"
                    },
                    "path": [
                        "font",
                        "bottom-margin",
                        "heading",
                        "six"
                    ]
                },
                "mobile": {
                    "one": {
                        "value": "1rem",
                        "filePath": "properties/globals/font/base.json",
                        "isSource": true,
                        "original": {
                            "value": "{font.brand.bottom-margin.heading.mobile.one.value}"
                        },
                        "name": "tds-font-bottom-margin-heading-mobile-one",
                        "attributes": {
                            "category": "font",
                            "type": "bottom-margin",
                            "item": "heading",
                            "subitem": "mobile",
                            "state": "one"
                        },
                        "path": [
                            "font",
                            "bottom-margin",
                            "heading",
                            "mobile",
                            "one"
                        ]
                    },
                    "two": {
                        "value": "1rem",
                        "filePath": "properties/globals/font/base.json",
                        "isSource": true,
                        "original": {
                            "value": "{font.brand.bottom-margin.heading.mobile.two.value}"
                        },
                        "name": "tds-font-bottom-margin-heading-mobile-two",
                        "attributes": {
                            "category": "font",
                            "type": "bottom-margin",
                            "item": "heading",
                            "subitem": "mobile",
                            "state": "two"
                        },
                        "path": [
                            "font",
                            "bottom-margin",
                            "heading",
                            "mobile",
                            "two"
                        ]
                    },
                    "three": {
                        "value": "1rem",
                        "filePath": "properties/globals/font/base.json",
                        "isSource": true,
                        "original": {
                            "value": "{font.brand.bottom-margin.heading.mobile.three.value}"
                        },
                        "name": "tds-font-bottom-margin-heading-mobile-three",
                        "attributes": {
                            "category": "font",
                            "type": "bottom-margin",
                            "item": "heading",
                            "subitem": "mobile",
                            "state": "three"
                        },
                        "path": [
                            "font",
                            "bottom-margin",
                            "heading",
                            "mobile",
                            "three"
                        ]
                    },
                    "four": {
                        "value": "1rem",
                        "filePath": "properties/globals/font/base.json",
                        "isSource": true,
                        "original": {
                            "value": "{font.brand.bottom-margin.heading.mobile.four.value}"
                        },
                        "name": "tds-font-bottom-margin-heading-mobile-four",
                        "attributes": {
                            "category": "font",
                            "type": "bottom-margin",
                            "item": "heading",
                            "subitem": "mobile",
                            "state": "four"
                        },
                        "path": [
                            "font",
                            "bottom-margin",
                            "heading",
                            "mobile",
                            "four"
                        ]
                    },
                    "five": {
                        "value": ".75rem",
                        "filePath": "properties/globals/font/base.json",
                        "isSource": true,
                        "original": {
                            "value": "{font.brand.bottom-margin.heading.mobile.five.value}"
                        },
                        "name": "tds-font-bottom-margin-heading-mobile-five",
                        "attributes": {
                            "category": "font",
                            "type": "bottom-margin",
                            "item": "heading",
                            "subitem": "mobile",
                            "state": "five"
                        },
                        "path": [
                            "font",
                            "bottom-margin",
                            "heading",
                            "mobile",
                            "five"
                        ]
                    },
                    "six": {
                        "value": ".75rem",
                        "filePath": "properties/globals/font/base.json",
                        "isSource": true,
                        "original": {
                            "value": "{font.brand.bottom-margin.heading.mobile.six.value}"
                        },
                        "name": "tds-font-bottom-margin-heading-mobile-six",
                        "attributes": {
                            "category": "font",
                            "type": "bottom-margin",
                            "item": "heading",
                            "subitem": "mobile",
                            "state": "six"
                        },
                        "path": [
                            "font",
                            "bottom-margin",
                            "heading",
                            "mobile",
                            "six"
                        ]
                    }
                }
            },
            "paragraph": {
                "value": ".75rem",
                "filePath": "properties/globals/font/base.json",
                "isSource": true,
                "original": {
                    "value": "{font.brand.bottom-margin.paragraph.value}"
                },
                "name": "tds-font-bottom-margin-paragraph",
                "attributes": {
                    "category": "font",
                    "type": "bottom-margin",
                    "item": "paragraph"
                },
                "path": [
                    "font",
                    "bottom-margin",
                    "paragraph"
                ]
            },
            "footnote": {
                "value": ".75rem",
                "filePath": "properties/globals/font/base.json",
                "isSource": true,
                "original": {
                    "value": "{font.brand.bottom-margin.footnote.value}"
                },
                "name": "tds-font-bottom-margin-footnote",
                "attributes": {
                    "category": "font",
                    "type": "bottom-margin",
                    "item": "footnote"
                },
                "path": [
                    "font",
                    "bottom-margin",
                    "footnote"
                ]
            },
            "link": {
                "value": ".75rem",
                "filePath": "properties/globals/font/base.json",
                "isSource": true,
                "original": {
                    "value": "{font.brand.bottom-margin.link.value}"
                },
                "name": "tds-font-bottom-margin-link",
                "attributes": {
                    "category": "font",
                    "type": "bottom-margin",
                    "item": "link"
                },
                "path": [
                    "font",
                    "bottom-margin",
                    "link"
                ]
            },
            "label": {
                "value": ".75rem",
                "filePath": "properties/globals/font/base.json",
                "isSource": true,
                "original": {
                    "value": "{font.brand.bottom-margin.label.value}"
                },
                "name": "tds-font-bottom-margin-label",
                "attributes": {
                    "category": "font",
                    "type": "bottom-margin",
                    "item": "label"
                },
                "path": [
                    "font",
                    "bottom-margin",
                    "label"
                ]
            }
        },
        "color": {
            "heading": {
                "dark": {
                    "value": "#002677",
                    "filePath": "properties/globals/font/base.json",
                    "isSource": true,
                    "original": {
                        "value": "{color.brand.primary.one.value}"
                    },
                    "name": "tds-font-color-heading-dark",
                    "attributes": {
                        "category": "font",
                        "type": "color",
                        "item": "heading",
                        "subitem": "dark"
                    },
                    "path": [
                        "font",
                        "color",
                        "heading",
                        "dark"
                    ]
                },
                "light": {
                    "value": "#ffffff",
                    "filePath": "properties/globals/font/base.json",
                    "isSource": true,
                    "original": {
                        "value": "{color.brand.primary.three.value}"
                    },
                    "name": "tds-font-color-heading-light",
                    "attributes": {
                        "category": "font",
                        "type": "color",
                        "item": "heading",
                        "subitem": "light"
                    },
                    "path": [
                        "font",
                        "color",
                        "heading",
                        "light"
                    ]
                }
            },
            "content": {
                "dark": {
                    "value": "#5a5a5a",
                    "filePath": "properties/globals/font/base.json",
                    "isSource": true,
                    "original": {
                        "value": "{color.brand.gray.six.value}"
                    },
                    "name": "tds-font-color-content-dark",
                    "attributes": {
                        "category": "font",
                        "type": "color",
                        "item": "content",
                        "subitem": "dark"
                    },
                    "path": [
                        "font",
                        "color",
                        "content",
                        "dark"
                    ]
                },
                "light": {
                    "value": "#ffffff",
                    "filePath": "properties/globals/font/base.json",
                    "isSource": true,
                    "original": {
                        "value": "{color.brand.primary.three.value}"
                    },
                    "name": "tds-font-color-content-light",
                    "attributes": {
                        "category": "font",
                        "type": "color",
                        "item": "content",
                        "subitem": "light"
                    },
                    "path": [
                        "font",
                        "color",
                        "content",
                        "light"
                    ]
                }
            },
            "link": {
                "value": "#196ecf",
                "filePath": "properties/globals/font/base.json",
                "isSource": true,
                "original": {
                    "value": "{color.brand.primary.two.value}"
                },
                "name": "tds-font-color-link",
                "attributes": {
                    "category": "font",
                    "type": "color",
                    "item": "link"
                },
                "path": [
                    "font",
                    "color",
                    "link"
                ]
            }
        }
    },
    "color": {
        "primary": {
            "one": {
                "value": "#002677",
                "filePath": "properties/globals/color/base.json",
                "isSource": true,
                "original": {
                    "value": "{color.brand.primary.one.value}"
                },
                "name": "tds-color-primary-one",
                "attributes": {
                    "category": "color",
                    "type": "primary",
                    "item": "one"
                },
                "path": [
                    "color",
                    "primary",
                    "one"
                ]
            },
            "two": {
                "value": "#196ecf",
                "filePath": "properties/globals/color/base.json",
                "isSource": true,
                "original": {
                    "value": "{color.brand.primary.two.value}"
                },
                "name": "tds-color-primary-two",
                "attributes": {
                    "category": "color",
                    "type": "primary",
                    "item": "two"
                },
                "path": [
                    "color",
                    "primary",
                    "two"
                ]
            },
            "three": {
                "value": "#ffffff",
                "filePath": "properties/globals/color/base.json",
                "isSource": true,
                "original": {
                    "value": "{color.brand.primary.three.value}"
                },
                "name": "tds-color-primary-three",
                "attributes": {
                    "category": "color",
                    "type": "primary",
                    "item": "three"
                },
                "path": [
                    "color",
                    "primary",
                    "three"
                ]
            }
        },
        "supporting": {
            "one": {
                "value": "#00bed5",
                "filePath": "properties/globals/color/base.json",
                "isSource": true,
                "original": {
                    "value": "{color.brand.supporting.one.value}"
                },
                "name": "tds-color-supporting-one",
                "attributes": {
                    "category": "color",
                    "type": "supporting",
                    "item": "one"
                },
                "path": [
                    "color",
                    "supporting",
                    "one"
                ]
            },
            "two": {
                "value": "#f5b700",
                "filePath": "properties/globals/color/base.json",
                "isSource": true,
                "original": {
                    "value": "{color.brand.supporting.two.value}"
                },
                "name": "tds-color-supporting-two",
                "attributes": {
                    "category": "color",
                    "type": "supporting",
                    "item": "two"
                },
                "path": [
                    "color",
                    "supporting",
                    "two"
                ]
            },
            "three": {
                "value": "#ff681f",
                "filePath": "properties/globals/color/base.json",
                "isSource": true,
                "original": {
                    "value": "{color.brand.supporting.three.value}"
                },
                "name": "tds-color-supporting-three",
                "attributes": {
                    "category": "color",
                    "type": "supporting",
                    "item": "three"
                },
                "path": [
                    "color",
                    "supporting",
                    "three"
                ]
            },
            "four": {
                "value": "#d14600",
                "filePath": "properties/globals/color/base.json",
                "isSource": true,
                "original": {
                    "value": "{color.brand.supporting.four.value}"
                },
                "name": "tds-color-supporting-four",
                "attributes": {
                    "category": "color",
                    "type": "supporting",
                    "item": "four"
                },
                "path": [
                    "color",
                    "supporting",
                    "four"
                ]
            },
            "five": {
                "value": "#aa3600",
                "filePath": "properties/globals/color/base.json",
                "isSource": true,
                "original": {
                    "value": "{color.brand.supporting.five.value}"
                },
                "name": "tds-color-supporting-five",
                "attributes": {
                    "category": "color",
                    "type": "supporting",
                    "item": "five"
                },
                "path": [
                    "color",
                    "supporting",
                    "five"
                ]
            }
        },
        "tints": {
            "primary": {
                "one": {
                    "extra-light": {
                        "value": "#fafcff",
                        "filePath": "properties/globals/color/base.json",
                        "isSource": true,
                        "original": {
                            "value": "{color.brand.tints.primary.one.extra-light.value}"
                        },
                        "name": "tds-color-tints-primary-one-extra-light",
                        "attributes": {
                            "category": "color",
                            "type": "tints",
                            "item": "primary",
                            "subitem": "one",
                            "state": "extra-light"
                        },
                        "path": [
                            "color",
                            "tints",
                            "primary",
                            "one",
                            "extra-light"
                        ]
                    }
                }
            },
            "supporting": {
                "one": {
                    "opacity": {
                        "ten": {
                            "value": "#e5f8fb",
                            "filePath": "properties/globals/color/base.json",
                            "isSource": true,
                            "original": {
                                "value": "{color.brand.tints.supporting.one.opacity.ten.value}"
                            },
                            "name": "tds-color-tints-supporting-one-opacity-ten",
                            "attributes": {
                                "category": "color",
                                "type": "tints",
                                "item": "supporting",
                                "subitem": "one",
                                "state": "opacity"
                            },
                            "path": [
                                "color",
                                "tints",
                                "supporting",
                                "one",
                                "opacity",
                                "ten"
                            ]
                        },
                        "twenty": {
                            "value": "#ccf2f7",
                            "filePath": "properties/globals/color/base.json",
                            "isSource": true,
                            "original": {
                                "value": "{color.brand.tints.supporting.one.opacity.twenty.value}"
                            },
                            "name": "tds-color-tints-supporting-one-opacity-twenty",
                            "attributes": {
                                "category": "color",
                                "type": "tints",
                                "item": "supporting",
                                "subitem": "one",
                                "state": "opacity"
                            },
                            "path": [
                                "color",
                                "tints",
                                "supporting",
                                "one",
                                "opacity",
                                "twenty"
                            ]
                        },
                        "forty": {
                            "value": "#99e5ee",
                            "filePath": "properties/globals/color/base.json",
                            "isSource": true,
                            "original": {
                                "value": "{color.brand.tints.supporting.one.opacity.forty.value}"
                            },
                            "name": "tds-color-tints-supporting-one-opacity-forty",
                            "attributes": {
                                "category": "color",
                                "type": "tints",
                                "item": "supporting",
                                "subitem": "one",
                                "state": "opacity"
                            },
                            "path": [
                                "color",
                                "tints",
                                "supporting",
                                "one",
                                "opacity",
                                "forty"
                            ]
                        }
                    }
                }
            }
        },
        "gray": {
            "one": {
                "value": "#fbfbfb",
                "filePath": "properties/globals/color/base.json",
                "isSource": true,
                "original": {
                    "value": "{color.brand.gray.one.value}"
                },
                "name": "tds-color-gray-one",
                "attributes": {
                    "category": "color",
                    "type": "gray",
                    "item": "one"
                },
                "path": [
                    "color",
                    "gray",
                    "one"
                ]
            },
            "two": {
                "value": "#f4f4f4",
                "filePath": "properties/globals/color/base.json",
                "isSource": true,
                "original": {
                    "value": "{color.brand.gray.two.value}"
                },
                "name": "tds-color-gray-two",
                "attributes": {
                    "category": "color",
                    "type": "gray",
                    "item": "two"
                },
                "path": [
                    "color",
                    "gray",
                    "two"
                ]
            },
            "three": {
                "value": "#e9e9e9",
                "filePath": "properties/globals/color/base.json",
                "isSource": true,
                "original": {
                    "value": "{color.brand.gray.three.value}"
                },
                "name": "tds-color-gray-three",
                "attributes": {
                    "category": "color",
                    "type": "gray",
                    "item": "three"
                },
                "path": [
                    "color",
                    "gray",
                    "three"
                ]
            },
            "four": {
                "value": "#dadbdc",
                "filePath": "properties/globals/color/base.json",
                "isSource": true,
                "original": {
                    "value": "{color.brand.gray.four.value}"
                },
                "name": "tds-color-gray-four",
                "attributes": {
                    "category": "color",
                    "type": "gray",
                    "item": "four"
                },
                "path": [
                    "color",
                    "gray",
                    "four"
                ]
            },
            "five": {
                "value": "#6f6f6f",
                "filePath": "properties/globals/color/base.json",
                "isSource": true,
                "original": {
                    "value": "{color.brand.gray.five.value}"
                },
                "name": "tds-color-gray-five",
                "attributes": {
                    "category": "color",
                    "type": "gray",
                    "item": "five"
                },
                "path": [
                    "color",
                    "gray",
                    "five"
                ]
            },
            "six": {
                "value": "#5a5a5a",
                "filePath": "properties/globals/color/base.json",
                "isSource": true,
                "original": {
                    "value": "{color.brand.gray.six.value}"
                },
                "name": "tds-color-gray-six",
                "attributes": {
                    "category": "color",
                    "type": "gray",
                    "item": "six"
                },
                "path": [
                    "color",
                    "gray",
                    "six"
                ]
            },
            "seven": {
                "value": "#333333",
                "filePath": "properties/globals/color/base.json",
                "isSource": true,
                "original": {
                    "value": "{color.brand.gray.seven.value}"
                },
                "name": "tds-color-gray-seven",
                "attributes": {
                    "category": "color",
                    "type": "gray",
                    "item": "seven"
                },
                "path": [
                    "color",
                    "gray",
                    "seven"
                ]
            }
        },
        "interaction": {
            "success": {
                "value": "#007000",
                "filePath": "properties/globals/color/base.json",
                "isSource": true,
                "original": {
                    "value": "{color.brand.interaction.success.value}"
                },
                "name": "tds-color-interaction-success",
                "attributes": {
                    "category": "color",
                    "type": "interaction",
                    "item": "success"
                },
                "path": [
                    "color",
                    "interaction",
                    "success"
                ]
            },
            "alert": {
                "value": "#c40000",
                "filePath": "properties/globals/color/base.json",
                "isSource": true,
                "original": {
                    "value": "{color.brand.interaction.alert.value}"
                },
                "name": "tds-color-interaction-alert",
                "attributes": {
                    "category": "color",
                    "type": "interaction",
                    "item": "alert"
                },
                "path": [
                    "color",
                    "interaction",
                    "alert"
                ]
            }
        },
        "border": {
            "default": {
                "value": "#dadbdc",
                "filePath": "properties/platforms/web/secondary.json",
                "isSource": true,
                "original": {
                    "value": "{color.gray.four.value}"
                },
                "name": "tds-color-border-default",
                "attributes": {
                    "category": "color",
                    "type": "border",
                    "item": "default"
                },
                "path": [
                    "color",
                    "border",
                    "default"
                ]
            },
            "disabled": {
                "value": "#6f6f6f",
                "filePath": "properties/platforms/web/secondary.json",
                "isSource": true,
                "original": {
                    "value": "{color.gray.five.value}"
                },
                "name": "tds-color-border-disabled",
                "attributes": {
                    "category": "color",
                    "type": "border",
                    "item": "disabled"
                },
                "path": [
                    "color",
                    "border",
                    "disabled"
                ]
            }
        },
        "disabled": {
            "value": "#6f6f6f",
            "filePath": "properties/platforms/web/secondary.json",
            "isSource": true,
            "original": {
                "value": "{color.gray.five.value}"
            },
            "name": "tds-color-disabled",
            "attributes": {
                "category": "color",
                "type": "disabled"
            },
            "path": [
                "color",
                "disabled"
            ]
        },
        "fill": {
            "utility-icon": {
                "primary": {
                    "value": "#002677",
                    "filePath": "properties/platforms/web/secondary.json",
                    "isSource": true,
                    "original": {
                        "value": "{color.primary.one.value}"
                    },
                    "name": "tds-color-fill-utility-icon-primary",
                    "attributes": {
                        "category": "color",
                        "type": "fill",
                        "item": "utility-icon",
                        "subitem": "primary"
                    },
                    "path": [
                        "color",
                        "fill",
                        "utility-icon",
                        "primary"
                    ]
                },
                "primaryCTA": {
                    "value": "#196ecf",
                    "filePath": "properties/platforms/web/secondary.json",
                    "isSource": true,
                    "original": {
                        "value": "{color.primary.two.value}"
                    },
                    "name": "tds-color-fill-utility-icon-primary-cta",
                    "attributes": {
                        "category": "color",
                        "type": "fill",
                        "item": "utility-icon",
                        "subitem": "primaryCTA"
                    },
                    "path": [
                        "color",
                        "fill",
                        "utility-icon",
                        "primaryCTA"
                    ]
                },
                "primaryLight": {
                    "value": "#ffffff",
                    "filePath": "properties/platforms/web/secondary.json",
                    "isSource": true,
                    "original": {
                        "value": "{color.primary.three.value}"
                    },
                    "name": "tds-color-fill-utility-icon-primary-light",
                    "attributes": {
                        "category": "color",
                        "type": "fill",
                        "item": "utility-icon",
                        "subitem": "primaryLight"
                    },
                    "path": [
                        "color",
                        "fill",
                        "utility-icon",
                        "primaryLight"
                    ]
                },
                "gray": {
                    "value": "#333333",
                    "filePath": "properties/platforms/web/secondary.json",
                    "isSource": true,
                    "original": {
                        "value": "{color.gray.seven.value}"
                    },
                    "name": "tds-color-fill-utility-icon-gray",
                    "attributes": {
                        "category": "color",
                        "type": "fill",
                        "item": "utility-icon",
                        "subitem": "gray"
                    },
                    "path": [
                        "color",
                        "fill",
                        "utility-icon",
                        "gray"
                    ]
                },
                "disabled": {
                    "value": "#6f6f6f",
                    "filePath": "properties/platforms/web/secondary.json",
                    "isSource": true,
                    "original": {
                        "value": "{color.gray.five.value}"
                    },
                    "name": "tds-color-fill-utility-icon-disabled",
                    "attributes": {
                        "category": "color",
                        "type": "fill",
                        "item": "utility-icon",
                        "subitem": "disabled"
                    },
                    "path": [
                        "color",
                        "fill",
                        "utility-icon",
                        "disabled"
                    ]
                },
                "success": {
                    "value": "#007000",
                    "filePath": "properties/platforms/web/secondary.json",
                    "isSource": true,
                    "original": {
                        "value": "{color.interaction.success.value}"
                    },
                    "name": "tds-color-fill-utility-icon-success",
                    "attributes": {
                        "category": "color",
                        "type": "fill",
                        "item": "utility-icon",
                        "subitem": "success"
                    },
                    "path": [
                        "color",
                        "fill",
                        "utility-icon",
                        "success"
                    ]
                },
                "alert": {
                    "value": "#c40000",
                    "filePath": "properties/platforms/web/secondary.json",
                    "isSource": true,
                    "original": {
                        "value": "{color.interaction.alert.value}"
                    },
                    "name": "tds-color-fill-utility-icon-alert",
                    "attributes": {
                        "category": "color",
                        "type": "fill",
                        "item": "utility-icon",
                        "subitem": "alert"
                    },
                    "path": [
                        "color",
                        "fill",
                        "utility-icon",
                        "alert"
                    ]
                }
            }
        },
        "focus": {
            "box-shadow-inner": {
                "value": "#ffffff",
                "filePath": "properties/platforms/web/secondary.json",
                "isSource": true,
                "original": {
                    "value": "{color.primary.three.value}"
                },
                "name": "tds-color-focus-box-shadow-inner",
                "attributes": {
                    "category": "color",
                    "type": "focus",
                    "item": "box-shadow-inner"
                },
                "path": [
                    "color",
                    "focus",
                    "box-shadow-inner"
                ]
            },
            "box-shadow-outer": {
                "value": "#196ecf",
                "filePath": "properties/platforms/web/secondary.json",
                "isSource": true,
                "original": {
                    "value": "{color.primary.two.value}"
                },
                "name": "tds-color-focus-box-shadow-outer",
                "attributes": {
                    "category": "color",
                    "type": "focus",
                    "item": "box-shadow-outer"
                },
                "path": [
                    "color",
                    "focus",
                    "box-shadow-outer"
                ]
            }
        }
    },
    "depth": {
        "box-shadow": {
            "value": "0 5px 5px -5px #333333",
            "filePath": "properties/globals/depth/base.json",
            "isSource": true,
            "original": {
                "value": "{depth.brand.box-shadow.value}"
            },
            "name": "tds-depth-box-shadow",
            "attributes": {
                "category": "depth",
                "type": "box-shadow"
            },
            "path": [
                "depth",
                "box-shadow"
            ]
        },
        "z-index": {
            "bottom": {
                "value": "-100",
                "filePath": "properties/globals/depth/base.json",
                "isSource": true,
                "original": {
                    "value": "{depth.brand.z-index.bottom.value}"
                },
                "name": "tds-depth-z-index-bottom",
                "attributes": {
                    "category": "depth",
                    "type": "z-index",
                    "item": "bottom"
                },
                "path": [
                    "depth",
                    "z-index",
                    "bottom"
                ]
            },
            "below": {
                "value": "-1",
                "filePath": "properties/globals/depth/base.json",
                "isSource": true,
                "original": {
                    "value": "{depth.brand.z-index.below.value}"
                },
                "name": "tds-depth-z-index-below",
                "attributes": {
                    "category": "depth",
                    "type": "z-index",
                    "item": "below"
                },
                "path": [
                    "depth",
                    "z-index",
                    "below"
                ]
            },
            "default": {
                "value": "1",
                "filePath": "properties/globals/depth/base.json",
                "isSource": true,
                "original": {
                    "value": "{depth.brand.z-index.default.value}"
                },
                "name": "tds-depth-z-index-default",
                "attributes": {
                    "category": "depth",
                    "type": "z-index",
                    "item": "default"
                },
                "path": [
                    "depth",
                    "z-index",
                    "default"
                ]
            },
            "sticky": {
                "value": "100",
                "filePath": "properties/globals/depth/base.json",
                "isSource": true,
                "original": {
                    "value": "{depth.brand.z-index.sticky.value}"
                },
                "name": "tds-depth-z-index-sticky",
                "attributes": {
                    "category": "depth",
                    "type": "z-index",
                    "item": "sticky"
                },
                "path": [
                    "depth",
                    "z-index",
                    "sticky"
                ]
            },
            "overlay": {
                "value": "800",
                "filePath": "properties/globals/depth/base.json",
                "isSource": true,
                "original": {
                    "value": "{depth.brand.z-index.overlay.value}"
                },
                "name": "tds-depth-z-index-overlay",
                "attributes": {
                    "category": "depth",
                    "type": "z-index",
                    "item": "overlay"
                },
                "path": [
                    "depth",
                    "z-index",
                    "overlay"
                ]
            }
        }
    },
    "radius": {
        "border": {
            "value": "24px",
            "filePath": "properties/globals/radius/base.json",
            "isSource": true,
            "original": {
                "value": "{radius.brand.border.value}"
            },
            "name": "tds-radius-border",
            "attributes": {
                "category": "radius",
                "type": "border"
            },
            "path": [
                "radius",
                "border"
            ]
        }
    },
    "spacing": {
        "xsm": {
            "value": "4px",
            "filePath": "properties/globals/spacing/base.json",
            "isSource": true,
            "original": {
                "value": "{spacing.brand.xsm.value}"
            },
            "name": "tds-spacing-xsm",
            "attributes": {
                "category": "spacing",
                "type": "xsm"
            },
            "path": [
                "spacing",
                "xsm"
            ]
        },
        "sm": {
            "value": "8px",
            "filePath": "properties/globals/spacing/base.json",
            "isSource": true,
            "original": {
                "value": "{spacing.brand.sm.value}"
            },
            "name": "tds-spacing-sm",
            "attributes": {
                "category": "spacing",
                "type": "sm"
            },
            "path": [
                "spacing",
                "sm"
            ]
        },
        "md": {
            "value": "12px",
            "filePath": "properties/globals/spacing/base.json",
            "isSource": true,
            "original": {
                "value": "{spacing.brand.md.value}"
            },
            "name": "tds-spacing-md",
            "attributes": {
                "category": "spacing",
                "type": "md"
            },
            "path": [
                "spacing",
                "md"
            ]
        },
        "lg": {
            "value": "16px",
            "filePath": "properties/globals/spacing/base.json",
            "isSource": true,
            "original": {
                "value": "{spacing.brand.lg.value}"
            },
            "name": "tds-spacing-lg",
            "attributes": {
                "category": "spacing",
                "type": "lg"
            },
            "path": [
                "spacing",
                "lg"
            ]
        },
        "xlg": {
            "value": "20px",
            "filePath": "properties/globals/spacing/base.json",
            "isSource": true,
            "original": {
                "value": "{spacing.brand.xlg.value}"
            },
            "name": "tds-spacing-xlg",
            "attributes": {
                "category": "spacing",
                "type": "xlg"
            },
            "path": [
                "spacing",
                "xlg"
            ]
        },
        "xxlg": {
            "value": "24px",
            "filePath": "properties/globals/spacing/base.json",
            "isSource": true,
            "original": {
                "value": "{spacing.brand.xxlg.value}"
            },
            "name": "tds-spacing-xxlg",
            "attributes": {
                "category": "spacing",
                "type": "xxlg"
            },
            "path": [
                "spacing",
                "xxlg"
            ]
        },
        "gutter": {
            "value": "16px",
            "filePath": "properties/globals/spacing/base.json",
            "isSource": true,
            "original": {
                "value": "{spacing.brand.lg.value}"
            },
            "name": "tds-spacing-gutter",
            "attributes": {
                "category": "spacing",
                "type": "gutter"
            },
            "path": [
                "spacing",
                "gutter"
            ]
        }
    },
    "size": {
        "border": {
            "value": "1px",
            "filePath": "properties/platforms/web/secondary.json",
            "isSource": true,
            "original": {
                "value": "1px"
            },
            "name": "tds-size-border",
            "attributes": {
                "category": "size",
                "type": "border"
            },
            "path": [
                "size",
                "border"
            ]
        },
        "utility-icon": {
            "sm": {
                "value": "1rem",
                "filePath": "properties/platforms/web/secondary.json",
                "isSource": true,
                "original": {
                    "value": "{font.size.base.font.value}"
                },
                "name": "tds-size-utility-icon-sm",
                "attributes": {
                    "category": "size",
                    "type": "utility-icon",
                    "item": "sm"
                },
                "path": [
                    "size",
                    "utility-icon",
                    "sm"
                ]
            },
            "md": {
                "value": "1.5rem",
                "filePath": "properties/platforms/web/secondary.json",
                "isSource": true,
                "original": {
                    "value": "1.5rem"
                },
                "name": "tds-size-utility-icon-md",
                "attributes": {
                    "category": "size",
                    "type": "utility-icon",
                    "item": "md"
                },
                "path": [
                    "size",
                    "utility-icon",
                    "md"
                ]
            },
            "lg": {
                "value": "2.25rem",
                "filePath": "properties/platforms/web/secondary.json",
                "isSource": true,
                "original": {
                    "value": "2.25rem"
                },
                "name": "tds-size-utility-icon-lg",
                "attributes": {
                    "category": "size",
                    "type": "utility-icon",
                    "item": "lg"
                },
                "path": [
                    "size",
                    "utility-icon",
                    "lg"
                ]
            }
        },
        "icon": {
            "xsm": {
                "value": "48px",
                "filePath": "properties/platforms/web/secondary.json",
                "isSource": true,
                "original": {
                    "value": "48px"
                },
                "name": "tds-size-icon-xsm",
                "attributes": {
                    "category": "size",
                    "type": "icon",
                    "item": "xsm"
                },
                "path": [
                    "size",
                    "icon",
                    "xsm"
                ]
            },
            "sm": {
                "value": "60px",
                "filePath": "properties/platforms/web/secondary.json",
                "isSource": true,
                "original": {
                    "value": "60px"
                },
                "name": "tds-size-icon-sm",
                "attributes": {
                    "category": "size",
                    "type": "icon",
                    "item": "sm"
                },
                "path": [
                    "size",
                    "icon",
                    "sm"
                ]
            },
            "md": {
                "value": "72px",
                "filePath": "properties/platforms/web/secondary.json",
                "isSource": true,
                "original": {
                    "value": "72px"
                },
                "name": "tds-size-icon-md",
                "attributes": {
                    "category": "size",
                    "type": "icon",
                    "item": "md"
                },
                "path": [
                    "size",
                    "icon",
                    "md"
                ]
            },
            "lg": {
                "value": "84px",
                "filePath": "properties/platforms/web/secondary.json",
                "isSource": true,
                "original": {
                    "value": "84px"
                },
                "name": "tds-size-icon-lg",
                "attributes": {
                    "category": "size",
                    "type": "icon",
                    "item": "lg"
                },
                "path": [
                    "size",
                    "icon",
                    "lg"
                ]
            }
        },
        "outline": {
            "offset": {
                "sm": {
                    "value": "2px",
                    "filePath": "properties/platforms/web/secondary.json",
                    "isSource": true,
                    "original": {
                        "value": "2px"
                    },
                    "name": "tds-size-outline-offset-sm",
                    "attributes": {
                        "category": "size",
                        "type": "outline",
                        "item": "offset",
                        "subitem": "sm"
                    },
                    "path": [
                        "size",
                        "outline",
                        "offset",
                        "sm"
                    ]
                },
                "md": {
                    "value": "4px",
                    "filePath": "properties/platforms/web/secondary.json",
                    "isSource": true,
                    "original": {
                        "value": "{spacing.xsm.value}"
                    },
                    "name": "tds-size-outline-offset-md",
                    "attributes": {
                        "category": "size",
                        "type": "outline",
                        "item": "offset",
                        "subitem": "md"
                    },
                    "path": [
                        "size",
                        "outline",
                        "offset",
                        "md"
                    ]
                }
            }
        }
    }
};
export default tokens;