import * as React from "react";
import classNames from "classnames";
import { StepProps } from "./stepInterface";
import InnerStepCircle from "./InnerStepCircle";
import StepTitle from "./StepTitle";
import StepAssistiveText from "./StepAssistiveText";

export interface TwoSubStepProps extends StepProps {
  /**
   * Determines number of substeps completed
   */
  stepsComplete: 0 | 1 | 2;
}

/**
 * This is an Step
 * @param {TwoSubStep} props
 */
const TwoSubStep: React.FunctionComponent<TwoSubStepProps> = (props) => {
  const {
    className,
    stepNumber,
    active,
    stepsComplete,
    title,
    subtitle,
    ...other
  } = props;
  const maxSubSteps = 2;

  const createSubStepClasses = (currentStep: number): object => {
    return {
      "tds-step-tracker__sub-steps": true,
      "tds-step-tracker__sub-steps--active":
        active && stepsComplete === currentStep,
      "tds-step-tracker__sub-steps--complete":
        active && stepsComplete >= currentStep + 1,
    };
  };

  const subStepOneClasses = classNames(createSubStepClasses(0));
  const subStepPathOne =
    active && stepsComplete === 0
      ? "M40,19.9012515 C40,30.2716131 32.1071547,38.798502 22.0009781,39.8024039 L22.0007904,35.7773654 C29.8930309,34.7928183 36,28.0602144 36,19.9012515 C36,11.7422886 29.8930309,5.00968468 22.0007904,4.02513759 L22.0009781,9.91296065e-05 C32.1071547,1.00400103 40,9.53088987 40,19.9012515 Z"
      : "M40,20 C40,30.3703616 32.1071547,38.8972505 22.0009781,39.9011524 L22.0004182,37.8900962 C31.0001178,36.8950175 38,29.2649638 38,20 C38,10.7350362 31.0001178,3.10498251 22.0004182,2.10990379 L22.0009781,0.0988476235 C32.1071547,1.10274952 40,9.62963836 40,20 Z";

  const subStepTwoClasses = classNames(createSubStepClasses(1));
  const subStepPathTwo =
    active && stepsComplete === 1
      ? "M18.0000201,0 L18.0002073,4.02501317 C10.1074838,5.00911732 4,11.7419448 4,19.9012515 C4,28.0605582 10.1074838,34.7933857 18.0002073,35.7774898 L18.0000201,39.802503 C7.89336506,38.7990634 0,30.2719546 0,19.9012515 C0,9.53054844 7.89336506,1.00343957 18.0000201,0 Z"
      : "M18.0000201,0.0987484939 L18.0005846,2.10979295 C9.00040219,3.10441575 2,10.7346921 2,20 C2,29.2653079 9.00040219,36.8955843 18.0005846,37.8902071 L18.0000201,39.9012515 C7.89336506,38.8978119 0,30.3707031 0,20 C0,9.74714584 7.71498968,1.29622406 17.6563408,0.135862971 L18.0000201,0.0987484939 Z";

  return (
    <li className="tds-step-tracker__sub-step-container" {...other}>
      <svg
        width="40px"
        height="40px"
        viewBox="0 0 41 41"
        version="1.1"
        aria-hidden="true"
        focusable="false"
      >
        <title>blocks/Steps-2/default</title>
        <g
          id="Step-Tracker"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <g id="Step-tracker" transform="translate(-597.000000, -160.000000)">
            <g id="Group" transform="translate(597.000000, 160.000000)">
              <circle id="bg" fill="#FFFFFF" cx="20" cy="20" r="20"></circle>

              <path
                className={subStepOneClasses}
                d={subStepPathOne}
                id="Path"
                fill="#DBDBDB"
                fillRule="nonzero"
              ></path>
              <path
                className={subStepTwoClasses}
                d={subStepPathTwo}
                id="Path"
                fill="#DBDBDB"
                fillRule="nonzero"
              ></path>
              <g
                transform="translate(8.000000, 8.000000)"
                id=".blocks/step-number"
              >
                <InnerStepCircle
                  complete={maxSubSteps === stepsComplete}
                  displayNumber={stepNumber}
                />
              </g>
            </g>
          </g>
        </g>
      </svg>
      {(title || subtitle) && (
        <StepTitle title={title} subtitle={subtitle}></StepTitle>
      )}
      <StepAssistiveText
        stepsComplete={stepsComplete}
        maxSubSteps={maxSubSteps}
        active={active}
      />
    </li>
  );
};

TwoSubStep.defaultProps = {
  stepNumber: 1,
  stepsComplete: 0,
  active: false,
} as Partial<TwoSubStepProps>;

export default TwoSubStep;
