import * as React from "react";
import classNames from "classnames";
import UtilityIcon from "../icon/UtilityIcon";
import TwitterIcon from "@material-ui/icons/Twitter";
import FacebookIcon from "@material-ui/icons/Facebook";
import InstagramIcon from "@material-ui/icons/Instagram";
import YouTubeIcon from "@material-ui/icons/YouTube";
import LinkedInIcon from "@material-ui/icons/LinkedIn";

export interface FooterSocialProps {
  twitter?: string;
  linkedin?: string;
  facebook?: string;
  instagram?: string;
  youtube?: string;
  [x: string]: any;
}

/**
 * This is an FooterSocial
 * @param {FooterSocialProps} props
 */
const FooterSocial: React.FunctionComponent<FooterSocialProps> = (props) => {
  const {
    className,
    children,
    twitter,
    linkedin,
    facebook,
    instagram,
    youtube,
    ...other
  } = props;

  const socials = {
    Twitter: twitter,
    LinkedIn: linkedin,
    Facebook: facebook,
    Instagram: instagram,
    YouTube: youtube,
  };

  const icons = {
    Twitter: TwitterIcon,
    LinkedIn: LinkedInIcon,
    Facebook: FacebookIcon,
    Instagram: InstagramIcon,
    YouTube: YouTubeIcon,
  };

  let socialIcons: Array<JSX.Element> = [];

  Object.keys(socials).forEach((key, index) => {
    if (socials[key]) {
      socialIcons.push(
        <li className="tds-margin-xlg-right" key={index}>
          <a className="tds-footer__social-icon-link" href={socials[key]}>
            <span className="tds-sr-only">{key}</span>
            <UtilityIcon icon={icons[key]} color="primaryLight" />
          </a>
        </li>
      );
    }
  });

  let footerClasses = classNames({
    "tds-footer__social-icons": true,
  });

  footerClasses = className
    ? footerClasses.concat(" ", className)
    : footerClasses;

  return (
    <ul className={footerClasses} {...other}>
      {socialIcons.map((icons) => {
        return icons;
      })}
    </ul>
  );
};

FooterSocial.defaultProps = {} as Partial<FooterSocialProps>;

export default FooterSocial;
