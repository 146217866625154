import * as React from "react";
import FormGroup, { FormGroupType } from "../FormGroup/FormGroup";
import Input, { InputType } from "../Input/Input";
import Label, { LabelType } from "../Label/Label";
import Instruction, { InstructionType } from "../Instruction/Instruction";
import ErrorMessage, { ErrorMessageType } from "../ErrorMessage/ErrorMessage";
import InputCombo, { InputComboType } from "../InputCombo/InputCombo";
import InputButton, { InputButtonType } from "../InputCombo/InputButton";
import RadioButton, { RadioButtonType } from "../RadioButton/RadioButton";
import Textarea, { TextareaType } from "../Textarea/Textarea";
import LargeErrorMessage, {
  LargeErrorMessageType,
} from "../LargeErrorMessage/LargeErrorMessage";
import Checkbox, { CheckboxType } from "../Checkbox/Checkbox";
import Select, { SelectType } from "../Select/Select";
import { FormProps } from "../formTypes";

export type FormType = React.FunctionComponent<
  FormProps & React.HTMLProps<HTMLFormElement>
>;

const Form: FormType & { Group: FormGroupType } & { Input: InputType } & {
  InputButton: InputButtonType;
} & { Label: LabelType } & { Instruction: InstructionType } & {
  ErrorMessage: ErrorMessageType;
} & { InputCombo: InputComboType } & { RadioButton: RadioButtonType } & {
  Textarea: TextareaType;
} & { LargeErrorMessage: LargeErrorMessageType } & {
  Checkbox: CheckboxType;
} & { Select: SelectType } = (props) => {
  const { children, ...rest } = props;

  return <form {...rest}>{children}</form>;
};

Form.Group = FormGroup;
Form.Input = Input;
Form.InputButton = InputButton;
Form.Label = Label;
Form.Instruction = Instruction;
Form.ErrorMessage = ErrorMessage;
Form.InputCombo = InputCombo;
Form.RadioButton = RadioButton;
Form.Textarea = Textarea;
Form.LargeErrorMessage = LargeErrorMessage;
Form.Checkbox = Checkbox;
Form.Select = Select;

export default Form;
