import * as React from "react";
import classNames from "classnames";

export interface ModalFooterProps {}

export type ModalFooterType = React.FunctionComponent<
  ModalFooterProps & React.HTMLProps<HTMLDivElement>
>;

/**
 * This is a ModalFooter
 * @param {ModalFooterProps} props
 */

const ModalFooter: ModalFooterType = (props) => {
  const { children, className } = props;

  let footerClasses = classNames({
    "tds-modal__footer": true,
  });

  footerClasses = className
    ? footerClasses.concat(" ", className)
    : footerClasses;

  return <div className={footerClasses}>{children}</div>;
};

export default ModalFooter;
