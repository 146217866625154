import * as React from "react";
import classNames from "classnames";
import { StepProps } from "./stepInterface";
import InnerStepCircle from "./InnerStepCircle";
import StepTitle from "./StepTitle";
import StepAssistiveText from "./StepAssistiveText";

export interface ThreeSubStepProps extends StepProps {
  /**
   * Determines number of substeps completed
   */
  stepsComplete: 0 | 1 | 2 | 3;
}

/**
 * This is an Step
 * @param {ThreeSubStep} props
 */
const ThreeSubStep: React.FunctionComponent<ThreeSubStepProps> = (props) => {
  const {
    className,
    stepNumber,
    active,
    stepsComplete,
    title,
    subtitle,
    ...other
  } = props;
  const maxSubSteps = 3;

  const createSubStepClasses = (currentStep: number): object => {
    return {
      "tds-step-tracker__sub-steps": true,
      "tds-step-tracker__sub-steps--active":
        active && stepsComplete === currentStep,
      "tds-step-tracker__sub-steps--complete":
        active && stepsComplete >= currentStep + 1,
    };
  };

  const subStepOneClasses = classNames(createSubStepClasses(0));
  const subStepPathOne =
    active && stepsComplete === 0
      ? "M40,19.9012515 C40,22.8311689 39.3699768,25.6139365 38.2380146,28.1214701 L34.7514588,26.1082837 C35.5555349,24.1996527 36,22.1023573 36,19.9012515 C36,11.7422886 29.8930309,5.00968468 22.0007904,4.02513759 L22.0009781,9.91296065e-05 C32.1071547,1.00400103 40,9.53088987 40,19.9012515 Z"
      : "M40,20 C40,22.9299174 39.3699768,25.712685 38.2380146,28.2202186 L36.4957734,27.2147886 C37.4631961,25.0060263 38,22.5657018 38,20 C38,10.7350362 31.0001178,3.10498251 22.0004182,2.10990379 L22.0009781,0.0988476235 C32.1071547,1.10274952 40,9.62963836 40,20 Z";

  const subStepTwoClasses = classNames(createSubStepClasses(1));
  const subStepPathTwo =
    active && stepsComplete === 1
      ? "M32.7484437,29.5710534 L36.2352823,31.5835084 C32.6037095,36.6215806 26.6848588,39.9012515 20,39.9012515 C13.3151412,39.9012515 7.39629053,36.6215806 3.76471773,31.5835084 L7.25155634,29.5710534 C10.1736781,33.4175549 14.7967898,35.9012515 20,35.9012515 C25.2032102,35.9012515 29.8263219,33.4175549 32.7484437,29.5710534 Z"
      : "M20,38 C25.9436572,38 31.2144711,35.1192154 34.492345,30.6777429 L36.2352823,31.6822569 C32.6037095,36.7203291 26.6848588,40 20,40 C13.3151412,40 7.39629053,36.7203291 3.76471773,31.6822569 L5.50765496,30.6777429 C8.78552894,35.1192154 14.0563428,38 20,38 Z";

  const subStepThreeClasses = classNames(createSubStepClasses(2));
  const subStepPathThree =
    active && stepsComplete === 2
      ? "M18.0000201,0 L18.0002073,4.02501317 C10.1074838,5.00911732 4,11.7419448 4,19.9012515 C4,22.1023573 4.44446506,24.1996527 5.24854119,26.1082837 L1.76198539,28.1214701 C0.630023202,25.6139365 0,22.8311689 0,19.9012515 C0,9.53054844 7.89336506,1.00343957 18.0000201,0 Z"
      : "M18.0000201,0.0987484939 L18.0005846,2.10979295 C9.00040219,3.10441575 2,10.7346921 2,20 C2,22.5657018 2.53680386,25.0060263 3.50422663,27.2147886 L1.76198539,28.2202186 C0.630023202,25.712685 -4.54747351e-13,22.9299174 -4.54747351e-13,20 C-4.54747351e-13,9.74714584 7.71498968,1.29622406 17.6563408,0.135862971 L18.0000201,0.0987484939 Z";

  return (
    <li className="tds-step-tracker__sub-step-container" {...other}>
      <svg
        width="40px"
        height="40px"
        viewBox="0 0 40 40"
        version="1.1"
        aria-hidden="true"
        focusable="false"
      >
        <title>blocks/Steps-3/default Copy 3</title>
        <g
          id="Step-Tracker"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <g id="Step-tracker" transform="translate(-717.000000, -415.000000)">
            <g
              id=".blocks/steps/3-Steps/3rd-step-active"
              transform="translate(717.000000, 415.000000)"
            >
              <circle id="bg" fill="#FFFFFF" cx="20" cy="20" r="20"></circle>
              <g
                id=".blocks/step-number"
                transform="translate(8.000000, 8.000000)"
              >
                <InnerStepCircle
                  complete={maxSubSteps === stepsComplete}
                  displayNumber={stepNumber}
                />
              </g>
              <path
                d={subStepPathOne}
                className={subStepOneClasses}
                id="Path"
                fill="#DBDBDB"
                fillRule="nonzero"
              ></path>
              <path
                d={subStepPathTwo}
                className={subStepTwoClasses}
                id="Path"
                fill="#DBDBDB"
                fillRule="nonzero"
              ></path>
              <path
                d={subStepPathThree}
                className={subStepThreeClasses}
                id="Path"
                fill="#DBDBDB"
                fillRule="nonzero"
              ></path>
            </g>
          </g>
        </g>
      </svg>
      {(title || subtitle) && (
        <StepTitle title={title} subtitle={subtitle}></StepTitle>
      )}
      <StepAssistiveText
        stepsComplete={stepsComplete}
        maxSubSteps={maxSubSteps}
        active={active}
      />
    </li>
  );
};

ThreeSubStep.defaultProps = {
  stepNumber: 1,
  stepsComplete: 0,
  active: false,
} as Partial<ThreeSubStepProps>;

export default ThreeSubStep;
