import {
    initAnalytics,
    AppPlatform,
    ConfigurationKeys,
    SupportedDestinationKeys,
    AppDataKeys,
    logCustomUserEvent,
    logPageNav,
    TriggerType,
    captureEventTrigger,
    logExternalPageNav,
    logModalPageNav,
    setAppData,
    AppDataProperties,
  } from '@rally/analytics';

  export const initializeAnalytics = (config) => {
    let host = window.location.host;
    let env;
    if(host === ("member.uhc.com")){
        env = "PROD";
    }else if(host === ("member.uat.uhc.com")){
        env = "UAT";
    }else if(host === ("member.int.uhc.com")){
        env = "STAGE";
    }else{
        env = "DEV";
    }
    initAnalytics(
      {
        [SupportedDestinationKeys.Adobe]: {
          [ConfigurationKeys.Include]: true,
          [ConfigurationKeys.Sdk]: window._satellite,
        },
        [SupportedDestinationKeys.Amplitude]: {
          [ConfigurationKeys.Include]: false,
          [ConfigurationKeys.Sdk]: {},
        },
        [SupportedDestinationKeys.Banzai]: {
          [ConfigurationKeys.Include]: false,
          [ConfigurationKeys.SendEventMethod]: () => {
            return false;
          },
        },
        [ConfigurationKeys.GlobalBlocklist]: ['secret', 'token'],
        [ConfigurationKeys.Pillar]: 'care',
      },
      {
        [AppDataKeys.AppName]: 'mnr-activate-ucard',
        [AppDataKeys.AppVersion]: '1',
        [AppDataKeys.AppPlatform]: AppPlatform.Web,
        [AppDataKeys.AppSessionId]: undefined,
        [AppDataKeys.AppSessionType]: undefined,
        [AppDataKeys.Environment]:  env,
        [AppDataKeys.Product]: 'ucard',
      },
    );
  };
  
  export const logClickEvent  = (eventData) => {
      console.log("logClickEvent is firing");
    const explicitTriggerType = TriggerType.Click;
    const name = eventData.name;
    const additionalData = eventData.data;
    logCustomUserEvent(name, additionalData, explicitTriggerType, null);
  };
 
  
  export const logPageNavEvent = (eventData) => {
      console.log("logPageNavEvent is firing");
    const { pageName, pageTags, pageArgs, data } = eventData;
    logPageNav(pageName, pageTags, pageArgs, data);
  };

  export const setAddtionalAppData = (appData)=> {
    setAppData(appData);
  }

  export default {initializeAnalytics,logClickEvent,logPageNavEvent,setAddtionalAppData}