import React from "react";

export interface AccordionProps {
  /**
   * Title to show in the accordion header as a description of the collapsed content.
   *
   * @type {string}
   * @memberof AccordionProps
   */
  title: string;
  /**
   * Children here is the content that should show when the accordion is in an expanded state.
   *
   * @type {JSX.Element}
   * @memberof AccordionProps
   */
  children: JSX.Element;
}

/**
 * An Accordion wrapper for building content for an Accordion group
 * -- Can not be used on its own, it needs to be nested within an Accordion Group
 *
 * @param {AccordionProps} props
 */
const Accordion: React.FunctionComponent<AccordionProps> = (props) => {
  return (
    <h3>
      NOTE: accordions should only be used, and will only display properly when
      used within an accordion group
    </h3>
  );
};

export default Accordion;
