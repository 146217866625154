import * as React from "react";
import {
  ACCORDION_CONTENT_KEY,
  ACCORDION_HEADER_TITLE_KEY,
} from "./accordion.const";
import { useRef, useLayoutEffect } from "react";
import { UniqueIdentifier } from "hooks/uniqueIdentifier";

export interface AccordionContentProps {
  isOpen: boolean;
  children: JSX.Element;
  accordionIdentifier: UniqueIdentifier;
}

/**
 * An Accordion for displaying accordion content.
 *
 * @param {AccordionContentProps} props
 */
const AccordionContent: React.FunctionComponent<AccordionContentProps> = (
  props
) => {
  const { children, isOpen, accordionIdentifier } = props;
  const openState = isOpen ? "open" : "closed";
  const ref = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    if (!isOpen)
      ref.current?.classList.add("tds-accordion-content__container--hide");
  });

  return (
    <div
      id={accordionIdentifier.getChildId(ACCORDION_CONTENT_KEY)}
      role="region"
      aria-labelledby={accordionIdentifier.getChildId(
        ACCORDION_HEADER_TITLE_KEY
      )}
      aria-hidden={isOpen ? "false" : "true"}
      className={`tds-accordion-content__container tds-accordion-content__container--${openState}`}
      ref={ref}
    >
      {children}
    </div>
  );
};

export default AccordionContent;
