import * as React from "react";
import classNames from "classnames";
import tokens from "../../tokens/token_modules/allTokens.json";
import { OverridableComponent } from "@material-ui/core/OverridableComponent";
import { SvgIconTypeMap } from "@material-ui/core";

const colorTokens = tokens.color;

type MaterialFontSizes = "small" | "medium" | "large" | "inherit";
export type IconColorVariants =
  | "primary"
  | "primaryCTA"
  | "primaryLight"
  | "gray"
  | "disabled"
  | "success"
  | "alert"
  | "currentColor";

export interface UtilityIconProps {
  /**
   * Name of the icon that you want to render
   */
  icon: OverridableComponent<SvgIconTypeMap<{}, "svg">>;
  /**
   * When passed in creates a \<title\> element within the SVG.
   * The \<title\> element provides an accessible, short-text description of any SVG container element or graphics element.
   */
  title?: string;
  /**
   * When passed in creates a \<desc\> element within the SVG.
   * The \<desc\> element provides an accessible, long-text description of any SVG container element or graphics element.
   */
  description?: string;
  /**
   * Size to render the SVG element
   * Conforms to expected sizes for utility icons for the design system.
   */
  size?: "small" | "medium" | "large";
  /**
   * Colors that are approved to use for the utility icons. They align to the following tokens:
   *
   * - `primary`: color-fill-utility-icon-primary
   * - `primaryCTA`: color-fill-utility-icon-primaryCTA
   * - `primaryLight`: color-fill-utility-icon-primaryLight
   * - `gray`: color-fill-utility-icon-gray
   * - `disabled`: color-fill-utility-icon-disabled
   * - `success`: color-fill-utility-icon-success
   * - `alert`: color-fill-utility-icon-alert
   */
  color?: IconColorVariants;
}

const COLORS = {
  primary: colorTokens.fill["utility-icon"].primary.value,
  primaryCTA: colorTokens.fill["utility-icon"].primaryCTA.value,
  primaryLight: colorTokens.fill["utility-icon"].primaryLight.value,
  gray: colorTokens.fill["utility-icon"].gray.value,
  disabled: colorTokens.fill["utility-icon"].disabled.value,
  success: colorTokens.fill["utility-icon"].success.value,
  alert: colorTokens.fill["utility-icon"].alert.value,
  currentColor: "currentColor",
};

const SIZE = {
  small: "small",
  medium: "medium",
  large: "large",
};

/**
 * Utility icon
 * - A helper component wrapper for material icons to ensure they are used correctly within the context of the Tempo design system
 *
 * @param {UtilityIconProps} props
 * @return {React.FunctionComponent<UtilityIconProps & React.SVGAttributes<SVGElement>>}
 */
const UtilityIcon: React.FunctionComponent<
  UtilityIconProps & React.SVGAttributes<SVGElement>
> = (props) => {
  const {
    icon,
    title,
    description,
    size = "medium",
    color = COLORS.primary,
    className = "",
    ...rest
  } = props;
  const IconSvg = icon as any;
  if (!icon) {
    console.warn(
      "An svg icon from the material library must be included as an icon prop"
    );
    return null;
  }

  let iconClasses = classNames({
    "tds-utility-icon": true,
  });

  iconClasses = iconClasses.concat(" ", className);

  const fontSize = (SIZE[size] || SIZE.medium) as MaterialFontSizes;
  const iconColor = COLORS[color] || COLORS.primary;
  return (
    <IconSvg
      className={iconClasses}
      fontSize={fontSize}
      htmlColor={iconColor}
      titleAccess={title}
      desc={description}
      role={title || description ? undefined : "img"}
      aria-hidden={title || description ? undefined : "true"}
      focusable={title || description ? undefined : "false"}
      {...rest}
    />
  );
};

UtilityIcon.defaultProps = {} as Partial<UtilityIconProps>;

export default UtilityIcon;
