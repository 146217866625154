import * as React from "react";
import classNames from "classnames";
import PaginationNavigationButton from "./sub-components/PaginationNavigationButton";
import PaginationDropdown from "./sub-components/PaginationDropdown";
import { useState, useEffect } from "react";
import Select from "components/form/Select/Select"
import { useUniqueId } from "../../hooks";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import "tippy.js/themes/light-border.css";
import "tippy.js/animations/shift-away.css";
import { KeyboardArrowUp, KeyboardArrowDown } from "@material-ui/icons";
import UtilityIcon from "../icon/UtilityIcon";

type a11yModes = "default" | "focus" | "live" | "none";

export interface PaginationProps {
  /**
   * Switch Next button text
   */
  altNextTitle?: string;
  /**
   * Switch Previous button text
   */
  altPreviousTitle?: string;
  /**
   * Switch "Page" in dropdown text
   */
  altPageTitle?: string;
  /**
   * Determine wether change events (button click and dropdown selection)
   * have event.preventDefault called or not
   */
  preventDefault?: boolean;
  /**
   * Current active page
   */
  page: number;
  /**
   * Max amount pages. Determines max number in dropdown.
   */
  maxPage: number;
  /**
   * Different accessibility modes for keyboard and screen reader navigation
   */
  changeA11yMode?: a11yModes;
  /**
   * Used with changeA11yMode={"focus"} as id to focus after page change.
   */
  changeFocusId?: string;
  /**
   * Default orientation of pagination dropdown.
   */
  defaultOrientation?: "top" | "bottom";
  /**
   * Call back function for page change event (navigation arrows and dropdown)
   */
  onPageChange: (
    event:
      | React.MouseEvent<HTMLElement>
      | React.KeyboardEvent<HTMLElement>
      | React.FormEvent<HTMLElement>,
    page: number
  ) => void;
}

/**
 * Pagination
 * @param {PaginationProps} props
 */
const Pagination: React.FunctionComponent<
  PaginationProps & React.HTMLProps<HTMLDivElement>
> = (props) => {
  const {
    className,
    altPageTitle,
    page,
    maxPage,
    altPreviousTitle,
    altNextTitle,
    onPageChange,
    preventDefault,
    changeA11yMode,
    changeFocusId,
    defaultOrientation = "bottom",
    ...other
  } = props;

  let PaginationClasses = classNames({
    "tds-pagination": true,
  });

  const groupIdentifier = useUniqueId("pagination");
  const DROPDOWN_SELECT_ID = groupIdentifier.getChildId("select");
  const NEXT_BUTTON_ID = groupIdentifier.getChildId("next-button");
  const PREVIOUS_BUTTON_ID = groupIdentifier.getChildId("previous-button");
  const dropdownElement = document.getElementById(DROPDOWN_SELECT_ID);

  let keyboardDetectedOnSelect = false;
  //Create text entries for the dropdown
  const pageNumbers: number[] = createPageNumberEntries(maxPage);

  PaginationClasses = className
    ? PaginationClasses.concat(" ", className)
    : PaginationClasses;

  // Set up state
  const [isOpen, setIsOpen] = useState(false);

  const setUpCloseDropdownKeyboard = (event: KeyboardEvent) => {
    if (event.key === "Escape") {
      setIsOpen(false);
    }
  };

  const PaginationDropdownElement = () => {
    return (
      <PaginationDropdown
        altTitle={altPageTitle ? altPageTitle : ""}
        isOpen={isOpen}
        page={page}
        maxPage={maxPage}
        groupIdentifier={groupIdentifier}
        onPageSelection={onPageSelection}
        closeDropdown={() => setIsOpen(false)}
        pageNumbers={pageNumbers}
        changeFocusId={
          changeA11yMode === "focus" && changeFocusId ? changeFocusId : ""
        }
      />
    );
  };

  //====================== Functions ========================

  function a11yChangeEvent(
    event:
      | React.MouseEvent<HTMLElement>
      | React.KeyboardEvent<HTMLElement>
      | React.FormEvent<HTMLElement>
  ) {
    switch (changeA11yMode) {
      case "focus":
        if (changeFocusId) {
          document.getElementById(changeFocusId ? changeFocusId : "")?.focus();
        } else {
          console.warn(
            "You must pass in a changeFocusId when changeA11yMode is set to 'focus'"
          );
        }
        break;
      case "default":
      default:
        break;
    }
  }

  function navKeyPressHandler(
    event: React.KeyboardEvent<HTMLElement>,
    advanceForward: boolean
  ): void {
    // Guard statements
    if (event.key !== "Enter") return;

    const updated = onClickPageChange(
      event,
      page,
      advanceForward,
      preventDefault
    );
    onPageChange(updated.event, updated.page);
    if (isKeyboard(updated.event)) {
      a11yChangeEvent(updated.event);
    }
  }

  function navClickHandler(
    event: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>,
    advanceForward: boolean
  ): void {
    const updated = onClickPageChange(
      event,
      page,
      advanceForward,
      preventDefault
    );

    onPageChange(updated.event, updated.page);
    a11yChangeEvent(updated.event);
  }

  // Use for triggering page change within PaginationDropdown (and select)
  // But mainly so the actually rendering events all happen at the parent level
  // and reduce prop passing.
  function onPageSelection(
    event:
      | React.MouseEvent<HTMLElement>
      | React.KeyboardEvent<HTMLElement>
      | React.FormEvent<HTMLElement>,
    page: number,
    enterPress: boolean = false
  ): void {
    onPageChange(event, page);
    setIsOpen(false);

    if (changeA11yMode === "none") return;

    if (enterPress && changeA11yMode !== "focus") {
      dropdownElement?.focus();
    } else if (enterPress && changeA11yMode === "focus") {
      a11yChangeEvent(event);
    }
  }
  //======================= Hooks ============================

  useEffect(() => {
    //If user hits the escape key or tabs out, close it.
    document.addEventListener("keyup", setUpCloseDropdownKeyboard);

    return function cleanup() {
      document.removeEventListener("keyup", setUpCloseDropdownKeyboard);
    };
  });

  //======================= Render ============================

  return (
    <div className={PaginationClasses} {...other}>
      <PaginationNavigationButton
        id={PREVIOUS_BUTTON_ID}
        direction="left"
        altTitle={altPreviousTitle}
        altPageTitle={altPageTitle ? altPageTitle : ""}
        hide={page === maxPage - (maxPage - 1)}
        onClick={(event) => {
          if (checkIfButtonHidden(PREVIOUS_BUTTON_ID)) return;

          navClickHandler(event, false);
        }}
        onKeyDown={(event) => navKeyPressHandler(event, false)}
      />
      <span className="tds-pagination__tippy-container">
        <span id="section-helper-text" className="tds-sr-only">
          Select a {altPageTitle}.
        </span>
        <Tippy
          content={<PaginationDropdownElement />}
          placement={defaultOrientation}
          interactive={true}
          theme="light-border"
          visible={isOpen}
          animation="shift-away"
          role="listbox"
          onClickOutside={() => setIsOpen(false)}
          popperOptions={{
            placement: defaultOrientation,
            modifiers: [
              {
                name: "flip",
                options: {
                  fallbackPlacements: ["bottom", "top"],
                  padding: 8,
                },
              },
              {
                name: "offset",
                options: {
                  offset: [0, 20],
                },
              },
            ],
          }}
        >
          <button
            id={DROPDOWN_SELECT_ID}
            className="tds-pagination__section-select"
            onClick={() => setIsOpen(!isOpen)}
            aria-labelledby="section-helper-text"
          >
            <span className="tds-pagination__section-select-text">
              {altPageTitle} {page} of {maxPage}
            </span>
            <UtilityIcon
              className="tds-pagination__section-select-icon"
              size="medium"
              icon={isOpen ? KeyboardArrowUp : KeyboardArrowDown}
              color="gray"
            />
          </button>
        </Tippy>
      </span>
      {/** This only displays for mobile*/}
      <Select
        className="tds-pagination__select-mobile"
        id="tester"
        onChange={(event) => {
          const target = event.target as HTMLOptionElement;
          onPageSelection(
            event,
            parseInt(target.value),
            keyboardDetectedOnSelect
          );
        }}
        onKeyDown={(event) => {
          keyboardDetectedOnSelect = true;
        }}
        value={page}
      >
        {pageNumbers.map((currentPage) => {
          return (
            <Select.Option key={currentPage} value={currentPage}>
              {altPageTitle} {currentPage}
            </Select.Option>
          );
        })}
      </Select>
      <PaginationNavigationButton
        id={NEXT_BUTTON_ID}
        direction="right"
        altTitle={altNextTitle}
        altPageTitle={altPageTitle ? altPageTitle : ""}
        hide={page === maxPage}
        onClick={(event) => {
          if (checkIfButtonHidden(NEXT_BUTTON_ID)) return;

          navClickHandler(event, true);
        }}
        onKeyDown={(event) => navKeyPressHandler(event, true)}
      />
    </div>
  );
};

//======================= Helper Functions ============================

function onClickPageChange(
  event: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>,
  page: number,
  advanceForward: boolean,
  preventDefault: boolean = true
): {
  page: number;
  event: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>;
} {
  let updatedPage;

  if (advanceForward) {
    updatedPage = page + 1;
  } else {
    updatedPage = page - 1;
  }

  if (preventDefault) event.preventDefault();

  return { page: updatedPage, event: event };
}

/**
 * Create array of numbers up to numberOfPages
 * @param {number} numberOfPages Max amount of pages
 * @returns {Array} A list of number entries for dropdown
 */
function createPageNumberEntries(numberOfPages: Number): number[] {
  let currentPage = 1;
  let pages: number[];
  pages = [];

  do {
    pages.push(currentPage);
    currentPage++;
  } while (currentPage <= numberOfPages);

  return pages;
}

function checkIfButtonHidden(id: string): boolean | undefined {
  const buttonElement = document.getElementById(id);
  const classes = buttonElement?.classList;
  return classes?.contains("tds-pagination__navigation-button--hidden");
}

// Apparently React events don't exist at runtime so this is basically checking if the event
// at runtime is a native keyboard event then return the React Keyboard event type to make
// typescript happy :( This is an example of a User-Defined Type Guard or the new name "Narrowing".
// Typescript: https://www.typescriptlang.org/docs/handbook/2/narrowing.html#using-type-predicates
function isKeyboard(
  e: React.MouseEvent | React.KeyboardEvent | React.FormEvent<HTMLElement>
): e is React.KeyboardEvent {
  return e.nativeEvent instanceof KeyboardEvent;
}

//==================================================================

Pagination.defaultProps = {
  altNextTitle: "Next",
  altPreviousTitle: "Previous",
  altPageTitle: "Page",
  preventDefault: true,
  page: 1,
  maxPage: 1,
  changeA11yMode: "default",
  defaultOrientation: "bottom",
} as Partial<PaginationProps>;

export default Pagination;
