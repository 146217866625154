import * as React from "react";

const AuthNavMobile: React.FC<React.HTMLProps<HTMLDivElement>> = ({
  children,
  ...rest
}) => (
  <div className="s-nav-mobile" {...rest}>
    {children}
  </div>
);

AuthNavMobile.displayName = "AuthNav.Mobile";

export default AuthNavMobile;
