import * as React from "react";
import ModalContext from "./ModalContext";
import FocusTrap from "focus-trap-react";
import classNames from "classnames";
import ModalHeader, { ModalHeaderType } from "./ModalHeader";
import ModalContent, { ModalContentType } from "./ModalContent";
import ModalFooter, { ModalFooterType } from "./ModalFooter";
import ModalTitle, {
  ModalTitleType,
  MODAL_TITLE_COMPONENT_KEY,
} from "./ModalTitle";
import { useUniqueId } from "../../hooks";

const MODAL_COMPONENT_KEY = "modal";

const { useCallback, useEffect, useRef } = React;

export interface ModalProps {
  /**
   * Unique key to ensure we keep uniqueness across different accordion groups if they are on the same page.
   * This will be used to create all the id's needed for the content and accessibility requirements.
   *
   * @type {number}
   * @memberof ModalProps
   */
  contentId?: number | string;

  /** A handler to close the modal. It will be passed to the backdrop and the close button. */
  handleClose: () => void;
}

export interface ConditionalFocusTrapProps {
  condition: boolean;
  children: React.ReactElement | React.ReactElement[];
}

// Keeps focus within the modal after it has mounted
const ConditionalFocusTrap = ({
  condition,
  children,
}: ConditionalFocusTrapProps) => {
  /* istanbul ignore next */ // FocusTrap does not work with Jest at all!
  return condition && process.env.JEST_WORKER_ID === undefined ? (
    <FocusTrap>{children}</FocusTrap>
  ) : (
    <React.Fragment>{children}</React.Fragment>
  );
};

/**
 * This is a Modal
 * @param {ModalProps} props
 */
const Modal: React.FunctionComponent<
  ModalProps & React.HTMLProps<HTMLDivElement>
> & { Header: ModalHeaderType } & { Title: ModalTitleType } & {
  Content: ModalContentType;
} & { Footer: ModalFooterType } = (props) => {
  const { contentId, children, className, handleClose, ...rest } = props;
  const modalIdentifier = useUniqueId(MODAL_COMPONENT_KEY, contentId);

  let modalRef = useRef<HTMLDivElement>(null);
  let previousOverflow: string | null = "";
  let previouslyFocusedElement: HTMLElement | null = null;
  let focusFallbackRef = React.useRef<HTMLDivElement>(null);
  let componentIsMounted = useRef(true);

  const handleKeyDown = useCallback(
    (event) => {
      const { key } = event;
      if (key === "Escape") {
        handleClose();
      }
    },
    [handleClose]
  );

  const handleOutsideClick = useCallback(
    (e: React.MouseEvent) => {
      if (modalRef !== null && modalRef.current !== null) {
        if (!modalRef.current.contains((e as any).target)) {
          handleClose();
        }
      }
    },
    [handleClose, modalRef]
  );

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);

    // Remember the document's overflow setting
    // eslint-disable-next-line react-hooks/exhaustive-deps
    previousOverflow = document.body.style.overflow;

    // Focus back on the previously focused element when unmounted
    // eslint-disable-next-line react-hooks/exhaustive-deps
    previouslyFocusedElement = document.activeElement as HTMLElement;

    document.body.style.overflow = "hidden";

    // Prevent double animation when wrapping the modal in a focus trap
    componentIsMounted.current = true;

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
      document.body.style.overflow =
        previousOverflow !== null ? previousOverflow : "visible";

      // Focus back on the element that had focus before the modal was opened
      try {
        if (previouslyFocusedElement !== null) {
          previouslyFocusedElement.focus();
        }
        // eslint-disable-next-line no-empty
      } catch (e) {}
      componentIsMounted.current = false;
    };
  }, [
    JSON.stringify(modalIdentifier),
    JSON.stringify(handleKeyDown),
    previousOverflow,
    previouslyFocusedElement,
    componentIsMounted,
  ]);

  let modalClasses = classNames({
    "tds-modal": true,
  });

  // Allow user to add there own classes to the base card div
  modalClasses = className ? modalClasses.concat(" ", className) : modalClasses;

  const context = {
    handleClose,
    modalIdentifier,
  };

  const ariaProps = {
    "aria-modal": true,
    "aria-labelledby": modalIdentifier.getChildId(MODAL_TITLE_COMPONENT_KEY),
  };

  return (
    <ModalContext.Provider value={context}>
      <ConditionalFocusTrap condition={componentIsMounted.current}>
        <div className={`tds-modal__focus`}>
          <div
            ref={focusFallbackRef}
            className={`tds-modal__outer`}
            onClick={handleOutsideClick}
          >
            <div
              className={modalClasses}
              ref={modalRef}
              role="dialog"
              {...ariaProps}
              {...rest}
            >
              {children}
            </div>
          </div>
          <div className={`tds-modal__backdrop`}></div>
        </div>
      </ConditionalFocusTrap>
    </ModalContext.Provider>
  );
};

Modal.defaultProps = {
  handleClose: () => {},
} as Partial<ModalProps>;

Modal.Header = ModalHeader;
Modal.Title = ModalTitle;
Modal.Content = ModalContent;
Modal.Footer = ModalFooter;

export default Modal;
