import React, { Component } from 'react';
import { Banner, Form, Label } from '@uhc-tempo/components';
class Version extends Component {
     state ={
         versionConfig: null
     }    

     componentDidMount = () => {
       
        fetch('ucard-activation/static/js/version.json')
          .then(res => res.json())
          .then(data => {this.setState({versionConfig : data});});
        }      
     
     
  render() {
    let configurations = [];
    let totalConfigs = {};
    Object.assign(totalConfigs, this.state.versionConfig);
    if(totalConfigs != null ) {
        for (var key in totalConfigs) {
            configurations.push(
      <div style={{  width: "100%",
            paddingRight: "15px",
            paddingLeft: "15px",
            paddingTop: "10px",
            marginRight: "auto",
            marginLeft: "auto",
            maxWidth: "1140px",
            margin: "auto"
            }}>

        <span>
        {key + " : "}
        </span>
        <span>
        {(totalConfigs[key]).toString()}
        </span>
      </div>
            );
            
        }
    }

    
   

    return (
      <div>
        <Banner disable="false" text="MNR - Activate Ucard Version" basetitle="MNR - Activate Ucard Version" /> 
        <div class="container">
        <br/>
            <div class="config-box">
             {configurations}
            </div>
        </div>
        <br/><br/>
      </div>
   )
  }
}

export default Version
