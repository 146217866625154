import * as React from "react";
import classNames from "classnames";
import UtilityIcon from "../icon/UtilityIcon";
import LaunchIcon from "@material-ui/icons/Launch";
import AutorenewIcon from "@material-ui/icons/Autorenew";
import { ClickTrackData } from "tracking/types";
import { useClickTracking } from "../../tracking";

//TODO: Button should keep pill radius regardless of font size

export interface ButtonProps {
  /**
   * Style determines the type of button rendered. This is used to apply
   * the correct classes to style the button.
   */
  buttonType?:
    | "primary-one"
    | "primary-two"
    | "primary-three"
    | "secondary-one"
    | "secondary-two"
    | "disabled";
  /**
   * When in the disabled state the button has a gray background. This adds a dark
   * border in the cases the button is on a gray background.
   */
  disabledBorder?: boolean;
  /**
   * When this prop is used it creates an anchor element
   * styled like a button
   */
  href?: string;
  /**
   * Adds new window/tab icon and sets target="_blank"
   */
  newTabWindow?: boolean;
  /**
   * Toggles the rendering of the default button icons
   */
  noIcon?: boolean;
  /**
   * Determines the default size of the button
   */
  size?: "md" | "lg";
  /**
   *
   */
  loading?: boolean;
  /**
   * onClick tracking data
   */
  onClickTrackingData?: ClickTrackData;
  /**
   * <span style="color: #c40000;">**Deprecated**</span>
   *
   * This prop is deprecated and will be removed in future versions.
   * All text should now be passed as children of the component. Please update accordingly.
   * @deprecated
   */
  text?: string;
  /**
   * <span style="color: #c40000;">**Deprecated**</span>
   *
   * This prop is deprecated as of version 2.1.4 and will be removed soon.
   *
   * @deprecated
   */
  fullWidth?: boolean;
  /**
   * <span style="color: #c40000;">**Deprecated**</span>
   *
   * Please use `newTabWindow` instead. This prop will be removed soon.
   * @deprecated
   */
  externalLink?: boolean;

  [x: string]: any;
}

/**
 * This is a button
 * @param {ButtonProps} props
 */
const Button: React.FunctionComponent<ButtonProps> = (props) => {
  const {
    text,
    buttonType,
    noIcon,
    fullWidth,
    href,
    newTabWindow,
    externalLink,
    className,
    disabledBorder,
    loading,
    onClickTrackingData,
    onClick,
    size,
    ...other
  } = props;
  const trackedOnClick = useClickTracking(onClickTrackingData, onClick);
  const openNewTab = newTabWindow ? true : externalLink ? true : false;

  let buttonClasses = classNames({
    "tds-button": true,
    "tds-focus-dark-background":
      buttonType === "primary-two" || buttonType === "secondary-two",
    "tds-button--primary-one": buttonType === "primary-one",
    "tds-button--primary-two": buttonType === "primary-two",
    "tds-button--primary-three": buttonType === "primary-three",
    "tds-button--secondary-one": buttonType === "secondary-one",
    "tds-button--secondary-two": buttonType === "secondary-two",
    "tds-button--large": size === "lg",
    "tds-button--disabled": buttonType === "disabled",
    "tds-button--disabled-border": disabledBorder,
    "tds-button--full-width": fullWidth,
    "tds-button--link": href,
  });

  buttonClasses = className
    ? buttonClasses.concat(" ", className)
    : buttonClasses;

  if (href) {
    return (
      <a
        href={href}
        target={openNewTab ? "_blank" : ""}
        className={buttonClasses}
        onClick={trackedOnClick}
        {...other}
      >
        {props.children}
        {text}
        {openNewTab && (
          <span className="tds-sr-only">, opens in a new tab or window</span>
        )}
        {openNewTab && !noIcon && (
          <UtilityIcon
            size="small"
            icon={LaunchIcon}
            color="currentColor"
            className="tds-button__icon"
          />
        )}
      </a>
    );
  } else {
    return (
      <button
        className={buttonClasses}
        aria-disabled={buttonType === "disabled" ? "true" : "false"}
        onClick={trackedOnClick}
        {...other}
      >
        {props.children}
        {text}
        {loading && (
          <UtilityIcon
            size="small"
            icon={AutorenewIcon}
            className={"tds-button__icon tds-button__icon--loading"}
            color="gray"
          />
        )}
      </button>
    );
  }
};

Button.defaultProps = {
  size: "md",
  buttonType: "primary-one",
  disabledBorder: false,
  loading: false,
} as Partial<ButtonProps>;

export default Button;
