import * as React from "react";
import classNames from "classnames";
import { FormElementProps } from "../formTypes";

export interface InstructionProps extends FormElementProps {
  /**
   * If this is true it will hide the instruction text.
   */
  hideInstruction?: boolean;
  state?: string;
}

export type InstructionType = React.FunctionComponent<
  InstructionProps & React.HTMLProps<HTMLSpanElement>
>;

/**
 * This is an Instruction
 * @param {InstructionProps} props
 */
const Instruction: InstructionType = (props) => {
  const {
    className,
    groupIdentifier,
    state /* Is this needed? */,
    hideInstruction,
    id,
    ...other
  } = props;

  let instructionClasses = classNames({
    "tds-form__instruction": true,
    "tds-form__instruction--hide": hideInstruction,
  });

  instructionClasses = className
    ? instructionClasses.concat(" ", className)
    : instructionClasses;
  let instructionGroupId = groupIdentifier?.getChildId("instruction");

  return (
    <span
      id={instructionGroupId || id}
      className={instructionClasses}
      {...other}
    >
      {props.children}
    </span>
  );
};

Instruction.defaultProps = {} as Partial<InstructionProps>;

export default Instruction;
