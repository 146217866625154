import * as React from "react";
import classNames from "classnames";
import { FormElementProps } from "../formTypes";

export interface LargeErrorMessageProps extends FormElementProps {
  state?: "error";
}

export type LargeErrorMessageType = React.FunctionComponent<
  LargeErrorMessageProps & React.HTMLProps<HTMLDivElement>
>;

/**
 * This is an LargeErrorMessage
 * @param {LargeErrorMessageProps} props
 */
const LargeErrorMessage: LargeErrorMessageType = (props) => {
  const { className, groupIdentifier, state, ...other } = props;

  let largeErrorMessageClasses = classNames({
    "tds-form__large-error-message": true,
  });

  largeErrorMessageClasses = className
    ? largeErrorMessageClasses.concat(" ", className)
    : largeErrorMessageClasses;

  return (
    <div className={largeErrorMessageClasses} {...other}>
      <div className="tds-form__large-error-message-icon">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="24"
          viewBox="0 0 24 24"
          width="24"
          aria-hidden="true"
          focusable="false"
        >
          <path d="M0 0h24v24H0z" fill="none" />
          <path d="M1 21h22L12 2 1 21zm12-3h-2v-2h2v2zm0-4h-2v-4h2v4z" />
        </svg>
      </div>
      <p className="tds-form__large-error-message-text">{props.children}</p>
    </div>
  );
};

LargeErrorMessage.defaultProps = {
  state: "error",
} as Partial<LargeErrorMessageProps>;

export default LargeErrorMessage;
