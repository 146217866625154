import * as React from "react";
import classNames from "classnames";

export interface FooterLinkWrapperProps {
  heading?: any;
  groupId?: string;
  [x: string]: any;
}

/**
 * This is an FooterLinkWrapper
 * @param {FooterLinkWrapperProps} props
 */
const FooterLinkWrapper: React.FunctionComponent<FooterLinkWrapperProps> = (
  props
) => {
  const { className, children, heading, groupId, ...other } = props;

  let footerClasses = classNames({
    "tds-footer__link-wrapper": true,
  });

  footerClasses = className
    ? footerClasses.concat(" ", className)
    : footerClasses;

  const elements = React.Children.toArray(children);

  return (
    <div className={footerClasses} {...other}>
      {heading &&
        React.cloneElement(heading, {
          id: props.groupId,
          className: "tds-footer__link-heading",
        })}
      <ul aria-labelledby={groupId}>
        {elements.length > 1
          ? React.Children.map(children, (child, i) => {
              return <li className="tds-footer__link-list-item ">{child}</li>;
            })
          : children}
      </ul>
    </div>
  );
};

FooterLinkWrapper.defaultProps = {} as Partial<FooterLinkWrapperProps>;

export default FooterLinkWrapper;
