import * as React from "react";
import classNames from "classnames";
import { UniqueIdentifier } from "hooks/uniqueIdentifier";

export interface ErrorMessageProps {
  /**
   * Adjusts the color of the RadioButton/Checkbox based on state
   */
  state?: "default" | "error" | "disabled";
  groupIdentifier?: UniqueIdentifier;
}

export type ErrorMessageType = React.FunctionComponent<
  ErrorMessageProps & React.HTMLProps<HTMLSpanElement>
>;

/**
 * This is an ErrorMessage
 * @param {ErrorMessageProps} props
 */
const ErrorMessage: ErrorMessageType = (props) => {
  const { className, groupIdentifier, state, ...other } = props;

  let ErrorMessageClasses = classNames({
    "tds-form__error-message": true,
  });

  ErrorMessageClasses = className
    ? ErrorMessageClasses.concat(" ", className)
    : ErrorMessageClasses;

  let errorGroupId = groupIdentifier
    ? groupIdentifier.getChildId("error-message")
    : undefined;

  return state === "error" ? (
    <span id={errorGroupId} className={ErrorMessageClasses} {...other}>
      {props.children}
    </span>
  ) : null;
};

ErrorMessage.defaultProps = {
  state: "error",
} as Partial<ErrorMessageProps>;

export default ErrorMessage;
