import { Button } from "@uhc-tempo/components";
import styles from "./css/Activateuhccom.module.css";
import React from "react";

 
class MockPage extends React.Component {

    onButtonClick =  () => {
           console.log("Goto DashBoard Button was clicked");
           window.location.href="/dashboard";
        } 
    
  render() {
      return(
      <>
      <div className={styles.activateuhccomDiv}>
      <h2 className={styles.tempLink}>Ucard DeepLink</h2>
      <Button onClick={this.onButtonClick} className={styles.signInBtn}>Goto DashBoard</Button>
      </div>
      </>
      )
    
  }
}
 
export default MockPage;
