import * as React from "react";

export interface AuthNavSubNavProps {
  // HTML Props
  /** Props passed to the Navs's inner container. */
  innerContainerProps?: React.HTMLProps<HTMLDivElement>;
  /** Props passed to the Navs's item list container. */
  listContainerProps?: React.HTMLProps<HTMLUListElement>;
  /** Collapse the menu on smaller screen sizes */
  responsive?: boolean;
  /** Prefix links with screen reader text. For example, pageContext="Coverage" will announce "Coverage: Overview" or "Coverage: Medical". */
  pageContext?: string;
}

const AuthNavSubNav: React.FC<
  AuthNavSubNavProps & React.HTMLProps<HTMLDivElement>
> = ({
  children,
  innerContainerProps,
  listContainerProps,
  responsive = false,
  pageContext,
  ...rest
}) => {
  const [expanded, setExpanded] = React.useState(false);
  const ariaProps = {
    "aria-label": rest["aria-label"] || "Sub page",
  };
  return (
    <nav className="s-nav-subnav" {...rest} {...ariaProps}>
      <div className="s-nav-container" {...innerContainerProps}>
        <ul className={`s-nav-items`} {...listContainerProps}>
          {React.Children.map(children, (child: React.ReactNode) => {
            return React.cloneElement(child as React.ReactElement, {
              expanded,
              setExpanded,
              pageContext,
            });
          })}
        </ul>
      </div>
    </nav>
  );
};

AuthNavSubNav.displayName = "AuthNav.SubNav";

export default AuthNavSubNav;
