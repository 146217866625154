import * as React from "react";
import ModalContext from "./ModalContext";
import Typography from "../typography/Typography";
import classNames from "classnames";

export interface ModalTitleProps {}

export type ModalTitleType = React.FunctionComponent<
  ModalTitleProps & React.HTMLProps<HTMLDivElement>
>;

export const MODAL_TITLE_COMPONENT_KEY = "title";

/**
 * This is a ModalTitle
 * @param {ModalTitleProps} props
 */

const ModalTitle: ModalTitleType = (props) => {
  const { children, className } = props;

  let titleClasses = classNames({
    "tds-modal__title": true,
    "tds-margin-none-bottom": true,
    "tds-margin-none-top": true,
  });

  titleClasses = className ? titleClasses.concat(" ", className) : titleClasses;

  const { modalIdentifier } = React.useContext(ModalContext);

  return (
    <Typography.H2
      className={titleClasses}
      id={modalIdentifier.getChildId(MODAL_TITLE_COMPONENT_KEY)}
    >
      {children}
    </Typography.H2>
  );
};

export default ModalTitle;
