
import React from "react";
//import { Activateuhccom } from "./Activateuhccom";
import { UcardActivation } from "./UcardActivation";

class RegistrationConfirmation extends React.Component {
  render() {
    return <UcardActivation isRegistrationRequest={true} />;
  }
}
 
export default RegistrationConfirmation;