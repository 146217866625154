import * as React from "react";

export interface InnerStepCircleProps {
  complete?: boolean;
  displayNumber: number;
}

export default class InnerStepCircle extends React.PureComponent<
  InnerStepCircleProps
> {
  render() {
    const { complete, displayNumber } = this.props;

    if (complete) {
      return (
        <React.Fragment>
          <circle id="Oval" fill="#008C00" cx="12" cy="12" r="12"></circle>
          <g
            transform="translate(3.000000, 3.000000)"
            fill="#FFFFFF"
            id="Navigation/ic_check"
          >
            <polygon
              id="🎨Icon-Color"
              points="6.75 12.1275 3.6225 9 2.5575 10.0575 6.75 14.25 15.75 5.25 14.6925 4.1925"
            ></polygon>
          </g>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <circle
            id="Oval"
            stroke="#F4F4F4"
            strokeWidth="2"
            fill="#FFFFFF"
            cx="12"
            cy="12"
            r="12"
          ></circle>
          <text
            id="Step-Number"
            fontFamily="UHC2020Sans-Bold, UHC2020 Sans"
            fontSize="16"
            fontWeight="bold"
            fill="#5A5A5A"
          >
            <tspan x="7.52" y="17">
              {displayNumber}
            </tspan>
          </text>
        </React.Fragment>
      );
    }
  }
}
