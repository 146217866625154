/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect } from "react";
import trackPageLoad from "./trackPageLoad";
import TrackingContext from "./TrackingContext";
import { PageLoadTrackData } from "./types";

const usePageLoadTracking = (
  pageLoadTrackingData: PageLoadTrackData | undefined
) => {
  const trackingContext = useContext(TrackingContext);

  useEffect(() => {
    if (trackingContext && pageLoadTrackingData) {
      trackPageLoad(trackingContext, pageLoadTrackingData);
    }
  }, [JSON.stringify(trackingContext), JSON.stringify(pageLoadTrackingData)]);
};

export default usePageLoadTracking;
