import * as React from "react";

/**
 * A flex utility component that provides right, left, and center align.
 */
class AuthNavLevel extends React.PureComponent<
  React.HTMLProps<HTMLDivElement>
> {
  public static displayName = "AuthNav.Level";

  public render() {
    const { children, ...rest } = this.props;
    return (
      <div className="s-level" {...rest}>
        {children}
      </div>
    );
  }
}

export default AuthNavLevel;
