import * as React from "react";
import classNames from "classnames";
import { FormElementProps } from "../formTypes";

export interface TextareaProps extends FormElementProps {
  state?: "default" | "error" | "disabled";
  ariaDescribedBy?: string;
}

export type TextareaType = React.FunctionComponent<
  TextareaProps & React.HTMLProps<HTMLTextAreaElement>
>;

/**
 * This is an Textarea
 * @param {TextareaProps} props
 */
const Textarea: TextareaType = (props) => {
  const {
    className,
    groupIdentifier,
    state,
    ariaDescribedBy,
    id,
    ...other
  } = props;

  let textareaClasses = classNames({
    "tds-form__textarea": true,
    "tds-form__textarea--disabled": state === "disabled",
    "tds-form__textarea--error": state === "error",
  });

  textareaClasses = className
    ? textareaClasses.concat(" ", className)
    : textareaClasses;

  return (
    <textarea
      id={groupIdentifier?.getId() || id}
      className={textareaClasses}
      disabled={state === "disabled" ? true : undefined}
      aria-invalid={state === "error" ? "true" : "false"}
      aria-describedby={ariaDescribedBy}
      {...other}
    />
  );
};

Textarea.defaultProps = {} as Partial<
  TextareaProps & React.HTMLProps<HTMLTextAreaElement>
>;

export default Textarea;
