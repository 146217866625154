import * as React from "react";
import UtilityIcon from "../icon/UtilityIcon";
import ChevronRight from "@material-ui/icons/ChevronRight";
import classNames from "classnames";
import BreadcrumbContext from "./BreadcrumbContext";

export interface BreadcrumbProps {}

export type BreadcrumbType = React.FunctionComponent<
  BreadcrumbProps & React.HTMLProps<HTMLLIElement>
>;

const Breadcrumb: BreadcrumbType = ({ children, className, href, ...rest }) => {
  const context = React.useContext(BreadcrumbContext);
  const { color } = context;

  let breadcrumbListItemClassNames = classNames({
    "tds-breadcrumbs__listitem": true,
    "tds-breadcrumbs__active": href === undefined,
  });

  breadcrumbListItemClassNames = className
    ? breadcrumbListItemClassNames.concat(" ", className)
    : breadcrumbListItemClassNames;

  return (
    <li className={breadcrumbListItemClassNames} {...rest}>
      {href !== undefined ? (
        <React.Fragment>
          <a href={href} className="tds-breadcrumbs__link">
            <span className="tds-breadcrumbs__link-text">{children}</span>
          </a>
          <UtilityIcon
            size="small"
            className="tds-breadcrumbs__chevron"
            icon={ChevronRight}
            color={color === "primary" ? "gray" : "primaryLight"}
          />
        </React.Fragment>
      ) : (
        children
      )}
    </li>
  );
};

export default Breadcrumb;
