// @flow

import { datadogRum } from '@datadog/browser-rum';
import { APPLICATION_ID, CLIENT_TOKEN, ENV} from './config';

export const initializeDatadog = async () => {
  console.log("APPLICATION_ID:::::"+APPLICATION_ID);
  console.log("CLIENT_TOKEN:::::"+CLIENT_TOKEN);
  console.log("ENV ::::"+ENV);

  datadogRum.init({
    applicationId: APPLICATION_ID,
    clientToken: CLIENT_TOKEN,
    env: ENV,
    site: 'datadoghq.com',
    sampleRate: 100,
    trackInteractions: true,
    trackSessionAcrossSubdomains: true,
    useSecureSessionCookie: true,
    useCrossSiteSessionCookie: true,
    sampleRate: 100,
    replaySampleRate: 100
  });
  datadogRum.startSessionReplayRecording();
};
