import * as React from "react";

const AuthNavModalContent: React.FC<
  { labeledBy?: any; handleClose?: () => void } & React.HTMLProps<
    HTMLDivElement
  >
> = ({ children, handleClose, labeledBy, ...rest }) => (
  <div className="s-modal-content" {...rest}>
    {children}
  </div>
);

AuthNavModalContent.displayName = "AuthNav.ModalContent";

export default AuthNavModalContent;
