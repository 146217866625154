import { track } from "./index";
import { ClickTrackData, AppTrackData } from "./types";

// This should be used as a fallback if useClickTracking is not available.
// Forced to provide app context as well as the clickTrackData.
const trackClick = (context: AppTrackData, clickTrackData?: ClickTrackData) => {
  if (clickTrackData) {
    const trackingData = {
      ...context,
      ...clickTrackData,
    };
    track({ data: trackingData, event: "clickEvent" });
  }
};

export default trackClick;
