/// <reference path="./datepicker.d.ts" />

import { dayNames } from "./datepicker.const";

export const monthTwoDigits = /(0[123456789]|1[012])/; // 01, 02, 03, 04, 05 , 06, 07, 08, 09, 10, 11, 12
export const monthDaySingleDigit = /(0[123456789]|1[012])[0-3]/; // xx/0, xx/1, xx/2, xx/3
export const monthDayTwoDigits = /((0[13578]|1[02])(0[1-9]|[12][0-9]|3[01])|(0[469]|11)(0[1-9]|[12][0-9]|30)|(02)(0[1-9]|1[0-9]|2[0-9]))/; // xx/01, xx/11, xx/21, 01/31, 02/29, 03/31, 04/30
export const monthDayYearSingleDigit = /((0[13578]|1[02])(0[1-9]|[12][0-9]|3[01])|(0[469]|11)(0[1-9]|[12][0-9]|30)|(02)(0[1-9]|1[0-9]|2[0-9]))[12]/;
export const monthDayYearSingleTwoDigits = /((0[13578]|1[02])(0[1-9]|[12][0-9]|3[01])|(0[469]|11)(0[1-9]|[12][0-9]|30)|(02)(0[1-9]|1[0-9]|2[0-9]))(18|19|20|21)/;
export const monthDayYearSingleThreeDigits = /((0[13578]|1[02])(0[1-9]|[12][0-9]|3[01])|(0[469]|11)(0[1-9]|[12][0-9]|30)|(02)(0[1-9]|1[0-9]|2[0-9]))(18|19|20|21)[0-9]/;
export const monthDayYearSingleFourDigits = /((0[13578]|1[02])31([0-9])[0-9]{3})|((01|0[3-9]|1[1-2])(29|30)([0-9])[0-9]{3})|((0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-8])([0-9])[0-9]{3})|((02)29(((19|20)(04|08|[2468][048]|[13579][26]))|2000))/;

export const isValidDate = (d: ReadableYearMonthDay) => {
  return monthDayYearSingleFourDigits.test(
    `${padDayOrMonth(d[1])}${padDayOrMonth(d[2])}${d[0]}`
  );
};

export const isEqualDate = (
  date1?: ReadableYearMonthDay | ReadableYearMonth | any[],
  date2?: ReadableYearMonthDay | ReadableYearMonth | any[]
) => {
  if (!date1 || !date2) {
    return false;
  }
  return (
    date1[0] === date2[0] && date1[1] === date2[1] && date1[2] === date2[2]
  );
};

const getDaysInMonth = (yearAndMonth: ReadableYearMonth) => {
  const [year, month] = yearAndMonth;
  var monthIndex = month - 1;
  var date = new Date(year, monthIndex, 1);
  var result = [];
  while (date.getMonth() === monthIndex) {
    result.push({ date: date.getDate(), day: dayNames[date.getDay()] });
    date.setDate(date.getDate() + 1);
  }
  return result;
};

// Splits the weeks of the month into an array that can be mapped to the DatePicker calendar rows
export const splitWeeksInMonth = (yearAndMonth: ReadableYearMonth) => {
  const days = getDaysInMonth(yearAndMonth);
  const startOfWeek = dayNames.indexOf(days[0].day);
  const months: any = [];
  let from = 0;
  let to = 7 - startOfWeek;
  while (
    months.reduce(
      (accumulator: number, currentValue: []) =>
        accumulator + currentValue.length,
      0
    ) < days.length
  ) {
    months.push(days.slice(from, to));
    from = to;
    to = from + 7;
  }
  // Fill in the beginning of the first week of the month
  for (var i = months[0].length; i < 7; i++) {
    months[0].unshift(undefined);
  }
  return months;
};

export const getPrevYearAndMonth = (
  yearAndMonth: ReadableYearMonth
): ReadableYearMonth => {
  const [year, month] = yearAndMonth;
  const prevMonth = month === 1 ? 12 : month - 1;
  const prevYear = month === 1 ? year - 1 : year;
  return [prevYear, prevMonth as ReadableMonth]; // i.e., [2019, 6]
};

export const getNextYearAndMonth = (
  yearAndMonth: ReadableYearMonth
): ReadableYearMonth => {
  const [year, month] = yearAndMonth;
  const nextMonth = month === 12 ? 1 : month + 1;
  const nextYear = month === 12 ? year + 1 : year;
  return [nextYear, nextMonth as ReadableMonth];
};

export const getYears = (startYear: number, endYear: number) => {
  const years = [];
  while (startYear <= endYear) {
    years.push(startYear++);
  }
  return years;
};

export const isDateWithinRange = (d: Date, min: Date, max: Date) =>
  d > min && d < max;

export const padDayOrMonth = (dm: ReadableMonth | DayOfMonth) => {
  if (dm < 10) {
    return `0${dm}`;
  } else {
    return `${dm}`;
  }
};

export const cleanDate = (d: string) => {
  // Check if the value contains anything other than a number
  if (!/^[0-9]+$/.test(d)) {
    // Split the value at any delimiter
    const splitDate = d.trim().split(/[^0-9]/);

    // Pad the day
    if (splitDate[0]) {
      splitDate[0] = splitDate[0].padStart(2, "0");
    }

    // Pad the month
    if (splitDate[1]) {
      splitDate[1] = splitDate[1].padStart(2, "0");
    }

    // Put the date back together
    d = splitDate.join("");
  }

  return d;
};

// Checks for a date that could be valid as the user types it in.
// For example: 0, 02, 02-, 2-0, 3, 3-, 2-2, 2-20, 2-29-202, 2-29-2020, but not 2-30, 4-31, 2-29-2021
export const validateDate = (d: string) => {
  let value = d;

  value = cleanDate(d);

  if (value.length === 2 && !monthTwoDigits.test(value)) {
    return false;
  }

  if (value.length === 3 && !monthDaySingleDigit.test(value)) {
    return false;
  }

  if (value.length === 4 && !monthDayTwoDigits.test(value)) {
    // Note: At this point something like 2-0 shows invalid (when a user prefixes the month, but not the day).
    // If the user continues on to enter a valid date such as: 2-02-2020, we just use it and forget about the odd d/mm/yyyy format.
    return false;
  }

  if (value.length === 5 && !monthDayYearSingleDigit.test(value)) {
    return false;
  }

  if (value.length === 6 && !monthDayYearSingleTwoDigits.test(value)) {
    return false;
  }

  if (value.length === 7 && !monthDayYearSingleThreeDigits.test(value)) {
    return false;
  }

  if (value.length === 8 && !monthDayYearSingleFourDigits.test(value)) {
    return false;
  }

  if (value.length > 8) {
    return false;
  }

  // Looks good so far!
  return true;
};

export const validateFullDate = (d: string | undefined) => {
  if (!d) {
    return false;
  }
  const value = cleanDate(d);
  if (value.length === 8 && monthDayYearSingleFourDigits.test(value)) {
    return true;
  }
  return false;
};

export const formatFullDate = (d: string) => {
  let value = cleanDate(d);
  return `${value.slice(0, 2)}/${value.slice(2, 4)}/${value.slice(4, 8)}`;
};
