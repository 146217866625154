import * as React from "react";
import classNames from "classnames";
import { FormElementProps } from "../formTypes";

const { useRef } = React;

export interface InputProps extends FormElementProps {
  iconLeft?: any;
  iconRight?: any;
  state?: "default" | "error" | "disabled";
  hasInstruction?: boolean;
  hasErrorMessage?: boolean;
  ariaDescribedBy?: string;
}

export type InputType = React.FunctionComponent<
  InputProps & React.HTMLProps<HTMLInputElement>
>;

/**
 * This is an Input
 * @param {InputProps} props
 */
const Input: InputType = (props) => {
  const {
    className,
    groupIdentifier,
    state,
    iconLeft,
    iconRight,
    hasInstruction,
    hasErrorMessage,
    ariaDescribedBy,
    id,
    ...other
  } = props;

  const inputRef = useRef<HTMLInputElement>(null);

  let inputClasses = classNames({
    "tds-form__input": true,
    "tds-form__input--disabled": state === "disabled",
    "tds-form__input--error": state === "error",
    "tds-form__input--icon-left": iconLeft && !iconRight,
    "tds-form__input--icon-right": iconRight && !iconLeft,
    "tds-form__input--icon-both": iconRight && iconLeft,
  });

  inputClasses = className ? inputClasses.concat(" ", className) : inputClasses;

  const inputComponent = (
    <input
      ref={inputRef}
      id={groupIdentifier?.getId() || id}
      className={inputClasses}
      type="text"
      disabled={state === "disabled" ? true : false}
      aria-invalid={state === "error" ? "true" : "false"}
      aria-describedby={ariaDescribedBy}
      {...other}
    />
  );

  // TODO: This will need a javascript equivalent for the HTML/CSS Storybook repo
  const addFocus = () => {
    const inputElement = inputRef.current;
    if (inputElement !== null) {
      inputElement.focus();
    }
  };

  if (iconLeft || iconRight) {
    return (
      <div className="tds-form__input-icon-wrapper">
        {inputComponent}
        {iconLeft && (
          <span
            className="tds-form__icon-append tds-form__icon-append--left"
            onClick={addFocus}
          >
            {iconLeft}
          </span>
        )}
        {iconRight && (
          <span
            className="tds-form__icon-append tds-form__icon-append--right"
            onClick={addFocus}
          >
            {iconRight}
          </span>
        )}
      </div>
    );
  } else {
    return <React.Fragment>{inputComponent}</React.Fragment>;
  }
};

Input.defaultProps = {} as Partial<InputProps>;

export default Input;
