import * as React from "react";
import classNames from "classnames";

export interface ModalContentProps {}

export type ModalContentType = React.FunctionComponent<
  ModalContentProps & React.HTMLProps<HTMLDivElement>
>;

/**
 * This is ModalContent
 * @param {ModalContentProps} props
 */

const ModalContent: ModalContentType = (props) => {
  const { children, className } = props;

  let contentClasses = classNames({
    "tds-modal__content": true,
  });

  contentClasses = className
    ? contentClasses.concat(" ", className)
    : contentClasses;

  return <div className={contentClasses}>{children}</div>;
};

export default ModalContent;
