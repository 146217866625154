import * as React from "react";
import Typography from "../typography/Typography";
import classNames from "classnames";

export interface CardProps {
  /**
   * Determines the state and position of the content being implemented inside the card */
  cardType: "background" | "block";
  /**
   * Custom color */
  backgroundColor?: "primary" | "secondary" | "high-impact";
  /**
   * Optional light accent border around card */
  border?: boolean;
  /**
   * Image included for the content block */
  contentBlockImage?: string;
  /**
   * Descriptive text that serves as an alternative of the image */
  alt?: string;
  /**
   * Text used for the heading, introduction to content */
  header?: string;
  /**
   * Text used for the subheading, article byline and/or description of content */
  subheader?: string;
  /**
   * Determines whether certain layout properties are applied (width, height, etc.)
   */
  layout?: "custom" | "default" | "side-banner";

  [x: string]: any;
}

/**
 * This is a Card
 * @param {CardProps} props
 */

const Card: React.FunctionComponent<CardProps> = (props) => {
  const {
    cardType,
    backgroundColor,
    border,
    contentBlockImage,
    alt,
    header,
    subheader,
    children,
    className,
    layout,
  } = props;

  let cardClasses = classNames({
    "tds-card": true,
    "tds-card--default-width": layout === "default",
    "tds-size-fill-container": layout === "side-banner",
    "tds-card--primary": backgroundColor === "primary" && cardType !== "block",
    "tds-card--secondary":
      backgroundColor === "secondary" && cardType !== "block",
    "tds-card--high-impact":
      backgroundColor === "high-impact" && cardType !== "block",
    "tds-card--block": cardType === "block",
    "tds-card--border": border,
  });

  // Allow user to add there own classes to the base card div
  cardClasses = className ? cardClasses.concat(" ", className) : cardClasses;

  const cardContainerClasses = classNames({
    "tds-card__content": true,
    "tds-card__content--border": border,
  });

  return (
    <React.Fragment>
      <div className={cardClasses}>
        {contentBlockImage && cardType === "block" && (
          <img className="tds-card__image" src={contentBlockImage} alt={alt} />
        )}
        <div className={cardContainerClasses}>
          {header && (
            <Typography.H2 className="tds-card__header tds-margin-none">
              {header}
            </Typography.H2>
          )}
          {subheader && (
            <Typography.Paragraph className="tds-margin-sm-top-only">
              {subheader}
            </Typography.Paragraph>
          )}
          {children}
        </div>
      </div>
    </React.Fragment>
  );
};

Card.defaultProps = {
  cardType: "background",
  backgroundColor: "primary",
  layout: "default",
} as Partial<CardProps>;

export default Card;
