import * as React from "react";

export interface StepAssistiveTextProps {
  stepsComplete?: number;
  maxSubSteps: number;
  active?: boolean;
}

export default class StepAssistiveText extends React.PureComponent<
  StepAssistiveTextProps
> {
  render() {
    const { stepsComplete, maxSubSteps, active } = this.props;

    const statuses = ["Not Started", "In progress", "Completed"];
    let status = "";
    let displaySteps;

    if (!active) {
      status = statuses[0];
      displaySteps = 0;
    } else if (typeof stepsComplete === "number") {
      status = stepsComplete === maxSubSteps ? statuses[2] : statuses[1];

      displaySteps =
        stepsComplete < maxSubSteps ? stepsComplete + 1 : stepsComplete;
    }

    return (
      <p className="tds-sr-only">
        {`${displaySteps} of ${maxSubSteps} steps. ${status}.`}
      </p>
    );
  }
}
