import React, { useState, useEffect } from "react";
import AccordionHeader from "./AccordionHeader";
import AccordionContent from "./AccordionContent";
import { AccordionProps } from "./Accordion";
import { useUniqueId } from "../../hooks";
import { ACCORDION_CONTENT_KEY } from "./accordion.const";
import { UniqueIdentifier } from "hooks/uniqueIdentifier";

// ================================================================================================
//
//                INNER ACCORDION
//
// ================================================================================================

interface InnerAccordionProps extends AccordionProps {
  accordionIdentifier: UniqueIdentifier;
  /**
   * Determines the color variant of the accordion
   * @memberof InnerAccordionProps
   */
  backgroundColor?: "primary" | "secondary";
  /**
   * Header level for overriding the default header level of 4 (h4)
   * @deprecated
   * @memberof InnerAccordionProps
   */
  headingLevel?: 1 | 2 | 3 | 4 | 5 | 6;
  isOpen?: boolean;
  index: number;
  /**
   * Call back function for accordion header button click
   */
  onAccordionToggle?: (
    event: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>,
    isToggleOpen: boolean,
    index: number
  ) => void;

  activeTab?: number;
}

const InnerAccordion: React.FunctionComponent<InnerAccordionProps> = (
  props
) => {
  const {
    title,
    children,
    accordionIdentifier,
    backgroundColor,
    isOpen = false,
    onAccordionToggle = () => {},
    index,
    activeTab,
  } = props;
  const [isToggleOpen, setToggleOpen] = useState(isOpen);
  const openState = isToggleOpen ? "open" : "closed";

  //======================= Hooks ============================

  useEffect(() => {
    if (activeTab !== undefined && activeTab !== index && isToggleOpen) {
      setToggleOpen(false);
    }
  }, [activeTab, index, isToggleOpen]);

  useEffect(() => {
    const animated = document.getElementById(
      accordionIdentifier.getChildId(ACCORDION_CONTENT_KEY)
    );

    if (!animated) {
      return;
    }
    const handler = (ev: AnimationEvent) => {
      if (ev.animationName === "slideUp") {
        animated.classList.add("tds-accordion-content__container--hide");
      }
    };
    animated.addEventListener("animationend", handler);

    return () => {
      animated.removeEventListener("animationend", handler);
    };
  }, [isToggleOpen, accordionIdentifier]);

  const onToggle = (
    event: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>
  ) => {
    setToggleOpen(!isToggleOpen);
    onAccordionToggle(event, !isToggleOpen, index);
  };

  //======================= Render ============================

  return (
    <li
      className={`tds-accordion__container tds-accordion__container--${openState}`}
      data-content-id={accordionIdentifier.getId()}
    >
      <AccordionHeader
        isOpen={isToggleOpen}
        onToggleAccordion={onToggle}
        accordionIdentifier={accordionIdentifier}
        backgroundColor={backgroundColor}
      >
        {title}
      </AccordionHeader>
      <AccordionContent
        isOpen={isToggleOpen}
        accordionIdentifier={accordionIdentifier}
      >
        {children}
      </AccordionContent>
    </li>
  );
};

// ================================================================================================
//
//                ACCORDION GROUP
//
// ================================================================================================

export interface AccordionGroupProps {
  /**
   * Determines the color variant of the accordion
   * @memberof AccordionGroupProps
   */
  backgroundColor?: "primary" | "secondary";
  /**
   * Currently open tab/accordion (starts at 1)
   * @ignore
   */
  activeTab?: number;
  /**
   * Call back function for accordion header button click
   */
  onAccordionToggle?: (
    event: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>,
    toggleState: boolean,
    index: number
  ) => void;

  /**
   * <span style="color: #c40000;">**Deprecated**</span>
   * Header level for overriding the default header level of 4 (h4)
   * @deprecated
   * @memberof AccordionGroupProps
   */
  headingLevel?: 1 | 2 | 3 | 4 | 5 | 6;
  /**
   * <span style="color: #c40000;">**Deprecated**</span>
   *
   * Unique key to ensure we keep uniqueness across different accordion groups if they are on the same page.
   * This will be used to create all the id's needed for the content and accessibility requirements.
   * @deprecated
   * @memberof AccordionGroupProps
   */
  contentId?: number;
}

/**
 * An Accordion Group for managing displaying accordion content.
 *
 * @param {AccordionProps} props
 */
const AccordionGroup: React.FunctionComponent<
  AccordionGroupProps & React.HTMLAttributes<HTMLUListElement>
> = (props) => {
  const {
    children,
    contentId,
    backgroundColor,
    activeTab,
    className,
    onAccordionToggle,
    id
  } = props;
  const accordionIdentifier = useUniqueId("accordion-group", contentId);
  if (!Array.isArray(children) || children.length < 2) {
    console.warn("Accordion Groups must have at least 2 children");
  }

  const mutatedChildren = React.Children.toArray(children)
    .filter(
      (child: any) => child.props && child.props.title && child.props.children
    )
    .map((child: any, index: number) => {
      const unique = accordionIdentifier.getDerivedIdentifier(
        "accordion",
        index
      );
      return (
        <InnerAccordion
          {...child.props}
          accordionIdentifier={unique}
          key={unique.getId()}
          isOpen={activeTab === index}
          backgroundColor={backgroundColor}
          onAccordionToggle={onAccordionToggle}
          index={index}
          activeTab={activeTab}
        />
      );
    });

  return (
    <ul
      id={id ? id : accordionIdentifier.getChildId("group")}
      aria-label="Accordion Control Group"
      className={`tds-accordion__group tds-accordion__group--${backgroundColor} ${className}`}
    >
      {mutatedChildren}
    </ul>
  );
};

AccordionGroup.defaultProps = {
  backgroundColor: "primary",
};

export default AccordionGroup;
