import { UniqueIdentifier } from "hooks/uniqueIdentifier";
import * as React from "react";

const MODAL_CLOSE_COMPONENT_KEY = "close";

const ModalClose = (
  props: React.SVGProps<SVGSVGElement> & { modalIdentifier: UniqueIdentifier }
) => {
  const { className, modalIdentifier, ...rest } = props;
  const id = modalIdentifier.getChildId(MODAL_CLOSE_COMPONENT_KEY);
  return (
    <svg
      width={14}
      height={14}
      viewBox="0 0 14 14"
      role="presentation"
      className={`tds-modal__close--icon${className ? ` ${className}` : ""}`}
      {...rest}
    >
      <title>Close modal</title>
      <defs>
        <rect id={id} x={0} y={0} width={14} height={14} rx={8} />
      </defs>
      <g fill="none" fillRule="evenodd">
        <path
          d="M14 1.41L12.59 0 7 5.59 1.41 0 0 1.41 5.59 7 0 12.59 1.41 14 7 8.41 12.59 14 14 12.59 8.41 7z"
          fill="#196ECF"
        />
      </g>
    </svg>
  );
};

export default ModalClose;
