import * as React from "react";
import classNames from "classnames";
import { useRef, useEffect, useState } from "react";
import { UniqueIdentifier } from "hooks/uniqueIdentifier";

export interface PaginationDropdownProps {
  isOpen?: boolean;
  altTitle: string;
  page: number;
  maxPage: number;
  closeDropdown: () => void;
  onPageSelection: (
    event: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>,
    page: number,
    enterPress?: boolean
  ) => void;
  /**
   * Parent unique id for pagination
   */
  groupIdentifier: UniqueIdentifier;
  changeFocusId?: string;
  pageNumbers: number[];
}

/**
 * Pagination Navigation Button (PNB)
 * @param {PaginationDropdownProps} props
 */
const PaginationDropdown: React.FunctionComponent<
  PaginationDropdownProps & React.HTMLProps<HTMLUListElement>
> = (props) => {
  const {
    className,
    isOpen,
    altTitle,
    page,
    maxPage,
    onPageSelection,
    closeDropdown,
    groupIdentifier,
    changeFocusId,
    pageNumbers,
    ...other
  } = props;

  // Set up ref
  const optionRef = useRef(new Array(maxPage));
  // Set up state
  const [focusedPage, setFocusedPage] = useState(page);

  //Check if exited by enter key
  let enterKeyPressed = false;

  const dropdownElement = document.getElementById(
    groupIdentifier.getChildId("select")
  );
  const nextButtonElement = document.getElementById(
    groupIdentifier.getChildId("next-button")
  );

  const previousButtonElement = document.getElementById(
    groupIdentifier.getChildId("previous-button")
  );

  //Set up listeners
  const setUpEventListeners = (event: KeyboardEvent) => {
    if (event.key === "ArrowDown") {
      if (focusedPage + 1 <= maxPage) {
        setFocusedPage(focusedPage + 1);
      }
    } else if (event.key === "ArrowUp") {
      if (focusedPage - 1 > 0) {
        setFocusedPage(focusedPage - 1);
      }
    }
  };

  const optionKeyPress = (
    event: React.KeyboardEvent<HTMLElement>,
    page: number
  ) => {
    if (isOpen === false) return;

    event.preventDefault();

    if (event.shiftKey && event.key === "Tab") {
      setFocusedPage(page);
      previousButtonElement?.focus();
      closeDropdown();
    } else if (event.key === "Tab") {
      setFocusedPage(page);
      nextButtonElement?.focus();
      closeDropdown();
    } else if (event.key === "Enter") {
      onPageSelection(event, page, true);
    } else if (event.key === "Escape") {
      setFocusedPage(page);
      dropdownElement?.focus();
      closeDropdown();
    }
  };

  // Set up classes and class logic
  let PaginationDropdownClasses = classNames({
    "tds-pagination__dropdown": true,
  });

  PaginationDropdownClasses = className
    ? PaginationDropdownClasses.concat(" ", className)
    : PaginationDropdownClasses;

  //======================= Hooks ============================

  useEffect(() => {
    document.addEventListener("keydown", setUpEventListeners);

    return function cleanup() {
      document.removeEventListener("keydown", setUpEventListeners);
    };
  });

  useEffect(() => {
    setFocusedPage(page);
  }, [page]);

  useEffect(() => {
    if (isOpen === true) {
      optionRef.current[focusedPage].focus();
    }
  }, [isOpen, focusedPage, enterKeyPressed]);

  return (
    <div>
      <ul
        id="pagination-dropdown"
        tabIndex={-1}
        role="listbox"
        className={PaginationDropdownClasses}
        {...other}
      >
        {pageNumbers.map((currentPage) => {
          return (
            <li
              key={currentPage}
              className={`tds-pagination__dropdown-list-option ${
                page === currentPage
                  ? "tds-pagination__dropdown-list-option--active"
                  : ""
              }`}
              role="option"
              aria-selected={page === currentPage}
              onClick={(event) => {
                onPageSelection(event, currentPage);
              }}
              onKeyDown={(event) => optionKeyPress(event, currentPage)}
              ref={(element) => (optionRef.current[currentPage] = element)}
              tabIndex={-1}
            >
              {altTitle} {currentPage}
            </li>
          );
        })}
      </ul>
    </div>
  );
};

PaginationDropdown.defaultProps = {
  isOpen: false,
  altTitle: "Page",
  page: 1,
  maxPage: 1,
} as Partial<PaginationDropdownProps>;

export default PaginationDropdown;
