import * as React from "react";

const AuthNavModalClose: React.FC<
  { labeledBy?: any; handleClose?: () => void } & React.HTMLProps<
    HTMLDivElement
  >
> = ({ children, handleClose, labeledBy, ...rest }) => (
  <div className="s-modal-close" {...rest}>
    {children}
  </div>
);

AuthNavModalClose.displayName = "AuthNav.ModalClose";

export default AuthNavModalClose;
