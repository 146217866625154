import $ from "jquery";

const PreLoginApi = (url,preLoginUrl) => {
    
    return new Promise((resolve, reject) => {
        $.ajax({
            url: preLoginUrl,
            xhrFields: {
      withCredentials: true
   }, 
            type: "POST",
            dataType: "json",
            crossDomain: true,
            contentType: "application/json; charset=utf-8",
            headers: {
                "Content-Type": "application/json; charset=utf-8",
                "Accept": "application/json",
            },
            data: JSON.stringify({ redirectUrl: url }),
            success: function (result) {
                resolve(result);
            },
            error: function (error) {
                console.log("Error: " + JSON.stringify(error));
                reject(error);
            }
        });
    })
}

export default PreLoginApi;