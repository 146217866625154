import { useUniqueId } from "../../hooks";
import * as React from "react";

export interface BrandIconProps {
  /**
   * Icon to render
   */
  icon: (props: React.SVGProps<SVGSVGElement>) => JSX.Element;
  /**
   * When passed in creates a \<title\> element within the SVG.
   * The \<title\> element provides an accessible, short-text description of any SVG container element or graphics element.
   */
  title?: string;
  /**
   * When passed in creates a \<desc\> element within the SVG.
   * The \<desc\> element provides an accessible, long-text description of any SVG container element or graphics element.
   */
  description?: string;
  /**
   * Size to render the SVG element
   * Conforms to expected sizes for utility icons for the design system.
   */
  size?: "EXTRA_SMALL" | "SMALL" | "MEDIUM" | "LARGE";
  /**
   * Style variations that map to layout and color combinations for the icon.
   */
  styleVariation?:
    | "ONE_TONE"
    | "ONE_TONE_DARK_CIRCLE"
    | "TWO_TONE"
    | "TWO_TONE_LIGHT_CIRCLE"
    | "TWO_TONE_DARK_CIRCLE";
  [x: string]: any;
}

const STYLE_VARIATIONS = {
  ONE_TONE: "one-tone",
  ONE_TONE_DARK_CIRCLE: "one-tone-circle",
  TWO_TONE: "two-tone-circle-white",
  TWO_TONE_LIGHT_CIRCLE: "two-tone-circle-white",
  TWO_TONE_DARK_CIRCLE: "two-tone-circle",
};

// Brand icons will have Extra Small - 48 / Small - 60 / Medium - 72 / Large - 84)
const SIZE = {
  EXTRA_SMALL: "extra-small",
  SMALL: "small",
  MEDIUM: "medium",
  LARGE: "large",
};

/**
 * Utility icon
 * - A helper component wrapper for material icons to ensure they are used correctly within the context of
 *
 * @param {BrandIconProps} props
 * @return {React.FunctionComponent<BrandIconProps>}
 */
const BrandIcon: React.FunctionComponent<BrandIconProps> = (props) => {
  const {
    icon,
    title,
    description,
    size = "medium",
    styleVariation = "ONE_TONE",
    className,
  } = props;
  const IconSvg = icon;
  const iconIdentifier = useUniqueId("brand-icon");
  if (!icon) {
    console.warn("An svg icon must be included in the icon prop");
    return null;
  }
  const sizeName = SIZE[size] || SIZE.MEDIUM;
  const titleId = title && iconIdentifier.getChildId("title");
  const descId = description && iconIdentifier.getChildId("desc");
  if (styleVariation === "TWO_TONE") {
    console.warn(
      'The styleVariation "TWO_TONE" is deprecated.  Replacing with "TWO_TONE_LIGHT_CIRCLE".'
    );
  }
  const classes = [
    `tds-icon--${sizeName}`,
    `${STYLE_VARIATIONS[styleVariation]}`,
  ];
  if (className) {
    classes.push(className);
  }

  return (
    <IconSvg
      className={classes.join(" ")}
      aria-hidden={title || description ? undefined : "true"}
      focusable={title || description ? undefined : "false"}
      aria-labelledby={
        title || description
          ? [titleId, descId].filter((x) => !!x).join(" ")
          : undefined
      }
      role={title || description ? undefined : "img"}
    >
      {title && <title id={titleId}>{title}</title>}
      {description && <desc id={descId}>{description}</desc>}
    </IconSvg>
  );
};

BrandIcon.defaultProps = {} as Partial<BrandIconProps>;

export default BrandIcon;
