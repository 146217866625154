/* eslint-disable react/display-name */
/* eslint-disable max-classes-per-file */
import * as React from "react";

export interface AuthNavModalProps {
  /** A handler to close the modal. It will be passed to the backdrop and the close button. */
  handleClose: () => void;
  /** An option to display the modal as a drawer on the left/right of the page. */
  drawer?: "right" | "left";
  focusRef?: React.RefObject<HTMLElement> | null;
  mode?: "full-screen";

  // HTML Props
  /** Props passed to the Modal's overlay. */
  overlayProps?: React.HTMLProps<HTMLDivElement>;
  /** Props passed to the Modal's container */
  modalContainerProps?: React.HTMLProps<HTMLDivElement>;
}

interface ModalState {
  mounted: boolean;
}

export interface ConditionalFocusTrapProps {
  condition: boolean;
  children: React.ReactElement | React.ReactElement[];
}

/**
 * An modal + overlay component with drawer display options.
 */
export class AuthNavModal extends React.PureComponent<
  AuthNavModalProps & React.HTMLProps<HTMLDivElement>,
  ModalState
> {
  public static displayName = "Nav.Modal";

  private modalRef = React.createRef<HTMLDivElement>();

  private focusFallbackRef = React.createRef<HTMLDivElement>();

  private previousOverflow: string | null = "";

  private previouslyFocusedElement: HTMLElement | null = null;

  constructor(props: AuthNavModalProps & React.HTMLProps<HTMLDivElement>) {
    super(props);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleOutsideClick = this.handleOutsideClick.bind(this);
    // this.modalId = 'portal-1';
    this.handleClose = this.handleClose.bind(this);
    this.state = {
      mounted: false,
    };
  }

  public componentDidMount() {
    const { focusRef } = this.props;
    document.addEventListener("keydown", this.handleKeyDown);
    this.previousOverflow = document.body.style.overflow;
    document.body.style.overflow = "hidden";
    this.previouslyFocusedElement = document.activeElement as HTMLElement;

    try {
      setTimeout(() => {
        if (focusRef && focusRef.current !== null) {
          focusRef.current.focus();
        }
        if (
          focusRef &&
          this.focusFallbackRef &&
          this.focusFallbackRef.current !== null
        ) {
          this.focusFallbackRef.current.focus();
        }
        this.setState({ mounted: true });
      }, 150);
      // eslint-disable-next-line no-empty
    } catch (e) {}
  }

  public componentWillUnmount() {
    document.removeEventListener("keydown", this.handleKeyDown);
    document.body.style.overflow =
      this.previousOverflow !== null ? this.previousOverflow : "visible";
    try {
      if (this.previouslyFocusedElement !== null) {
        this.previouslyFocusedElement.focus();
      }
      // eslint-disable-next-line no-empty
    } catch (e) {}
  }

  private handleOutsideClick(e: React.MouseEvent) {
    if (this.modalRef !== undefined && this.modalRef.current !== null) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      if (!this.modalRef.current.contains((e as any).target)) {
        this.handleClose();
      }
    }
  }

  private handleKeyDown(e: KeyboardEvent) {
    if (e.key === "Escape") {
      this.handleClose();
    }
  }

  private handleClose() {
    const { handleClose } = this.props;
    handleClose();
  }

  public render() {
    const {
      children,
      drawer,
      mode,
      overlayProps,
      modalContainerProps,
      handleClose,
      focusRef,
      ...rest
    } = this.props;
    const { mounted } = this.state;
    const labeledBy = "dialog-1";
    const ariaProps = {
      "aria-modal": true,
      "aria-labelledby": labeledBy,
    };
    if (mounted) {
      return (
        <div ref={this.focusFallbackRef}>
          <div
            className="s-modal-wrapper"
            // eslint-disable-next-line react/jsx-no-bind
            onClick={this.handleOutsideClick}
            data-test-drawer={drawer}
            {...modalContainerProps}
          >
            <div
              className="s-modal"
              ref={this.modalRef}
              role="dialog"
              {...ariaProps}
              {...rest}
            >
              <div className="s-modal-focus">
                {React.Children.map(children, (child: React.ReactNode) =>
                  React.cloneElement(child as React.ReactElement, {
                    handleClose: this.handleClose,
                    labeledBy,
                  })
                )}
              </div>
            </div>
          </div>
          <div
            className="s-modal-backdrop"
            {...overlayProps}
            onClick={this.handleOutsideClick}
          />
        </div>
      );
    }
    return null;
  }
}

export default AuthNavModal;
