import * as React from "react";

const version = "TEMPO_VERSION";

const setVersionOnBody = () => {
  if (typeof window !== "object") {
    return;
  }
  document.body.setAttribute("data-tempo-version", version);
};

let versionSet = false;

/**
 * Use Version, sets the current version in the body for auditing and troubleshooting.
 * NOTE: Really this should be titled setVersion, but needs to start with use for React syntax
 *
 * @return {*}
 */
const useVersion = () => {
  // @ts-ignore
  React.useEffect(() => {
    if (!versionSet) {
      // @ts-ignore
      versionSet = true;
      setVersionOnBody();
    }
    return () => ({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};

export default useVersion;
