import { useContext, useCallback } from "react";
import trackClick from "./trackClick";
import TrackingContext from "./TrackingContext";
import { ClickTrackData } from "./types";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const useClickTracking = (
  onClickTrackingData: ClickTrackData | undefined,
  onClick: any
) => {
  const trackingContext = useContext(TrackingContext);

  return useCallback(
    (e: React.MouseEvent) => {
      if (onClickTrackingData) {
        trackClick(trackingContext, onClickTrackingData);
      }
      if (onClick) {
        onClick(e);
      }
    },
    [trackingContext, onClickTrackingData, onClick]
  );
};

export default useClickTracking;
