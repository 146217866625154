import * as React from "react";
import classNames from "classnames";

export interface TypographyProps {
  /**
   * Determines wether to use the light or dark version
   */
  useLight?: boolean;
  /**
   * Determines wether to use italic or not
   */
  useItalic?: boolean;
}

/**
 * Base Typography Component
 * @param props
 */

const Typography: React.FunctionComponent<
  TypographyProps & React.HTMLAttributes<HTMLElement>
> & {
  H1: typeof HeadingOne;
  H2: typeof HeadingTwo;
  H3: typeof HeadingThree;
  H4: typeof HeadingFour;
  H5: typeof HeadingFive;
  H6: typeof HeadingSix;
  Paragraph: typeof Paragraph;
  Link: typeof Link;
  Footnote: typeof Footnote;
} = (props) => {
  return null;
};

export interface HeadingProps extends TypographyProps {
  /**
   * Sets heading level
   * @ignore
   */
  headingLevel?: 1 | 2 | 3 | 4 | 5 | 6;
  /**
   * Sets heading style (doesn't change semantic HTML underneath, only visual change)
   */
  headingStyle?: 1 | 2 | 3 | 4 | 5 | 6;
}

function renderHeading(
  props: React.PropsWithChildren<
    HeadingProps & React.HTMLAttributes<HTMLHeadingElement>
  >,
  defaultHeadingLevel = 1
) {
  const {
    useLight,
    headingLevel = defaultHeadingLevel,
    headingStyle = defaultHeadingLevel,
    className,
    children,
    useItalic,
    ...other
  } = props;

  const userHeadingStyle = headingStyle ? headingStyle : headingLevel;

  // Set up classes
  let headingClasses = classNames(
    {
      "tds-header__h1": userHeadingStyle === 1,
      "tds-header__h2": userHeadingStyle === 2,
      "tds-header__h3": userHeadingStyle === 3,
      "tds-header__h4": userHeadingStyle === 4,
      "tds-header__h5": userHeadingStyle === 5,
      "tds-header__h6": userHeadingStyle === 6,
      [`tds-header__h${headingLevel}--light`]: useLight,
      "tds-typography--italic": useItalic,
    },
    className
  );

  const Heading = ({ ...props }: React.HTMLAttributes<HTMLHeadingElement>) =>
    React.createElement(`h${headingLevel}`, props, children);

  return (
    <Heading className={headingClasses} {...other}>
      {children}
    </Heading>
  );
}

const HeadingOne: React.FunctionComponent<
  HeadingProps & React.HTMLAttributes<HTMLHeadingElement>
> = (props) => renderHeading(props, 1);
const HeadingTwo: React.FunctionComponent<
  HeadingProps & React.HTMLAttributes<HTMLHeadingElement>
> = (props) => renderHeading(props, 2);
const HeadingThree: React.FunctionComponent<
  HeadingProps & React.HTMLAttributes<HTMLHeadingElement>
> = (props) => renderHeading(props, 3);
const HeadingFour: React.FunctionComponent<
  HeadingProps & React.HTMLAttributes<HTMLHeadingElement>
> = (props) => renderHeading(props, 4);
const HeadingFive: React.FunctionComponent<
  HeadingProps & React.HTMLAttributes<HTMLHeadingElement>
> = (props) => renderHeading(props, 5);
const HeadingSix: React.FunctionComponent<
  HeadingProps & React.HTMLAttributes<HTMLHeadingElement>
> = (props) => renderHeading(props, 6);

export interface ParagraphProps extends TypographyProps {
  bodyStyle?: 1 | 2 | 3 | 4;
  useBold?: boolean;
}

const Paragraph: React.FunctionComponent<
  ParagraphProps & React.HTMLAttributes<HTMLParagraphElement>
> = (props) => {
  const {
    useLight,
    className,
    children,
    bodyStyle,
    useItalic,
    useBold,
    ...other
  } = props;

  // Set up classes
  let paragraphClasses = classNames(
    {
      "tds-text__paragraph": true,
      "tds-text__paragraph--light": useLight,
      "tds-text__paragraph--bold": useBold,
      "tds-text__paragraph-large": bodyStyle === 1,
      "tds-text__paragraph-small": bodyStyle === 3,
      "tds-text__footnote": bodyStyle === 4,
      "tds-typography--italic": useItalic,
    },
    className
  );

  return (
    <p className={paragraphClasses} {...other}>
      {children}
    </p>
  );
};

const Link: React.FunctionComponent<
  TypographyProps & React.HTMLAttributes<HTMLAnchorElement>
> = (props) => {
  const { useLight, useItalic, className, children, ...other } = props;

  // Set up classes
  let anchorClasses = classNames(
    {
      "tds-text__link": true,
      "tds-text__link--light": useLight,
      "tds-typography--italic": useItalic,
    },
    className
  );

  return (
    <a className={anchorClasses} {...other}>
      {children}
    </a>
  );
};

const Footnote: React.FunctionComponent<
  TypographyProps & React.HTMLAttributes<HTMLElement>
> = (props) => {
  const { useLight, useItalic, className, children, ...other } = props;

  // Set up classes
  let footnoteClasses = classNames(
    {
      "tds-text__footnote": true,
      "tds-text__footnote--light": useLight,
      "tds-typography--italic": useItalic,
    },
    className
  );

  return (
    <small className={footnoteClasses} {...other}>
      {children}
    </small>
  );
};

/**
 * Sub Components
 */

Typography.H1 = HeadingOne;

Typography.H2 = HeadingTwo;

Typography.H3 = HeadingThree;

Typography.H4 = HeadingFour;

Typography.H5 = HeadingFive;

Typography.H6 = HeadingSix;

Typography.Paragraph = Paragraph;

Typography.Link = Link;

Typography.Footnote = Footnote;

export default Typography;

export {
  HeadingOne,
  HeadingTwo,
  HeadingThree,
  HeadingFour,
  HeadingFive,
  HeadingSix,
  Paragraph,
  Link,
  Footnote,
};
