import * as React from "react";
import classNames from "classnames";
import { FormElementProps } from "../formTypes";

export interface LabelProps extends FormElementProps {
  /**
   * Adjusts the color of the label based on state
   */
  state?: "default" | "error" | "disabled";
}

export type LabelType = React.FunctionComponent<
  LabelProps & React.HTMLProps<HTMLLabelElement>
>;

/**
 * This is an Label
 * @param {LabelProps} props
 */
const Label: LabelType = (props) => {
  const { className, groupIdentifier, state, ...other } = props;

  let labelClasses = classNames({
    "tds-form__label": true,
    "tds-form__label--error": state === "error",
    "tds-form__label--disabled": state === "disabled",
  });

  labelClasses = className ? labelClasses.concat(" ", className) : labelClasses;

  return (
    <label
      htmlFor={groupIdentifier ? groupIdentifier.getId() : undefined}
      className={labelClasses}
      {...other}
    >
      {props.children}
    </label>
  );
};

Label.defaultProps = {} as Partial<LabelProps>;

export default Label;
