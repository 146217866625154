import * as React from "react";
import classNames from "classnames";
import { FormElementProps } from "../formTypes";

export interface InputComboProps extends FormElementProps {
  Input: JSX.Element;
  Button: JSX.Element;
  // groupId?: string;
  state?: string;
}

export type InputComboType = React.FunctionComponent<
  InputComboProps & React.HTMLProps<HTMLDivElement>
>;

/**
 * This is an InputCombo
 * @param {InputComboProps} props
 */
const InputCombo: InputComboType = (props) => {
  const {
    className,
    groupIdentifier,
    Input,
    Button,
    state /* Is this needed? */,
    ...other
  } = props;

  let InputComboClasses = classNames({
    "tds-form__input-combo": true,
  });

  InputComboClasses = className
    ? InputComboClasses.concat(" ", className)
    : InputComboClasses;

  return (
    <div className={InputComboClasses} {...other}>
      {React.cloneElement(Input, { groupIdentifier: groupIdentifier })}
      {React.cloneElement(Button, { groupIdentifier: groupIdentifier })}
    </div>
  );
};

InputCombo.defaultProps = {} as Partial<InputComboProps>;

export default InputCombo;
