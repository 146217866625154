
import React, { version }  from "react";
import Version from "../components/static/Version";
import { Switch, BrowserRouter, Route,HashRouter as Router,Redirect } from "react-router-dom";
import MockPage from "../MockPage";

//import { Activateuhccom } from "../Activateuhccom";
import RegistrationConfirmation from "../RegistractionConfirmation";
import { UcardActivation } from "../UcardActivation";



const AppRouting = () => {
    
  return (
    <>
      <BrowserRouter >   
      <div>  
        <Switch>     
        <Route path="/ucard-activation/temp-deeplink" component={MockPage}/>  
        <Route exact path="/temp-deeplink" component={MockPage}/> 
        <Route path="/ucard-activation/registration-confirmation" component={RegistrationConfirmation}/>   
        <Route exact path="/registration-confirmation" component={RegistrationConfirmation}/>   
        <Route path="/version" render={() => <Version />} />,
        {/* <Route exact path="*" component={Activateuhccom}/>   */}
        <Route exact path="*" component={UcardActivation}/>  
       </Switch>
      </div> 
      </BrowserRouter>
    </>
  )
}

export default AppRouting;