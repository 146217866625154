import * as React from "react";
import classNames from "classnames";
import { StepProps } from "./stepInterface";
import StepTitle from "./StepTitle";
import InnerStepCircle from "./InnerStepCircle";
import StepAssistiveText from "./StepAssistiveText";

export interface OneSubStepProps extends StepProps {
  /**
   * Determines number of substeps completed
   */
  stepsComplete: 0 | 1;
}

/**
 * This is an Step
 * @param {OneSubStep} props
 */
const OneSubStep: React.FunctionComponent<OneSubStepProps> = (props) => {
  const {
    className,
    stepNumber,
    active,
    stepsComplete,
    title,
    subtitle,
    ...other
  } = props;
  const maxSubSteps = 1;

  let subStepClasses = classNames({
    "tds-step-tracker__sub-step": true,
    "tds-step-tracker__sub-step--active":
      active && stepsComplete !== maxSubSteps,
    "tds-step-tracker__sub-step--complete":
      active && stepsComplete === maxSubSteps,
  });

  let stepClasses = classNames({
    "tds-step-tracker__sub-step-container": true,
  });

  stepClasses = className ? stepClasses.concat(" ", className) : stepClasses;

  return (
    <li className={stepClasses} {...other}>
      <svg
        width="40px"
        height="40px"
        viewBox="0 0 40 40"
        version="1.1"
        className={subStepClasses}
        aria-hidden="true"
        focusable="false"
      >
        <title>blocks/Steps-1/default</title>
        <g
          id="Step-Tracker"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <g id="Step-tracker" transform="translate(-477.000000, -160.000000)">
            <g id="Group" transform="translate(477.000000, 160.000000)">
              <circle
                id=".blocks/steps/1/default"
                className={subStepClasses}
                stroke="#DBDBDB"
                strokeWidth="2"
                fill="#FFFFFF"
                cx="20"
                cy="20"
                r="19"
              ></circle>
              <g
                transform="translate(8.000000, 8.000000)"
                id=".blocks/step-number"
              >
                <InnerStepCircle
                  complete={maxSubSteps === stepsComplete}
                  displayNumber={stepNumber}
                />
              </g>
            </g>
          </g>
        </g>
      </svg>
      {(title || subtitle) && (
        <StepTitle title={title} subtitle={subtitle}></StepTitle>
      )}
      <StepAssistiveText
        stepsComplete={stepsComplete}
        maxSubSteps={maxSubSteps}
        active={active}
      />
    </li>
  );
};

OneSubStep.defaultProps = {
  stepNumber: 1,
  stepsComplete: 0,
  active: false,
} as Partial<OneSubStepProps>;

export default OneSubStep;
