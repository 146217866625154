import * as React from "react";
import { PopupTipIconProps } from "./PopupTip";

const POPUP_QUESTION_COMPONENT_KEY = "question";

export const PopupTipQuestion = (props: PopupTipIconProps) => {
  const { popupTipIdentifier, isHovered, iconColor, iconVariant, ...rest } =
    props;
  const color = iconColor === "secondary" ? "#fff" : "#002677";
  const hoverColor = iconColor === "secondary" ? "#dadee9" : "#196ecf";

  if (iconVariant === "outlined") {
    return (
      <svg
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        focusable={false}
        aria-hidden={true}
        {...rest}
      >
        <defs>
          <path
            fillRule="evenodd"
            d="M11 18h2v-2h-2v2zm1-16C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm0-14c-2.21 0-4 1.79-4 4h2c0-1.1.9-2 2-2s2 .9 2 2c0 2-3 1.75-3 5h2c0-2.25 3-2.5 3-5 0-2.21-1.79-4-4-4z"
            id={popupTipIdentifier.getChildId(POPUP_QUESTION_COMPONENT_KEY)}
          />
        </defs>
        <g fill="none" fillRule="evenodd">
          <mask id={popupTipIdentifier.getChildId("mask")} fill="#fff">
            <use
              xlinkHref={`#${popupTipIdentifier.getChildId(
                POPUP_QUESTION_COMPONENT_KEY
              )}`}
            />
          </mask>
          <path
            fill={!isHovered ? color : hoverColor}
            mask={`url(#${popupTipIdentifier.getChildId("mask")})`}
            d="M11 18h2v-2h-2v2zm1-16C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm0-14c-2.21 0-4 1.79-4 4h2c0-1.1.9-2 2-2s2 .9 2 2c0 2-3 1.75-3 5h2c0-2.25 3-2.5 3-5 0-2.21-1.79-4-4-4z"
          />
        </g>
      </svg>
    );
  }

  return (
    <svg
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      focusable={false}
      aria-hidden={true}
      {...rest}
    >
      <defs>
        <path
          d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2V2ZM13 19H11V17H13V19V19ZM15.07 11.25L14.17 12.17C13.45 12.9 13 13.5 13 15H11V14.5C11 13.4 11.45 12.4 12.17 11.67L13.41 10.41C13.78 10.05 14 9.55 14 9C14 7.9 13.1 7 12 7C10.9 7 10 7.9 10 9H8C8 6.79 9.79 5 12 5C14.21 5 16 6.79 16 9C16 9.88 15.64 10.68 15.07 11.25V11.25Z"
          id={popupTipIdentifier.getChildId(POPUP_QUESTION_COMPONENT_KEY)}
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <mask id={popupTipIdentifier.getChildId("mask")} fill="#fff">
          <use
            xlinkHref={`#${popupTipIdentifier.getChildId(
              POPUP_QUESTION_COMPONENT_KEY
            )}`}
          />
        </mask>
        <path
          fill={!isHovered ? color : hoverColor}
          mask={`url(#${popupTipIdentifier.getChildId("mask")})`}
          d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2V2ZM13 19H11V17H13V19V19ZM15.07 11.25L14.17 12.17C13.45 12.9 13 13.5 13 15H11V14.5C11 13.4 11.45 12.4 12.17 11.67L13.41 10.41C13.78 10.05 14 9.55 14 9C14 7.9 13.1 7 12 7C10.9 7 10 7.9 10 9H8C8 6.79 9.79 5 12 5C14.21 5 16 6.79 16 9C16 9.88 15.64 10.68 15.07 11.25V11.25Z"
        />
      </g>
    </svg>
  );
};

PopupTipQuestion.defaultProps = {
  iconColor: "primary",
  iconVariant: "filled",
} as Partial<PopupTipIconProps>;

export default PopupTipQuestion;
