import * as React from "react";
import classNames from "classnames";
import SelectOption, { SelectOptionType } from "./SelectOption";
import ExpandMore from "@material-ui/icons/ExpandMore";
import { FormElementProps } from "../formTypes";

export interface SelectProps extends FormElementProps {
  state?: "default" | "error" | "disabled";
  hasInstruction?: boolean;
  hasErrorMessage?: boolean;
  ariaDescribedBy?: string;
  containerClassName?: string;
  containerProps?: React.HTMLAttributes<HTMLDivElement>;
}

export type SelectType = React.FunctionComponent<
  SelectProps & React.HTMLProps<HTMLSelectElement>
>;

/**
 * This is a Select
 * @param {SelectProps} props
 */
const Select: SelectType & { Option: SelectOptionType } = (props) => {
  const {
    className,
    groupIdentifier,
    state,
    hasInstruction,
    hasErrorMessage,
    ariaDescribedBy,
    id,
    children,
    containerClassName,
    containerProps,
    ...other
  } = props;

  let selectClasses = classNames({
    "tds-form__select-control": true,
    "tds-form__select-control--disabled": state === "disabled",
    "tds-form__select-control--error": state === "error",
  });

  selectClasses = className
    ? selectClasses.concat(" ", className)
    : selectClasses;

  let containerClasses = classNames({
    "tds-form__select": true,
  });

  containerClasses = containerClassName
    ? containerClasses.concat(" ", containerClassName)
    : containerClasses;

  return (
    <div className={containerClasses} {...containerProps}>
      <select
        id={groupIdentifier?.getId() || id}
        className={selectClasses}
        disabled={state === "disabled" ? true : false}
        aria-invalid={state === "error" ? "true" : "false"}
        aria-describedby={ariaDescribedBy}
        {...other}
      >
        {children}
      </select>
      <ExpandMore
        style={{
          fill: state === "disabled" ? "#777" : undefined,
          color: state === "disabled" ? "#777" : undefined,
        }}
        className="tds-form__select-expand"
      />
    </div>
  );
};

Select.defaultProps = {} as Partial<SelectProps>;

Select.Option = SelectOption;

export default Select;
