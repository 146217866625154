/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from "react";

export interface NavIconProps {
  // HTML Props
  /** Display the icon as open or closed. */
  open?: boolean;
}

const AuthNavIcon: any = React.forwardRef(({ open, ...rest }: any, ref) => {
  const ariaProps = {
    "aria-haspopup": open ? undefined : true,
  };
  return (
    <button
      {...ariaProps}
      className={`s-nav-icon ${open ? "s-nav-icon-open" : ""}`}
      ref={ref}
      {...rest}
    >
      <span className="s-nav-icon-line" />
      <span className="s-nav-icon-line" />
      <span className="s-nav-icon-line" />
      <span className="s-nav-icon-text">{open ? "Close" : "Menu"}</span>
    </button>
  );
});

AuthNavIcon.displayName = "AuthNav.Icon";

export default AuthNavIcon;
