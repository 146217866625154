import * as React from "react";
import { useWindowSize } from "../../hooks";
import Breadcrumb, { BreadcrumbType } from "./Breadcrumb";
import UtilityIcon from "../icon/UtilityIcon";
import ChevronRight from "@material-ui/icons/ChevronRight";
import MoreHoriz from "@material-ui/icons/MoreHoriz";
import classNames from "classnames";
import BreadcrumbContext from "./BreadcrumbContext";

export interface BreadcrumbsProps {
  /**
   * Unique key to ensure we keep uniqueness across different breadcrumbs if they are on the same page.
   * This will be used to create all the id's needed for the content and accessibility requirements.
   *
   * @type {number}
   * @memberof BreadcrumbsProps
   */
  contentId?: string | number;
  children: React.ReactElement | React.ReactElement[];
  /**
   * Determines the color variant of the breadcrumb
   * @type {("primary" | "secondary")}
   * @memberof BreadcrumbsProps
   */
  color?: "primary" | "secondary";
}

/**
 * Breadcrumbs
 * @param {BreadcrumbsProps} props
 */
const Breadcrumbs: React.FunctionComponent<
  BreadcrumbsProps & React.HTMLProps<HTMLDivElement>
> & { Breadcrumb: BreadcrumbType } = ({
  contentId,
  className,
  children,
  color = "primary",
  ...rest
}) => {
  const [expanded, setExpanded] = React.useState(false);
  const windowSize = useWindowSize();
  const windowWidth = windowSize.width || 0;
  const breadcrumbChildren = React.Children.toArray(children);
  const id = `breadcrumb-${contentId || 1}`;
  const isMobile = windowWidth < 768;

  if (
    ((breadcrumbChildren.length >= 3 && isMobile) ||
      (breadcrumbChildren.length >= 5 && !isMobile)) &&
    !expanded
  ) {
    breadcrumbChildren.splice(
      1,
      breadcrumbChildren.length - 2,
      <li key="breadcrumb-delimiter" className="tds-breadcrumbs__listitem">
        <button
          onClick={() => setExpanded(true)}
          aria-expanded="false"
          aria-controls={`breadcrumb-${contentId || 1}`}
          aria-label="show 3 more navigation levels"
          className="tds-breadcrumbs__ellipses-button"
        >
          <span aria-hidden="true" className="tds-breadcrumbs__ellipses-icon">
            <UtilityIcon
              size="small"
              className="tds-breadcrumbs__ellipses-dots"
              icon={MoreHoriz}
              color={color === "primary" ? "primaryCTA" : "primaryLight"}
            />
          </span>
        </button>
        <UtilityIcon
          size="small"
          className="tds-breadcrumbs__chevron"
          icon={ChevronRight}
          color={color === "primary" ? "gray" : "primaryLight"}
        />
      </li>
    );
  }

  let breadcrumbListClassNames = classNames({
    "tds-breadcrumbs__list": true,
    "tds-focus-dark-background": color === "secondary",
  });

  breadcrumbListClassNames = className
    ? breadcrumbListClassNames.concat(" ", className)
    : breadcrumbListClassNames;

  return (
    <nav
      className={`tds-breadcrumbs${
        color === "secondary" ? " tds-breadcrumbs--secondary" : ""
      }`}
      {...rest}
    >
      <BreadcrumbContext.Provider value={{ color }}>
        <ol className={breadcrumbListClassNames} id={id}>
          {breadcrumbChildren}
        </ol>
      </BreadcrumbContext.Provider>
    </nav>
  );
};

Breadcrumbs.Breadcrumb = Breadcrumb;

Breadcrumbs.displayName = "Breadcrumbs";

export default Breadcrumbs;
