import React from "react";

import { AppTrackData } from "../../tracking/types";
import TrackingContext from "../../tracking/TrackingContext";
import { useVersion } from "../../hooks";

export interface AppProps {
  appTrackingData?: AppTrackData;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  children?: any;
}

/**
 * A tracking container
 */
const Analytics: React.FunctionComponent<
  AppProps & React.HTMLProps<HTMLDivElement>
> = ({ appTrackingData = {}, children }) => {
  useVersion();
  return (
    <TrackingContext.Provider value={{ ...appTrackingData }}>
      {children}
    </TrackingContext.Provider>
  );
};

export default Analytics;
