import React from 'react';
import { Activateuhccom } from "./Activateuhccom";
import AppRouting from './app-routing/appRouting';

function App() {


  return (
   <AppRouting></AppRouting>
  );
}
export default App;
