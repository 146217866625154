export interface UniqueIdentifier {
  /**
   * Get Unique Id
   *
   * @memberof UniqueIdentifier
   */
  getId: () => string;
  /**
   * Gets a unique contextual id based on the parent id
   *
   * @memberof UniqueIdentifier
   */
  getChildId: (childComponentName: string) => string;
  /**
   * Gets a derived Identifier based on the same underlying unique key.
   * Useful for lists of items that need to look like part of the parent, but also need to be unique
   *
   * @memberof UniqueIdentifier
   */
  getDerivedIdentifier: (
    componentName: string,
    index: number
  ) => UniqueIdentifier;
}

export const getPlaceholderUID = (): UniqueIdentifier => {
  return {
    getId: () => "",
    getChildId: (childComponentName: string) => "",
    getDerivedIdentifier: (componentName, index) => getPlaceholderUID(),
  };
};

const userUniques = {};
let current = 1;

export function getUniqueId(override?: string | number): string {
  if (typeof override === "number") {
    userUniques[override] = true;
    return `${override}`;
  }
  const next = current + 1;
  current++;
  // make sure unique number hasn't been registered, otherwise, recursively try for unique
  if (userUniques[next]) {
    return getUniqueId();
  }
  return ("00" + next).slice(-Math.max(3, `${next}`.length));
}
