import {
  ClickTrackType,
  PageLoadTrackType,
  CustomTrackType,
  WindowWithTracking,
} from "./types";
import TrackingContext from "./TrackingContext";

// Exported into the Component Library for custom, non built-in, tracking...
export { default as useClickTracking } from "./useClickTracking";
export { default as usePageLoadTracking } from "./usePageLoadTracking";
export { default as useCustomTracking } from "./useCustomTracking";
export { default as trackClick } from "./trackClick";
export { default as trackPageLoad } from "./trackPageLoad";
export { default as trackCustomEvent } from "./trackCustomEvent";
export * from "./types";

const TrackingContextConsumer = TrackingContext.Consumer;

// Only exporting the Consumer. The TrackingContext.Provider is inside the <App /> component.
export { TrackingContextConsumer };

declare let window: WindowWithTracking;

const track = (data: ClickTrackType | PageLoadTrackType | CustomTrackType) => {
  if (window.appEventDataLayer) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return window.appEventDataLayer.push(data);
  }
  // NOTE:
  // - We don't want to define the app event data layer on behalf of the consumer.
  // - If they haven't done that, their installation is likely not setup correctly and probably should be notified that they should address it.
  // eslint-disable-next-line no-console
  console.warn("No appEventDataLayer available", data);
  return false;
};

export { track };
