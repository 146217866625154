import * as React from "react";
import ModalClose from "./ModalClose";
import ModalContext from "./ModalContext";
import classNames from "classnames";

export interface ModalHeaderProps {}

export type ModalHeaderType = React.FunctionComponent<
  ModalHeaderProps & React.HTMLProps<HTMLDivElement>
>;

/**
 * This is a ModalHeader
 * @param {ModalHeaderProps} props
 */

const ModalHeader: ModalHeaderType = (props) => {
  const { children, className } = props;

  let headerClasses = classNames({
    "tds-modal__header": true,
  });

  headerClasses = className
    ? headerClasses.concat(" ", className)
    : headerClasses;

  const { handleClose, modalIdentifier } = React.useContext(ModalContext);

  return (
    <div className={headerClasses}>
      <button
        onClick={handleClose}
        className="tds-modal__close"
        aria-label="close"
      >
        <ModalClose modalIdentifier={modalIdentifier} />
      </button>
      {children}
    </div>
  );
};

export default ModalHeader;
