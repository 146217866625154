import * as React from "react";

const AuthNavHeader: React.FC<React.HTMLProps<HTMLDivElement>> = ({
  children,
  ...rest
}) => (
  <header className="s-nav-header" {...rest}>
    <div className="s-nav-container">{children}</div>
  </header>
);

AuthNavHeader.displayName = "AuthNav.Header";

export default AuthNavHeader;
