import * as React from "react";
import classNames from "classnames";
import { allTokens } from "../../tokenLoader";
import UtilityIcon, { IconColorVariants } from "../icon/UtilityIcon";
import FlagIcon from "@material-ui/icons/Flag";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ErrorIcon from "@material-ui/icons/Error";
import CloseIcon from "@material-ui/icons/Close";
import { SvgIconComponent } from "@material-ui/icons";

export interface NotificationProps {
  /**
   * Specifies which style of notification to use
   */
  notificationType: "info" | "success" | "warning" | "error";

  /**
   * Controls the visibility of a notification
   */
  isDismissed: boolean;

  /**
   * Helps screen readers to better identify the content/purpose of the notification
   */
  a11yHeadingText?: string;

  /**
   * Defines what level of heading should be used, appropriate to it's surroundings
   */
  a11yHeadingLevel?: 1 | 2 | 3 | 4 | 5 | 6;

  /**
   * Function for determining what happens when a user clicks on the dismiss icon.
   *
   * **NOTE:** `dismissClickHandler` is required for all notification types.
   */
  dismissClickHandler: Function;
}

interface IconConfigProps {
  iconType: SvgIconComponent;
  iconColor: IconColorVariants;
}

export type NotificationType = React.FunctionComponent<
  NotificationProps & React.HTMLProps<HTMLDivElement>
>;

const warningSvg = {
  svg: (
    <svg
      className="MuiSvgIcon-root tds-utility-icon"
      focusable="false"
      color={allTokens.color.supporting.two.value}
      aria-hidden="true"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 22 20"
    >
      <g fill="none" fillRule="evenodd">
        <path fill="currentColor" d="M0 19.5h22L11 .5z" />
        <path fill="#000" d="M12 16.5h-2v-2h2zM12 12.5h-2v-4h2z" />
      </g>
    </svg>
  ),
};

/**
 * This is a Notification
 * @param {NotificationProps} props
 */
const Notification: NotificationType = (props) => {
  const {
    className,
    notificationType = "info",
    dismissClickHandler,
    isDismissed,
    a11yHeadingText,
    a11yHeadingLevel = 2,
    children,
    ...other
  } = props;

  const NOTIFICATION_TYPE = {
    info: "info",
    success: "success",
    warning: "warning",
    error: "error",
  };

  let iconConfig: IconConfigProps = {
    iconType: FlagIcon,
    iconColor: "primary",
  };

  if (typeof dismissClickHandler === "undefined") {
    console.error("You must specify a dismissClickHandler.");
  }

  let iconMarkup;
  let headingText;

  switch (notificationType) {
    case NOTIFICATION_TYPE.info:
      iconConfig.iconType = FlagIcon;
      iconConfig.iconColor = "primary";
      headingText = "Important Message";
      break;
    case NOTIFICATION_TYPE.success:
      iconConfig.iconType = CheckCircleIcon;
      iconConfig.iconColor = "success";
      headingText = "Success Message";
      break;
    case NOTIFICATION_TYPE.warning:
      iconMarkup = warningSvg.svg;
      headingText = "Warning Message";
      break;
    case NOTIFICATION_TYPE.error:
      iconConfig.iconType = ErrorIcon;
      iconConfig.iconColor = "alert";
      headingText = "Critical Message";
      break;
    default:
      break;
  }

  headingText = a11yHeadingText ? a11yHeadingText : headingText;

  if (notificationType !== NOTIFICATION_TYPE.warning) {
    iconMarkup = (
      <UtilityIcon
        icon={iconConfig.iconType}
        color={iconConfig.iconColor}
        size="medium"
      />
    );
  }

  let NotificationClasses = classNames(className, {
    "tds-notification": true,
    "tds-notification--with-dismiss": true,
    "tds-notification--info": notificationType === "info",
    "tds-notification--success": notificationType === "success",
    "tds-notification--warning": notificationType === "warning",
    "tds-notification--error": notificationType === "error",
    "tds-notification--is-dismissed": isDismissed === true,
  });

  let headingLevel;
  if (a11yHeadingLevel > 6 || a11yHeadingLevel < 1) {
    console.error(
      "You must specify a valid heading level 1-6.  Defaulting to h2."
    );
    headingLevel = 2;
  } else {
    headingLevel = a11yHeadingLevel;
  }

  const dismissNotification = (
    e: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>
  ): void => {
    if (dismissClickHandler) {
      dismissClickHandler();
    }
  };

  const HeaderComp = `h${headingLevel}` as any;
  return (
    <div className={NotificationClasses} {...other}>
      <div className="tds-notification__layout">
        <div className="tds-notification__icon">
          {iconMarkup}
          <HeaderComp className="tds-sr-only">{headingText}</HeaderComp>
        </div>
        <div className="tds-notification__body">{children}</div>
      </div>
      <button
        className="tds-notification__dismiss-button"
        onClick={dismissNotification}
        title="Dismiss notification"
      >
        <CloseIcon fontSize="small" aria-label="Dismiss" />
        <span className="tds-sr-only">Dismiss</span>
      </button>
    </div>
  );
};

Notification.defaultProps = {
  notificationType: "info",
  a11yHeadingLevel: 2,
  isDismissed: false,
} as Partial<NotificationProps>;

export default Notification;
