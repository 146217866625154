import * as React from "react";
import classNames from "classnames";
import { useState } from "react";

export interface SideNavJumpProps {
  /**
   * Adds a header to the SideNavJump component
   */
  header?: string;
  /**
   * Hides the header visually but keeps it detectable for screen readers.
   */
  hideHeader?: boolean;
  /**
   * An array of titles for links to jump to within page
   */
  linkTitles: Array<string>;
  /**
   * An array of anchor tags to jump to within the page
   */
  linkAnchors: Array<string>;
  /**
   * When used sets the active state the the anchor tag passed
   */
  currentAnchor?: string;

  [x: string]: any;
}

/**
 * This is an SideNavJump
 * @param {SideNavJumpProps} props
 */
const SideNavJump: React.FunctionComponent<SideNavJumpProps> = (props) => {
  const {
    className,
    header,
    hideHeader,
    linkTitles,
    linkAnchors,
    currentAnchor,
    ...other
  } = props;

  let setStartingAnchor = currentAnchor ? currentAnchor : "";

  const [activeAnchor, setActiveAnchor] = useState(setStartingAnchor);

  let sideNavJumpClasses = classNames({
    "tds-side-nav": true,
    "tds-side-nav--jump": true,
  });

  sideNavJumpClasses = className
    ? sideNavJumpClasses.concat(" ", className)
    : sideNavJumpClasses;

  let activeClass = "tds-side-nav__jump-list-link--active";

  let listAnchorItems = linkTitles.map((title, index) => {
    let isActive = activeAnchor === linkAnchors[index];
    return (
      <li className="tds-side-nav__jump-list-item" key={linkAnchors[index]}>
        <a
          onClick={() => {
            setActiveAnchor(linkAnchors[index]);
          }}
          className={`tds-side-nav__jump-list-link ${
            isActive ? activeClass : ""
          }`}
          href={linkAnchors[index]}
        >
          {title}
          {isActive && <span className="tds-sr-only">, current section</span>}
        </a>
      </li>
    );
  });

  return (
    <nav
      className={sideNavJumpClasses}
      aria-labelledby={header ? "#side-nav__jump-header" : ""}
      {...other}
    >
      {header && (
        <div
          className={`tds-side-nav__jump-header ${
            hideHeader ? "tds-sr-only" : ""
          }`}
          id="side-nav__jump-header"
        >
          {header}
        </div>
      )}
      <ul className="tds-side-nav__jump-list">{listAnchorItems}</ul>
    </nav>
  );
};

SideNavJump.defaultProps = {} as Partial<SideNavJumpProps>;

export default SideNavJump;
