import * as React from "react";
import AuthNavLink from "./AuthNavLink";
import AuthNavSubNav from "./AuthNavSubNav";
import AuthNavSubNavLink from "./AuthNavSubNavLink";
import AuthNavDesktop from "./AuthNavDesktop";
import AuthNavMobile from "./AuthNavMobile";
import AuthNavModal from "./AuthNavModal";
import AuthNavModalContent from "./AuthNavModalContent";
import AuthNavHeader from "./AuthNavHeader";
import AuthNavIcon from "./AuthNavIcon";
import AuthNavLevel from "./AuthNavLevel";
import AuthNavLogo from "./AuthNavLogo";
import AuthNavLevelItem from "./AuthNavLevelItem";
import AuthNavModalClose from "./AuthNavModalClose";

export interface AuthNavProps {
  // HTML Props
  /** Props passed to the Navs's inner container. */
  innerContainerProps?: React.HTMLProps<HTMLDivElement>;
  /** Props passed to the Navs's item list container. */
  listContainerProps?: React.HTMLProps<HTMLUListElement>;
}

const useWindowSize = () => {
  const isClient = typeof window === "object";

  function getSize() {
    return {
      width: isClient ? window.innerWidth : undefined,
      height: isClient ? window.innerHeight : undefined,
    };
  }

  const [windowSize, setWindowSize] = React.useState(getSize);

  // @ts-ignore
  React.useEffect(() => {
    if (!isClient) {
      return false;
    }

    function handleResize() {
      setWindowSize(getSize());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // Empty array ensures that effect is only run on mount and unmount

  return windowSize;
};

export { useWindowSize };

/**
 * The main page navigation.
 */
class AuthNav extends React.PureComponent<
  AuthNavProps & React.HTMLProps<HTMLDivElement>
> {
  public static displayName = "AuthNav";

  public static Logo = AuthNavLogo;

  public static Link = AuthNavLink;

  public static SubNav = AuthNavSubNav;

  public static SubNavLink = AuthNavSubNavLink;

  public static Desktop = AuthNavDesktop;

  public static Mobile = AuthNavMobile;

  public static Modal = AuthNavModal;

  public static ModalContent = AuthNavModalContent;

  public static Header = AuthNavHeader;

  public static Icon = AuthNavIcon;

  public static Level = AuthNavLevel;

  public static LevelItem = AuthNavLevelItem;

  public static ModalClose = AuthNavModalClose;

  public static useWindowSize = useWindowSize;

  public render() {
    const {
      children,
      innerContainerProps,
      listContainerProps,
      ...rest
    } = this.props;

    const ariaProps = {
      "aria-label": rest["aria-label"] || "Page",
    };

    return (
      <nav className="s-nav" {...rest} {...ariaProps}>
        <div className="s-nav-container" {...innerContainerProps}>
          <ul className="s-nav-items" {...listContainerProps}>
            {React.Children.map(
              children,
              (child: React.ReactNode, index: number) => (
                <li className="s-nav-item" key={index}>
                  {child}
                </li>
              )
            )}
          </ul>
        </div>
      </nav>
    );
  }
}

export default AuthNav;
