import * as React from "react";
import classNames from "classnames";
import { FormElementProps } from "../formTypes";

export interface InputButtonProps extends FormElementProps {}

export type InputButtonType = React.FunctionComponent<
  InputButtonProps & React.HTMLAttributes<HTMLButtonElement>
>;

/**
 * This is an InputButton
 * @param {InputButtonProps} props
 */
const InputButton: InputButtonType = (props) => {
  const { className, groupIdentifier, ...other } = props;

  let InputButtonClasses = classNames({
    "tds-form__input-button": true,
  });

  InputButtonClasses = className
    ? InputButtonClasses.concat(" ", className)
    : InputButtonClasses;

  return (
    <button className={InputButtonClasses} {...other}>
      {props.children}
    </button>
  );
};

InputButton.defaultProps = {} as Partial<InputButtonProps>;

export default InputButton;
