import * as React from "react";
import classNames from "classnames";

export interface BannerProps {
  /**
   * Determines whether the type of banner to display
   */
  bannerType?: "side" | "default";
  /**
   * Determines the state and position of the content being implemented inside the banner. */
  position?: "right" | "left";
  /**
   * Applies background color for the band type banner. */
  backgroundColor?: "primary" | "secondary";
  /**
   * Full-width background or side-by-side custom image option. This should the
   * source of where your image is located */
  image?: string;
  /*
   * Adds a border to the banner (side by side ONLY) */
  border?: boolean;
  /**
   * Adds alt text to the Side-by-Side Banner image ONLY
   */
  imageAlt?: string;

  [x: string]: any;
}

/**
 * This is a Banner Component. This has three types:
 * Default Banner
 * Side-by-Side Banner
 * Solid Band Banner
 * @param {BannerProps} props
 */
const Banner: React.FunctionComponent<BannerProps> = (props) => {
  const {
    position,
    backgroundColor,
    image,
    imageHeight,
    imageWidth,
    children,
    className,
    bannerType,
    border,
    imageAlt,
    ...other
  } = props;

  console.warn(
    "This component is deprecated and will become obsolete in a future version."
  );

  /**
   * Classes for the outer most div that holds all the content for the banner
   * This is also where the background color and background image are applied
   */
  let bannerClasses = classNames({
    "tds-banner": true,
    "tds-banner--side": bannerType === "side",
  });

  // Allow user to add there own classes to the base banner div
  bannerClasses = className
    ? bannerClasses.concat(" ", className)
    : bannerClasses;

  /**
   * Classes for the inner container of the banner that adjust layout
   * of the child elements (Card Component) passed in by the user. There
   * are two types, one for the default banner style and one for the
   * side-by-side banner
   */
  const bannerContainerClasses = classNames({
    "tds-banner__container": true,
    "tds-banner__container--no-padding": bannerType === "default" && !children,
    "tds-banner__container-side": bannerType === "side",
  });

  const bannerSideContainerClasses = classNames({
    "tds-banner__container-side": true,
    "tds-banner__container-side--image-left": position === "right",
    "tds-banner__container-side--border": border,
  });

  /**
   * Column classes(flexbox) for the inner container of the default banner to layout
   * the Card component/child elements
   */
  const bannerMainColumnContentClasses = classNames({
    "tds-banner__column-main": true,
    "tds-banner__column-main--content-left": position === "left",
    "tds-banner__column-main--content-right": position === "right",
  });

  /**
   * Inline styles to apply background image for the default banner style
   */
  const backgroundImageStyle =
    image && bannerType === "default"
      ? {
          background: `url(${image})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }
      : {};

  return (
    <div
      id="banner-background"
      className={bannerClasses}
      style={backgroundImageStyle}
      {...other}
    >
      {bannerType === "side" && (
        <div id="banner-container-side" className={bannerSideContainerClasses}>
          <div className="tds-banner__column-side">{children}</div>
          <div className="tds-banner__column-side">
            <img
              className="tds-banner__image-side"
              src={image}
              alt={imageAlt}
            />
          </div>
        </div>
      )}
      {bannerType === "default" && (
        <div id="banner-container" className={bannerContainerClasses}>
          <div className="tds-banner__column"></div>
          <div className={bannerMainColumnContentClasses}>{children}</div>
          <div className="tds-banner__column"></div>
        </div>
      )}
    </div>
  );
};

Banner.defaultProps = {
  position: "left",
  bannerType: "default",
  backgroundColor: "primary",
  imageAlt: "",
} as Partial<BannerProps>;

export default Banner;
