import * as React from "react";
import classNames from "classnames";
import { useUniqueId } from "../../hooks";

export interface TooltipProps {
  /**
   * Text inside of the tooltip that is displayed on hover.
   *
   * @type {string}
   * @memberof TooltipProps
   */
  title: string;
  /**
   * Display the tooltip on the top or bottom of the element.
   *
   * @type {"top" | "bottom" | 12 | 6}
   * @memberof TooltipProps
   */
  placement?: "top" | "bottom" | 12 | 6;
  /**
   * classNames passed to the tooltip that is displayed on hover.
   *
   * @type {string}
   * @memberof TooltipProps
   */
  contentClassNames?: string;
  /**
   * <span style="color: #c40000;">**Deprecated**</span>
   *
   * Unique key to ensure we keep uniqueness across different tooltips if they are on the same page.
   * This will be used to create all the id's needed for the content and accessibility requirements.
   *
   * This is deprecated for end users, but is still useful for unit testing because it will give a deterministic value to the final generated Id's
   * @deprecated
   * @type {number}
   * @memberof TooltipProps
   */
  contentId?: number;
}

let left = 0;

const TOOLTIP_COMPONENT_KEY = "tooltip";

const Tooltip: React.FunctionComponent<
  TooltipProps & React.HTMLProps<HTMLSpanElement>
> = (props) => {
  const { useState, useRef, useEffect } = React;
  const {
    contentId,
    children,
    className,
    title,
    placement,
    contentClassNames,
    ...rest
  } = props;
  const [open, setOpen] = useState(false);
  const [hovered, setHovered] = useState(false);
  const tooltipIdentifier = useUniqueId(TOOLTIP_COMPONENT_KEY, contentId);

  const tooltipContainerRef = useRef<HTMLSpanElement>(null);
  const tooltipContentRef = useRef<HTMLSpanElement>(null);

  let tooltipClassNames = classNames({
    "tds-tooltip": true,
  });

  tooltipClassNames = className
    ? tooltipClassNames.concat(" ", className)
    : tooltipClassNames;

  let contentClasses = classNames({
    "tds-tooltip__content": true,
    "tds-tooltip__content-top": placement === "top" || placement === 12,
    "tds-tooltip__content-bottom": placement === "bottom" || placement === 6,
    "tds-margin-none": true,
    "tds-text__paragraph--light": true,
  });

  contentClasses = contentClassNames
    ? contentClasses.concat(" ", contentClassNames)
    : contentClasses;

  const onMouseOver = () => {
    setOpen(true);
  };

  const onMouseOut = () => {
    setOpen(false);
  };

  let timer = useRef();

  useEffect(() => {
    if (timer.current) {
      clearTimeout(timer.current);
    }

    if (hovered) {
      // @ts-ignore
      timer.current = setTimeout(onMouseOver, 150);
    } else {
      // @ts-ignore
      timer.current = setTimeout(onMouseOut, 150);
    }

    return () => {
      clearTimeout(timer.current);
    };
  }, [hovered, timer]);

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === "Escape") {
        setOpen(false);
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  if (
    tooltipContainerRef &&
    tooltipContainerRef.current &&
    tooltipContentRef &&
    tooltipContentRef.current
  ) {
    left =
      (tooltipContainerRef.current.offsetWidth -
        tooltipContentRef.current.offsetWidth) /
      2;
  }

  if (!title) {
    return (
      <span className={tooltipClassNames} {...rest}>
        {children}
      </span>
    );
  }

  return (
    <span ref={tooltipContainerRef} className={tooltipClassNames} {...rest}>
      {React.cloneElement(children as React.ReactElement, {
        tabIndex: 0,
        "aria-describedby": tooltipIdentifier.getId(),
        onMouseEnter: () => setHovered(true),
        onMouseLeave: () => setHovered(false),
        onFocus: () => setHovered(true),
        onBlur: () => setHovered(false),
      })}
      <span
        ref={tooltipContentRef}
        id={tooltipIdentifier.getId()}
        role="tooltip"
        aria-hidden={!open}
        className={contentClasses}
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
        onFocus={() => setHovered(true)}
        onBlur={() => setHovered(false)}
        style={{ left: open ? `${left}px` : "-99999px" }}
      >
        {title}
      </span>
    </span>
  );
};

Tooltip.defaultProps = {
  // NOTE: having "top" in this prop as the default would expose a bug within Storybook that causes intermittent loading issues.
  // -- See https://github.optum.com/uhc-digital/tempo-design-system/pull/146 for details
  placement: 12,
} as Partial<TooltipProps>;

export default Tooltip;
