import * as React from "react";
import classNames from "classnames";
import logoImage from "./UHC_Wordmark_Stacked_wht_RGB.png";

export interface FooterLinkSectionProps {
  logo?: boolean;
  customLogo?: HTMLElement;
  [x: string]: any;
}

/**
 * This is an FooterLinkSection
 * @param {FooterLinkSectionProps} props
 */
const FooterLinkSection: React.FunctionComponent<FooterLinkSectionProps> = (
  props
) => {
  const { className, children, logo, customLogo, ...other } = props;

  let footerClasses = classNames({
    "tds-footer__link-section": true,
  });

  footerClasses = className
    ? footerClasses.concat(" ", className)
    : footerClasses;

  return (
    <div className="tds-footer__link-section-wrapper">
      <div className="tds-footer__logo">
        {logo && (
          <img
            className="tds-footer__logo-image"
            src={logoImage}
            alt="UnitedHealthcare"
          />
        )}
        {customLogo}
      </div>
      <div className={footerClasses} {...other}>
        {children}
      </div>
    </div>
  );
};

FooterLinkSection.defaultProps = {
  logo: true,
} as Partial<FooterLinkSectionProps>;

export default FooterLinkSection;
