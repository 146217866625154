import * as React from "react";
import classNames from "classnames";
import { StepProps } from "./stepInterface";
import InnerStepCircle from "./InnerStepCircle";
import StepTitle from "./StepTitle";
import StepAssistiveText from "./StepAssistiveText";

export interface FourSubStepProps extends StepProps {
  /**
   * Determines number of substeps completed
   */
  stepsComplete: 0 | 1 | 2 | 3 | 4;
}

/**
 * This is an Step
 * @param {FourSubStep} props
 */
const FourSubStep: React.FunctionComponent<FourSubStepProps> = (props) => {
  const {
    className,
    stepNumber,
    active,
    stepsComplete,
    title,
    subtitle,
    ...other
  } = props;
  const maxSubSteps = 4;

  const createSubStepClasses = (currentStep: number): object => {
    return {
      "tds-step-tracker__sub-steps": true,
      "tds-step-tracker__sub-steps--active":
        active && stepsComplete === currentStep,
      "tds-step-tracker__sub-steps--complete":
        active && stepsComplete >= currentStep + 1,
    };
  };

  const subStepOneClasses = classNames(createSubStepClasses(0));
  const subStepPathOne =
    active && stepsComplete === 0
      ? "M39.802503,17.9012716 L35.7774352,17.9010205 C34.8748562,10.6637718 29.1387312,4.92764682 21.9014826,4.02506783 L21.9012314,-4.79616347e-14 C31.2304748,0.926254226 38.6738285,8.26324098 39.7637262,17.5433939 L39.802503,17.9012716 Z"
      : "M39.9012515,18.0000201 L37.8902071,18.0005846 C36.9681696,9.65721562 30.3436494,3.03239812 22.0004182,2.10990379 L21.9999799,0.0987484939 C31.448829,1.03687777 38.9631222,8.55117105 39.9012515,18.0000201 Z";

  const subStepTwoClasses = classNames(createSubStepClasses(1));
  const subStepPathTwo =
    active && stepsComplete === 1
      ? "M21.9012314,39.802503 L21.9014826,35.7774352 C29.1387312,34.8748562 34.8748562,29.1387312 35.7774352,21.9014826 L39.802503,21.9012314 C38.8762488,31.2304748 31.539262,38.6738285 22.2591092,39.7637262 L21.9012314,39.802503 Z"
      : "M21.9999799,39.9012515 L22.0004182,37.8900962 C30.3433152,36.9676388 36.9676388,30.3433152 37.8900962,22.0004182 L39.9012515,21.9999799 C38.9631222,31.448829 31.448829,38.9631222 21.9999799,39.9012515 Z";

  const subStepThreeClasses = classNames(createSubStepClasses(2));
  const subStepPathThree =
    active && stepsComplete === 2
      ? "M17.9010205,35.7774352 L17.9012716,39.802503 C8.45242255,38.8643737 0.938129281,31.3500805 -5.32907052e-14,21.9012314 L4.02506783,21.9014826 C4.91332017,29.0238543 10.4828904,34.6923788 17.5575375,35.7308151 L17.9010205,35.7774352 Z"
      : "M18.0005846,37.8902071 L18.0000201,39.9012515 C8.55117105,38.9631222 1.03687777,31.448829 0.0987484939,21.9999799 L2.10990379,22.0004182 C3.03239812,30.3436494 9.65721562,36.9681696 18.0005846,37.8902071 Z";

  const subStepFourClasses = classNames(createSubStepClasses(3));
  const subStepPathFour =
    active && stepsComplete === 3
      ? "M17.9012716,-4.97379915e-14 L17.9010205,4.02506783 C10.6637718,4.92764682 4.92764682,10.6637718 4.02506783,17.9010205 L-5.15143483e-14,17.9012716 C0.926254226,8.57202824 8.26324098,1.12867453 17.5433939,0.038776859 L17.9012716,-4.97379915e-14 Z"
      : "M18.0000201,0.0987484939 L18.0005846,2.10979295 C9.65688136,3.03186731 3.03186731,9.65688136 2.10979295,18.0005846 L0.0987484939,18.0000201 C1.03687777,8.55117105 8.55117105,1.03687777 18.0000201,0.0987484939 Z";

  return (
    <li className="tds-step-tracker__sub-step-container" {...other}>
      <svg
        width="40px"
        height="40px"
        viewBox="0 0 40 40"
        version="1.1"
        aria-hidden="true"
        focusable="false"
      >
        <title>blocks/Steps-3/default Copy 3</title>
        <g
          id="Step-Tracker"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <g id="Step-tracker" transform="translate(-717.000000, -415.000000)">
            <g
              id=".blocks/steps/3-Steps/3rd-step-active"
              transform="translate(717.000000, 415.000000)"
            >
              <circle id="bg" fill="#FFFFFF" cx="20" cy="20" r="20"></circle>
              <g
                id=".blocks/step-number"
                transform="translate(8.000000, 8.000000)"
              >
                <InnerStepCircle
                  complete={maxSubSteps === stepsComplete}
                  displayNumber={stepNumber}
                />
              </g>
              <path
                d={subStepPathOne}
                className={subStepOneClasses}
                id="Path"
                fill="#DBDBDB"
                fillRule="nonzero"
              ></path>
              <path
                d={subStepPathTwo}
                className={subStepTwoClasses}
                id="Path"
                fill="#DBDBDB"
                fillRule="nonzero"
              ></path>
              <path
                d={subStepPathThree}
                className={subStepThreeClasses}
                id="Path"
                fill="#DBDBDB"
                fillRule="nonzero"
              ></path>
              <path
                d={subStepPathFour}
                className={subStepFourClasses}
                id="Path"
                fill="#DBDBDB"
                fillRule="nonzero"
              ></path>
            </g>
          </g>
        </g>
      </svg>
      {(title || subtitle) && (
        <StepTitle title={title} subtitle={subtitle}></StepTitle>
      )}
      <StepAssistiveText
        stepsComplete={stepsComplete}
        maxSubSteps={maxSubSteps}
        active={active}
      />
    </li>
  );
};

FourSubStep.defaultProps = {
  stepNumber: 1,
  stepsComplete: 0,
  active: false,
} as Partial<FourSubStepProps>;

export default FourSubStep;
