import * as React from "react";
import classNames from "classnames";
import LaunchIcon from "@material-ui/icons/Launch";
import UtilityIcon from "../icon/UtilityIcon";
import { ClickTrackData } from "tracking/types";
import { useClickTracking } from "../../tracking";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";

export interface LinkProps<C extends React.ElementType> {
  /**
   * Link text to be added to the link. This can also
   * be ignored and add text as a child of the link component. Anything
   * here will come after any children in hierarchy.
   */
  text?: string;
  /**
   * Adds url to link component
   */
  href?: string;
  /**
   * Adds new window/tab icon and sets target="_blank"
   */
  newTabWindow?: boolean;

  /**
   * Toggles the rendering of the default link icons
   */
  noIcon?: boolean;
  /**
   * Determines the size of the link component
   */
  size?: "small" | "medium" | "large" | number;
  /**
   * onClick tracking data
   */
  onClickTrackingData?: ClickTrackData;
  /**
   * Allows for a custom tag to be used instead of the default "a" tag
   */
  customTag?: C;
  /**
   * <span style="color: #c40000;">**Deprecated**</span>
   *
   * Please use `newTabWindow` instead. This prop will be removed soon.
   * @deprecated
   */
  externalLink?: boolean;
  [x: string]: any;
}

type CustomTagProps<C extends React.ElementType> = LinkProps<C> &
  Omit<React.ComponentPropsWithoutRef<C>, keyof LinkProps<C>>;

/**
 * This is an `a` element by default, but can be modified by the `customTag` prop
 * @param {LinkProps} props
 */
const Link = <C extends React.ElementType = "a">({
  children,
  text,
  noIcon,
  newTabWindow,
  externalLink,
  className,
  size = "medium",
  onClickTrackingData,
  onClick,
  customTag,
  ...other
}: CustomTagProps<C>) => {
  const trackedOnClick = useClickTracking(onClickTrackingData, onClick);
  const openNewTab = newTabWindow ? true : externalLink ? true : false;

  let linkClasses = classNames({
    "tds-link": true,
    "tds-link--small": size === "small",
    "tds-link--medium": size === "medium",
    "tds-link--large": size === "large",
    [className]: className,
  });

  const iconClasses = classNames({
    "tds-link__icon": true,
    "tds-link__icon--internal": !openNewTab,
    "tds-link__icon--external": openNewTab,
  });

  // We use a WORD JOINER character to prevent a line break before the icon.
  const wordJoinerCharacter = <React.Fragment>&#8288;</React.Fragment>;

  const LaunchIconSizeMap: any = {
    small: "small",
    medium: "small",
    large: "small",
  };

  const ArrowIconSizeMap: any = {
    small: "small",
    medium: "medium",
    large: "medium",
  };

  const launchIcon = (
    <UtilityIcon
      size={LaunchIconSizeMap[size]}
      icon={LaunchIcon}
      className={iconClasses}
      color="primaryCTA"
    />
  );

  const arrowIcon = (
    <UtilityIcon
      size={ArrowIconSizeMap[size]}
      icon={ChevronRightIcon}
      className={iconClasses}
      color="primaryCTA"
    />
  );

  const Tag = customTag || "a";

  return (
    <Tag
      className={linkClasses}
      target={openNewTab ? "_blank" : ""}
      onClick={trackedOnClick}
      {...other}
    >
      <span className="link-text">{children ? children : text}</span>
      {openNewTab && (
        <span className="tds-sr-only">, opens in a new tab or window</span>
      )}
      {!noIcon && (
        <span className="tds-link__icon-wrapper">
          {wordJoinerCharacter}
          {openNewTab ? launchIcon : arrowIcon}
        </span>
      )}
    </Tag>
  );
};

Link.defaultProps = {
  size: "medium",
} as Partial<LinkProps<React.ElementType>>;

export default Link;
