import * as React from "react";
import classNames from "classnames";

export interface FooterCopySectionProps {
  [x: string]: any;
}

/**
 * This is an FooterCopySection
 * @param {FooterCopySectionProps} props
 */
const FooterCopySection: React.FunctionComponent<FooterCopySectionProps> = (
  props
) => {
  const { className, children, ...other } = props;

  let footerClasses = classNames({
    "tds-footer__copy-section": true,
  });

  footerClasses = className
    ? footerClasses.concat(" ", className)
    : footerClasses;

  return (
    <div className={footerClasses} {...other}>
      {children}
    </div>
  );
};

FooterCopySection.defaultProps = {} as Partial<FooterCopySectionProps>;

export default FooterCopySection;
